dark-gray = #6d717e 
first-section-gray-background = #eeeeee
first-section-gray = #282828 

#homepage

	.heading
		width: 100%
		height: auto
		margin: 0 0 10px 0
		clear: both
		position: relative
		z-index: 2
		display none
		@media screen and (max-width: 770px)
			padding: 0 15px

		&.right-view-margin
			margin-bottom: 40px
			@media screen and (max-width: 770px)
				margin-bottom: 10px

		.title
			font-weight: 500
			font-size: 18px
			color: first-section-gray
			display: inline-block

		.lines
			margin: 4px 0 0 15px
			display: inline-block

			span
				height: 1px
				display: inline-block
				width: 17px
				background-color: first-section-gray

			span:first-child
				width: 27px

			span:last-child
				width: 5px

		.show-all
			color: hover-blue
			font-size: 10px
			font-weight: bold
			text-decoration: none
			margin: 3px 0 0 15px
			display: inline-block

	#first-section
		background-color: first-section-gray-background

		.container
			padding: 60px 0

		.news
			width: 100%
			height: auto
			margin: 0
			clear: both
			position: relative
			z-index: 2

			.items
				white-space: nowrap
				overflow: hidden
				margin-bottom: 50px

				.arrows
					width: auto
					height: auto
					box-sizing: border-box
					display: inline-block
					position: absolute
					top: 3px
					right: -35px
					@media screen and (max-width: 770px)
						position: initial
						margin-bottom: 10px

					.arrow
						display: inline-block
						cursor: pointer
						margin-right: 15px
						&:last-child
							margin-right: 0

				.item
					width: 100%
					height: auto
					position: relative
					clear: both
					display: none

					&:first-child
						display: inline-block

					&:hover
						.image
							.layer
								opacity: 0.66

					&.right-view
						.image
							right: 0
						.arrows
							width: 46.5%
							text-align: left
							right: 0
							@media screen and (max-width: 770px)
								background-color: #ffffff
								width: 100%
								position: static
								padding: 15px 10px 0 10px
						.text_side
							padding: 50px 80px 50px 50px
							float: left
							@media screen and (max-width: 770px)
								width: 100%
								min-height: auto
								padding: 30px

					.image
						background-size: contain
						background-position: top center
						background-repeat: no-repeat
						width: 40%
						height: 265px
						margin: 30px 0 0 0
						right: 0
						overflow: hidden
						position: absolute
						z-index: 6
						background-color: #fff
						@media screen and (max-width: 770px)
							width: 100%
							position: relative

						img
							width: 100%
							height: auto
							display: block

						.layer
							background-color: hover-blue
							width: 100%
							height: 100%
							position: absolute
							top: 0
							opacity: 0
							transition: opacity 0.4s ease

					.text_side
						background-color: #ffffff
						width: 63%
						height: 265px
						padding: 50px 80px 50px 50px
						float: left
						margin-bottom: 45px
						position: relative
						@media screen and (max-width: 770px)
							width: 100%
							min-height: auto
							padding: 30px
						@media screen and (max-width: 500px)
							width: 100%
							height: 360px
							min-height: auto
							padding: 30px

						h2, h2 a
							font-size: 14px
							font-weight: 700
							text-transform: uppercase
							color: dark-blue-color
							margin: 0
							margin-bottom: 20px
							box-sizing: border-box
							text-decoration: none
							white-space: normal

						p
							font-size: 14px
							color: dark-blue-color
							line-height: 27px
							margin: 0
							padding: 0
							text-align: justify
							white-space: normal

						a.btn
							float: right
							margin-top: 20px
							border-radius: 0
							background-color: #221d14
							line-height: 43px
							padding: 0 21px
							font-weight: 600 !important
							color: #fff
							text-transform: uppercase
							font-weight: 500
							position: absolute
							right: 80px
							bottom: 40px
							@media screen and (max-width: 1200px)
								position: initial

				&.first-row
					.item
						.image
							right: initial
							left: 0
						.text_side
							padding: 50px 50px 50px 80px
							float: right
							@media screen and (max-width: 770px)
								width: 100%
								min-height: auto
								padding: 30px
							a.btn
								right: 50px
						.arrows
							right: 102%
							text-align: right

			&:nth-child( 2n )
				.items
					.image
						right: initial
						left: 0
					.text_side
						padding: 50px 50px 50px 80px
						float: right
						@media screen and (max-width: 770px)
							width: 100%
							min-height: auto
							padding: 30px
						a.btn
							right: 50px
					.arrows
						right: 102%
						text-align: right

			


	#poznaj-centrum
		background-image: url('../img/tmp/home/poznaj_bg_new.png')
		background-size: cover
		background-repeat: no-repeat
		background-position: center
		width: 100%
		height: 100vh
		margin: -20px 0 0 0
		padding: 0
		display: flex
		flex-direction: row
		flex-wrap: nowrap
		justify-content: flex-start
		align-content: stretch
		align-items: center
		@media screen and (max-width: 1200px)
			height: auto
			padding: 100px 0

		#poznaj-centrum-content
			width: 79%
			height: auto
			margin: 0 0 0 -70px
			@media screen and (max-width: 1200px)
				width: 100%
				margin: 0

		.text
			background-color: rgba(0, 0, 0, 0.77)
			width: 100%
			height: auto
			padding: 55px
			box-sizing: border-box
			clear: both

			h2
				font-size: 91px
				font-family: 'Jaapokki'
				color: #ffffff
				margin: 0 0 50px 0
				@media screen and (max-width: 990px)
					font-size: 70px
				@media screen and (max-width: 770px)
					font-size: 50px

			p
				width: 100%
				font-size: 14px
				line-height: 28px
				color: #ffffff
				text-align: justify
				clear: both

		.read-more
			background-color: rgba( 0 , 0 , 0 , 0.77 )
			font-weight: 700
			color: #ffffff
			text-transform: uppercase
			padding: 12px 40px
			margin: 0 -20px 0 0
			float: right
			@media screen and (max-width: 1200px)
				margin: 10px 0 0 0
				float: right

	#zostan-patronem
		background-color: #000
		background-image: url('../img/tmp/home/zostan-patronem-bg.jpg')
		background-position: 100% 0
		background-repeat: no-repeat
		background-size: contain
		padding: 145px 0 155px
		@media screen and (max-width: 1450px)
			background-position: 120% 0
		@media screen and (max-width: 1200px)
			background-image: none
		@media screen and (max-width: 990px)
			background-position: top center
			background-size: cover

		h1
			margin: 0 0 30px
			color: #fff
			font-size: 80px
			font-family: 'Jaapokki'
			@media screen and (max-width: 990px)
				font-size: 70px
			@media screen and (max-width: 770px)
				font-size: 55px

		p
			width: 65%
			font-size: 14px
			line-height: 28px
			color: #ffffff
			@media screen and (max-width: 990px)
				width: 100%

		a
			background-color: hover-blue
			color: #000
			border-radius: 0
			padding: 0 28px
			text-transform: uppercase
			font-weight: 700
			margin-top: 50px
			line-height: 44px

	#programy-preview
		width: 100%

		.heading
			margin-top: 70px


	#items
		width: 100%
		height: auto
		margin: 40px auto
		margin-bottom: 0

		.item
			width: 100%
			height: auto
			clear: both
			&:nth-child(even)
				.left_side
					float: right
					padding: 40px 0 40px 40px
					@media screen and (max-width: 770px)
						padding: 25px 20px
				.right_side
					.img_container
						left: 0

	    .left_side
			width: 50%
			height: 300px
			padding: 40px 40px 40px 0
			box-sizing: border-box
			float: left
			@media screen and (max-width: 770px)
				width: 100%
				height: auto
				padding: 25px 20px
				clear: both


			h2
				font-size: 14px
				color: dark-blue-color
				font-weight: bold
				margin: 0
				margin-bottom: 40px
				text-transform: uppercase

			p
				font-size: 14px
				color: dark-blue-color
				line-height: 28px
				text-align: justify

			a
				padding: 0 30px
				line-height: 44px
				color: #ffffff
				background-color: #000
				font-weight: bold
				text-transform: uppercase
				border-radius: 0
				margin-top: 0

		.right_side
			width: 50%
			padding: 0
			margin: 0
			@media screen and (max-width: 770px)
				width: 100%
				clear: both

			.img_container
				background-size: cover
				width: 50%
				height: 300px
				position: absolute
				right: 0
				@media screen and (max-width: 770px)
					width: 100%
					position: initial
