.tekst-2-kolumny
	
	.columns		
		-webkit-column-count: 2
		-moz-column-count: 2
		column-count: 2
		-webkit-column-gap: 60px
		-moz-column-gap: 60px
		column-gap: 60px
		@media screen and (max-width: 700px)
			-webkit-column-count: 1
			-moz-column-count: 1
			column-count: 1