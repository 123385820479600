#condolences
    .condolences__header
        margin-bottom: 50px
        font-size: 18px
        color: #131f3c
        font-weight: 800
    .condolences-form
        margin-top: 75px
        position relative
        margin-bottom: 75px
        textarea
            width 100%
            margin-bottom: 25px
            border-color: #131f3c
            resize: none
            outline: none !important
            padding: 5px 15px
        label 
            display: none
        .input
            > input 
                width 35%
                height : 45px
                border: 1px solid  #131f3c
                outline: none
                padding: 0 15px
                display: flex
                align-items: center
                &::placeholder 
                    font-size: 14px
                    line-height: 2
                    color: #c9c9c9 
                @media(max-width:768px)
                    width 75%
        .submit
            position absolute
            bottom: 0
            left 35%
            button
                background-color: #131f3c
                height 45px
                padding: 0 25px
                display flex
                align-items: center
                color: #ffffff
                border: none 
                outline: none
                font-size: 14px
                font-weight: 700
                line-height: 2
            @media(max-width:768px)
                position : relative
                left 0
                margin-top: 30px
    .single-condolence
        font-size: 14px
        line-height: 2
        color: #131f3c
        padding-bottom: 60px
        margin-bottom: 60px
        border-bottom: 1px solid #e7e7e7
        .text
            margin-bottom: 30px
        .signature
            font-weight: 700
        .date
            color: #137EB0
            font-style: italic
    .pagination
        margin: 0 auto
        a, span
            font-size: 18px
            line-height: 2
            color: #131f3c !important
            margin-left: 90px
            font-weight: 700
            &:first-child
                margin-left: 0
        .current
            font-weight: 400

.flex 
    display flex       

.condolence-modal-title
    font-family: Jaapokki;
    font-size: 61px;
    color: #131f3c;
.condolence-modal-desc
    color: #131f3c
    font-size: 18px
    line-height: 30px