.blueimp-gallery
	background-color: rgba(0,0,0,.88)

	.next,
	.prev
		width: 23px
		height: 111px
		border: 0;
		background: url('../img/gallery-arrow-white.png') transparent no-repeat left center;
		font-size: 0;

	.next
		transform: rotate(180deg);

.galeria-zdjec
	display: flex
	padding: 0
	height: auto
	position: relative
	clear: both
	width: calc( 100% - 70px );
	margin: 0 auto;

	@media( max-width: 1450px )
		width: 100%;

	&::after
		content: ""
		flex: auto

	.gallery-move
		position: absolute
		width: 53px
		height: 100%
		background: red
		top: 0
		background: url('../img/gallery-arrow.png') no-repeat left center
		cursor: pointer
		transition: opacity .5s
		z-index: 999

		@media( max-width: 1450px )
			opacity: 0;
			visibility: hidden;

		&.left
			left: -53px

		&.right
			right: -53px
			transform: rotate(180deg)

		&.disabled
			opacity: .1
			cursor: not-allowed

	.gallery-container
		width: 100%
		height: 100%
		overflow: hidden
		position: relative
		.linkCopy
			&:focus
				.item-container
					.item
						outline solid 2px black
		.item-container
			position: absolute

			.item
				width: 100%
				height: 100%
				background-position: center center !important
				background-size: cover
				object-fit: cover

.galeria-zdjec-full
	display: flex
	padding: 0
	height: auto
	position: relative
	clear: both

	.gallery-move
		position: absolute
		width: 53px
		height: 100px
		background: red
		top: calc(50% - 50px)
		background: url('../img/gallery-arrow-white.png') no-repeat left center
		cursor: pointer
		transition: opacity .5s
		z-index: 999

		&.left
			left: 53px

		&.right
			right: 53px
			transform: rotate(180deg)

		@media( max-width: 750px )
			&.left
				left: 23px
			&.right
				right: 23px

		@media( max-width: 750px )
			&.left
				left: 10px
			&.right
				right: 10px

		&.disabled
			opacity: .1
			cursor: not-allowed

.slick-gallery
	width: 100%

	.slide
		width: auto
		position: relative
		&:not(.slick-active)::after
			background-color: rgba(0, 92, 156, 0.8)
			width: 100%
			height: 100%
			content: ''
			top: 0
			left: 0
			position: absolute

		img
			width: 75vw
