@font-face {
  font-family: 'Jaapokki';
  src: url("../fonts/jaapokki-regular.eot");
  src: url("../fonts/jaapokki-regular.eot?#iefix") format('embedded-opentype'), url("../fonts/jaapokki-regular.woff") format('woff'), url("../fonts/jaapokki-regular.ttf") format('truetype'), url("../fonts/jaapokki-regular.svg#svgFontName") format('svg');
}
.clearfix {
  clear: both;
}
.noscroll {
  overflow-y: hidden;
}
body {
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
  position: relative;
  padding: 0 !important;
}
body.removeOverflowY {
  overflow-y: hidden;
}
body .nopadding {
  padding: 0 !important;
}
body img {
  max-width: 100%;
}
body main {
  position: relative;
}
body main #winda {
  position: absolute;
  left: 0;
  top: 100px;
  background-color: #fff;
  z-index: 1000;
  width: 100%;
  max-width: 10vw;
}
body main #winda.affix {
  top: 70px;
  position: fixed;
}
@media screen and (max-width: 1500px) {
  body main #winda {
    display: none;
  }
}
@media screen and (min-width: 1700px) {
  body main #winda {
    max-width: 15vw;
  }
}
body main #winda h4 {
  padding: 38px 40px 18px 40px;
  font-size: 36px;
  font-weight: 700;
  color: #131f3c;
  position: relative;
}
body main #winda h4:after {
  content: '';
  width: 40px;
  height: 1px;
  background-color: #131f3c;
  position: absolute;
  bottom: -10px;
  left: 40px;
}
body main #winda ul {
  list-style-type: none;
  padding: 32px 0 43px 0;
  padding-bottom: 52px;
  margin: 0;
}
body main #winda ul li a {
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;
  padding: 9px 40px;
  font-weight: 400;
  color: #131f3c;
}
body main #winda ul li a:hover {
  background-color: transparent;
}
body main #winda ul li.active a {
  font-weight: 700;
  padding: 28px 28px;
}
body .skiplink {
  display: block;
  opacity: 1;
  position: absolute;
  left: -200px;
  z-index: 909090;
  text-align: center;
  word-break: break-word;
  border: 2px solid #000;
  background-color: #fff;
  width: 200px;
  height: auto;
  color: #000;
}
body .skiplink:focus {
  left: 0;
}
html.normal {
  font-size: 20px !important;
}
html.plus {
  font-size: 24px !important;
}
html.plus-plus {
  font-size: 28px !important;
}
.no-skin {
  background-color: #212121;
}
.no-skin h1,
.no-skin h2,
.no-skin h3,
.no-skin h4,
.no-skin h5,
.no-skin h6,
.no-skin p,
.no-skin a,
.no-skin text {
  color: #fdb81e;
  font-size: 1rem;
}
.no-skin p {
  line-height: 1.4rem;
}
.no-skin .svg,
.no-skin .svg * {
  fill: #fdb81e;
}
.no-skin .btn {
  color: #212121;
  background: #fdb81e;
  font-size: 1rem;
  line-height: 1.4rem;
}
.no-skin #winda {
  display: none;
}
.no-skin header #logo {
  width: 730px;
}
.no-skin header #top-info-wrapper {
  width: calc(100% - 730px);
}
.no-skin header #top-info-wrapper .info-item {
  color: #fdb81e;
  border-color: #fdb81e;
}
.no-skin header #top-info-wrapper .info-item:first-child {
  display: none;
}
.no-skin header .wcag-icon {
  z-index: 1000;
  position: fixed;
  right: 0;
  top: 20vh;
  display: block;
  width: auto;
  padding: 25px 15px;
  background: #212121;
}
.no-skin header .wcag-icon a {
  padding: 10px;
  border: 1px solid #fdb81e;
}
.no-skin #site-preview {
  background: #212121 !important;
}
.no-skin #site-preview #banner-blend {
  background: #212121;
}
.no-skin #site-preview .header-txt {
  height: 400px;
  top: 200px !important;
  bottom: none;
}
.no-skin #site-preview .header-txt h1,
.no-skin #site-preview .header-txt h2,
.no-skin #site-preview .header-txt p {
  color: #fdb81e;
}
.no-skin #site-preview .header-txt p {
  font-size: 1rem;
  line-height: 1.4rem;
}
.no-skin #site-preview .header-txt.home h1,
.no-skin #site-preview .header-txt.home h2 {
  font-weight: 600;
}
.no-skin #preview-menu-bar {
  position: absolute !important;
  bottom: 40px;
  width: 100%;
}
.no-skin #preview-menu-bar #main-menu .nopadding {
  display: flex;
  height: 100%;
  width: auto;
  min-height: 150px;
  padding: 0;
}
.no-skin #preview-menu-bar nav {
  border: 1px solid #fdb81e;
  height: 200px;
}
.no-skin #preview-menu-bar nav .menu-stick-right .btn#rezerwuj {
  padding: 0 10px;
}
.no-skin #preview-menu-bar nav #mobile-menu-toggler .line {
  display: block;
  background-color: #fdb81e !important;
}
.no-skin #preview-menu-bar nav ul {
  color: #fdb81e !important;
  padding: 10px;
  width: 65%;
  height: 100%;
}
.no-skin #preview-menu-bar nav ul li {
  display: flex;
  padding: 0 !important;
  margin-right: 0 !important;
  width: 33.3%;
}
.no-skin #preview-menu-bar nav ul li a {
  padding: 22px 0;
}
.no-skin #preview-menu-bar nav ul li a span.active {
  display: none;
}
.no-skin #preview-menu-bar nav ul.menu {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.no-skin #preview-menu-bar nav ul.menu > li > a {
  font-size: 1rem;
  color: #fdb81e;
  font-weight: 700;
  width: auto !important;
}
.no-skin #preview-menu-bar nav ul.menu > li > a span.active {
  display: none;
}
.no-skin #preview-menu-bar nav ul.menu > li ul.submenu {
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 200%;
  height: auto;
  margin-left: 340px;
  padding: 0;
}
.no-skin #preview-menu-bar nav ul.menu > li ul.submenu > li > a {
  font-size: 1rem;
  color: #fdb81e;
  width: auto !important;
}
.no-skin #preview-menu-bar nav ul.menu > li ul.submenu > li.submenu-close {
  width: 775px;
  color: inherit;
  font-size: 22px;
  display: flex;
  justify-content: center;
  font-weight: 700;
}
.no-skin #preview-menu-bar nav ul.menu > li ul.submenu.hidden_element {
  width: 40%;
}
.no-skin #preview-menu-bar nav ul .submenued {
  display: block;
}
.no-skin #preview-menu-bar nav ul.links > li {
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-skin #preview-menu-bar nav ul.links > li.wcag {
  align-items: center;
  justify-content: center;
  height: 100%;
  border-color: #fdb81e !important;
  color: #fdb81e;
  font-size: 1rem;
  width: 70px;
  margin-right: 0px !important;
}
.no-skin #preview-menu-bar nav ul.links > li.wcag .svg,
.no-skin #preview-menu-bar nav ul.links > li.wcag .svg * {
  fill: #fdb81e !important;
  height: 23px !important;
}
.no-skin #preview-menu-bar nav ul.links > li.bilety {
  height: 100%;
  width: 130px;
  right: 0;
  position: absolute;
}
.no-skin #preview-menu-bar nav ul.links > li.elements {
  width: 17%;
  height: 50%;
}
.no-skin #preview-menu-bar nav ul.links > li > a {
  font-size: 1rem;
  color: #fdb81e;
  font-weight: 400;
  width: auto !important;
}
.no-skin #preview-menu-bar nav .nav-list {
  width: 90% !important;
}
.no-skin #preview-menu-bar nav .menu-stick-right {
  position: relative;
  width: 35%;
}
.no-skin #preview-menu-bar nav .menu-stick-right .links-nav {
  display: flex;
  height: 100%;
}
.no-skin #preview-menu-bar nav .menu-stick-right .links {
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  height: auto;
  display: flex;
  padding: 0;
  margin: 0;
  width: 100%;
}
.no-skin #preview-menu-bar nav .menu-stick-right .btn#rezerwuj {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1rem;
  color: #212121;
  background: #fdb81e;
  display: flex;
  height: 100%;
  align-items: center;
}
.no-skin .amp {
  display: none;
}
.no-skin #homepage #first-section {
  background: #212121;
  border-top: 1px solid #fdb81e;
}
.no-skin #homepage #first-section .news .heading {
  margin-bottom: 30px;
}
.no-skin #homepage #first-section .news .heading .title {
  color: #fdb81e;
  text-transform: uppercase;
}
.no-skin #homepage #first-section .news .heading .lines {
  display: none;
}
.no-skin #homepage #first-section .news .heading .show-all {
  color: #fdb81e;
  font-size: 0.8rem;
}
.no-skin #homepage #first-section .news .items .item .image,
.no-skin #homepage #first-section .news .items.first-row .item .image {
  display: none;
}
.no-skin #homepage #first-section .news .items .item .text_side,
.no-skin #homepage #first-section .news .items.first-row .item .text_side {
  padding: 50px;
  width: 100%;
  background: #212121;
  border: 1px solid #fdb81e;
  height: 400px;
}
@media screen and (max-width: 770px) {
  .no-skin #homepage #first-section .news .items .item .text_side,
  .no-skin #homepage #first-section .news .items.first-row .item .text_side {
    height: 660px;
  }
}
.no-skin #homepage #first-section .news .items .item .text_side h2,
.no-skin #homepage #first-section .news .items.first-row .item .text_side h2,
.no-skin #homepage #first-section .news .items .item .text_side p,
.no-skin #homepage #first-section .news .items.first-row .item .text_side p,
.no-skin #homepage #first-section .news .items .item .text_side a,
.no-skin #homepage #first-section .news .items.first-row .item .text_side a {
  color: #fdb81e;
  font-size: 1rem;
  line-height: 1.4rem;
}
.no-skin #homepage #first-section .news .items .item .text_side a.btn,
.no-skin #homepage #first-section .news .items.first-row .item .text_side a.btn {
  float: left;
  left: 50px;
  right: auto;
  color: #212121;
  background: #fdb81e;
  font-size: 1rem;
  line-height: 1.4rem;
}
.no-skin #homepage #poznaj-centrum {
  background: #212121;
  height: auto;
}
.no-skin #homepage #poznaj-centrum .container {
  padding: 0;
}
.no-skin #homepage #poznaj-centrum #poznaj-centrum-content {
  width: 100%;
  border: 1px solid #fdb81e;
  margin: 0;
}
.no-skin #homepage #poznaj-centrum #poznaj-centrum-content .text h2,
.no-skin #homepage #poznaj-centrum #poznaj-centrum-content .text p {
  color: #fdb81e;
}
.no-skin #homepage #poznaj-centrum #poznaj-centrum-content .text p {
  font-size: 1rem;
  line-height: 1.4rem;
}
.no-skin #homepage #poznaj-centrum #poznaj-centrum-content .read-more {
  float: left;
  color: #212121;
  background: #fdb81e;
  font-size: 1rem;
  line-height: 1.4rem;
}
.no-skin #homepage #programy-preview .heading {
  margin-bottom: 30px;
}
.no-skin #homepage #programy-preview .heading .title {
  color: #fdb81e;
  text-transform: uppercase;
}
.no-skin #homepage #programy-preview .heading .lines {
  display: none;
}
.no-skin #homepage #programy-preview .heading .show-all {
  color: #fdb81e;
  font-size: 0.8rem;
}
.no-skin #homepage .right_side {
  display: none;
}
.no-skin #homepage .left_side {
  width: 100%;
  padding: 40px 0;
  height: 330px;
}
@media screen and (max-width: 770px) {
  .no-skin #homepage .left_side {
    padding: 40px 15px;
    height: 650px;
  }
}
.no-skin #homepage .left_side h2,
.no-skin #homepage .left_side p {
  color: #fdb81e;
  font-size: 1rem;
  line-height: 1.4rem;
}
.no-skin #homepage .left_side a {
  color: #212121;
  background: #fdb81e;
  margin-top: 40px;
}
.no-skin #homepage #items .item:nth-child(even) .left_side {
  padding: 40px 0;
  height: 330px;
}
@media screen and (max-width: 770px) {
  .no-skin #homepage #items .item:nth-child(even) .left_side {
    padding: 40px 15px;
    height: 900px;
  }
}
.no-skin #mecenat .galeria .galeria-opis {
  display: block;
}
.no-skin #mecenat h1 {
  font-size: 1.4rem;
}
.no-skin #mecenat p {
  font-size: 1rem;
  line-height: 1.4rem;
}
.no-skin #mecenat .btn {
  background: #212121;
  border: 1px solid #fdb81e;
  color: #fdb81e;
}
.no-skin #mecenat .btn:hover,
.no-skin #mecenat .btn:active,
.no-skin #mecenat .btn:focus,
.no-skin #mecenat .btn:visited {
  color: #fdb81e;
}
.no-skin #newsletter {
  background: #212121;
  border-top: 1px solid #fdb81e;
  border-bottom: 1px solid #fdb81e;
}
.no-skin #newsletter .container .row form button,
.no-skin #newsletter .container .row form #newsletter-notification-open {
  background: #fdb81e !important;
  color: #212121 !important;
  font-size: 1rem !important;
  line-height: 1.4rem !important;
}
.no-skin #newsletter .text h2,
.no-skin #newsletter .text p {
  color: #fdb81e;
}
.no-skin #newsletter .text p {
  font-size: 1rem;
  line-height: 1.4rem;
}
.no-skin #newsletter .form label {
  color: #fdb81e;
  font-size: 1rem;
  line-height: 1.4rem;
}
.no-skin #newsletter .form input {
  border: 1px solid #fdb81e;
  background: #212121;
  color: #fdb81e;
  font-size: 1rem;
  line-height: 1.4rem;
}
.no-skin #newsletter .form input::-webkit-input-placeholder {
  color: #fdb81e;
  font-size: 1rem;
}
.no-skin #newsletter .form input::-moz-placeholder {
  color: #fdb81e;
  font-size: 1rem;
}
.no-skin #newsletter .form input::-ms-input-placeholder {
  color: #fdb81e;
  font-size: 1rem;
}
.no-skin #newsletter .form input:-moz-placeholder {
  color: #fdb81e;
  font-size: 1rem;
}
.no-skin #newsletter-notification .notification-container .notification-content .newsletter-popup-background {
  border: none !important;
}
.no-skin #newsletter-notification .notification-container .notification-content .newsletter-popup-content {
  background: #212121 !important;
  border: 1px solid #fdb81e !important;
}
.no-skin #newsletter-notification .notification-container .notification-content .newsletter-popup-content .button-container .notification-message h1 {
  color: #fdb81e !important;
  font-size: 2.4rem !important;
  line-height: 1.4rem !important;
}
.no-skin #newsletter-notification .notification-container .notification-content .newsletter-popup-content .button-container .notification-message p {
  color: #fdb81e !important;
  font-size: 1rem !important;
  line-height: 1.4rem !important;
}
.no-skin #newsletter-notification .notification-container .notification-content .newsletter-popup-content .button-container .notification-button {
  background: #212121 !important;
  color: #fdb81e !important;
  font-size: 1.2rem !important;
  line-height: 1.4rem !important;
  border: 1px solid #fdb81e !important;
}
.no-skin #contact .company_place .first #first-map {
  display: none;
}
.no-skin #contact .company_place .first .heading {
  color: #fdb81e;
  font-size: 1rem;
  line-height: 1.4rem;
}
.no-skin #contact .company_place .first .informations_box .item {
  color: #fdb81e;
  font-size: 1rem;
  line-height: 1.4rem;
  padding-right: 15px;
  word-break: break-word;
  width: 50%;
}
.no-skin #contact .company_place .first .informations_box .item .person_fullname {
  color: #fdb81e;
  font-size: 1rem;
  line-height: 1.4rem;
  word-break: break-word;
}
.no-skin #contact .company_place .first .informations_box .item .person_role {
  font-size: 0.95rem;
  line-height: 1.4rem;
  word-break: break-all;
}
.no-skin #contact .company_place .second #small-map {
  display: none;
}
.no-skin #contact .company_place .second .left_side {
  width: 100%;
}
.no-skin #contact .company_place .second .left_side .heading {
  color: #fdb81e;
  font-size: 1rem;
  line-height: 1.4rem;
}
.no-skin #contact .company_place .second .left_side .informations_box {
  justify-content: flex-start;
}
.no-skin #contact .company_place .second .left_side .informations_box .item {
  width: 50%;
  color: #fdb81e;
  font-size: 1rem;
  line-height: 1.4rem;
  margin-top: 50px;
  padding-right: 15px;
}
.no-skin #contact .company_place .second .left_side .informations_box .item:nth-child(-n+2) {
  margin-top: 50px;
  padding-right: 15px;
}
.no-skin #contact .company_place .second .left_side .informations_box .item .person_fullname {
  color: #fdb81e;
  font-size: 1rem;
  line-height: 1.4rem;
  word-break: break-word;
}
.no-skin #contact .company_place .second .left_side .informations_box .item .person_role {
  font-size: 0.95rem;
  line-height: 1.4rem;
  word-break: break-all;
}
.no-skin #aktualnosci .lista .item:nth-of-type(2n - 1) .text {
  padding: 0;
}
@media screen and (max-width: 990px) {
  .no-skin #aktualnosci .lista .item:nth-of-type(2n - 1) {
    padding: 0 25px;
  }
}
.no-skin #aktualnosci .lista .item > a {
  display: none;
}
.no-skin #aktualnosci .lista .item .text {
  width: 100%;
  padding: 0;
}
.no-skin #aktualnosci .lista .item .text h2,
.no-skin #aktualnosci .lista .item .text a,
.no-skin #aktualnosci .lista .item .text p,
.no-skin #aktualnosci .lista .item .text a.tag,
.no-skin #aktualnosci .lista .item .text .read-more,
.no-skin #aktualnosci .lista .item .text .read-more:hover {
  color: #fdb81e;
  font-size: 1rem;
  line-height: 1.4rem;
}
@media screen and (max-width: 990px) {
  .no-skin #aktualnosci .lista .item {
    padding: 0 25px;
  }
}
.no-skin #aktualnosci .load-more {
  color: #fdb81e;
  font-size: 1rem;
  line-height: 1.4rem;
}
.no-skin .informations .information_item {
  color: #fdb81e;
  border: 1px solid #fdb81e;
  font-size: 1rem;
  line-height: 1.4rem;
}
.no-skin .informations .information_item.book-btn {
  background: #212121;
}
.no-skin .informations .information_item.book-btn a {
  color: #fdb81e;
}
.no-skin #szablon .module {
  background: #212121;
}
.no-skin #szablon .module h1,
.no-skin #szablon .module h2,
.no-skin #szablon .module p,
.no-skin #szablon .module a {
  color: #fdb81e;
  font-size: 1rem;
  line-height: 1.4rem;
}
.no-skin #szablon .module .bg-tekst {
  display: none;
}
.no-skin #szablon .module.obudowa:after {
  width: 0;
}
.no-skin #szablon .module .galeria-zdjec {
  display: none;
}
.no-skin #szablon .module #module2 {
  display: none;
}
.no-skin #szablon .module #module3 {
  display: none;
}
.no-skin #szablon .module #module5 {
  display: none;
}
.no-skin #szablon.programy .program {
  background: #212121;
}
.no-skin #szablon.programy .program:nth-child(2n) {
  background: #212121;
}
.no-skin .zdjecie-i-tekst .zdjecie {
  display: none;
}
.no-skin .zdjecie-i-tekst .tekst {
  width: 100%;
  right: 0;
}
.no-skin .zdjecie-przesuniete {
  display: none;
}
.no-skin .zdjecie-full {
  display: none;
}
.no-skin .galeria-osob .item img {
  display: none;
}
.no-skin .galeria-osob .item p {
  padding-right: 15px;
  word-break: break-word;
}
.no-skin #powiazane {
  background: #212121;
}
.no-skin #powiazane #powiazane-wrapper .item {
  border: 1px solid #fdb81e;
}
.no-skin #powiazane #powiazane-wrapper .item .name {
  color: #fdb81e;
  font-size: 1rem;
  line-height: 1.4rem;
}
.no-skin #powiazane #powiazane-wrapper .item .opis {
  color: #fdb81e;
  font-size: 1rem;
  line-height: 1.4rem;
}
.no-skin #sticky-news-nav {
  display: none;
}
.no-skin footer #logos {
  display: none;
}
.no-skin footer #dark-ending #txts h3 {
  font-size: 1rem;
  line-height: 1.4rem;
}
.no-skin footer #scroll-up {
  display: block;
  position: fixed;
  bottom: 0;
  right: 5vw;
  background: transparent;
  border: none;
  padding: 0;
  z-index: 2;
  outline: none;
  visibility: hidden;
  opacity: 0;
}
.no-skin footer #scroll-up.visible {
  visibility: visible;
  opacity: 1;
}
.no-skin #mobile-menu-container {
  background: #212121;
}
.no-skin #mobile-menu-container .mobile-menu-wrapper ul li a {
  color: #fdb81e;
}
.no-skin #mobile-menu-container .mobile-menu-wrapper ul li a:before {
  display: none;
}
.no-skin #mobile-menu-container .mobile-menu-wrapper ul li a.active {
  color: #fdb81e;
}
@media screen and (max-width: 1100px) {
  .no-skin #mobile-menu-container .mobile-menu-wrapper ul li a {
    font-size: 1.5rem;
  }
}
.no-skin #mobile-menu-container .mobile-menu-wrapper .links li.wcag {
  font-weight: bold;
  color: #fdb81e;
  font-size: 1.5rem !important;
}
.no-skin #mobile-menu-container .mobile-menu-wrapper .links li a {
  font-size: 1.5rem !important;
}
.no-skin #mobile-menu-container .mobile-menu li .submenued {
  display: none;
}
.no-skin #mobile-menu-container .mobile-menu li .submenu {
  margin: 0;
  display: block !important;
}
.no-skin #mobile-menu-container .mobile-menu li .submenu li {
  margin-bottom: 30px;
}
.no-skin #mobile-menu-container .mobile-menu li .submenu li a {
  font-size: 1.5rem;
  color: #fdb81e;
  padding-left: 0;
}
.no-skin #mobile-menu-container .mobile-menu-close {
  display: none;
}
.no-skin #mobile-menu-container .mobile-menu-close.close-wcag {
  display: block;
  width: 35px;
}
.no-skin #search-zone {
  background: #212121;
}
.no-skin #search-zone #search-form h1 {
  color: #fdb81e;
}
.no-skin #search-zone #search-form input {
  color: #fdb81e;
  font-size: 1rem;
  line-height: 1.4rem;
  border: 1px solid #fdb81e;
}
.no-skin #search-zone #search-form input::-webkit-input-placeholder {
  color: #fdb81e;
  font-size: 1rem;
}
.no-skin #search-zone #search-form input::-moz-placeholder {
  color: #fdb81e;
  font-size: 1rem;
}
.no-skin #search-zone #search-form input::-ms-input-placeholder {
  color: #fdb81e;
  font-size: 1rem;
}
.no-skin #search-zone #search-form input:-moz-placeholder {
  color: #fdb81e;
  font-size: 1rem;
}
.no-skin #search-zone #search-form button {
  background: #fdb81e;
  color: #212121;
  font-size: 1rem;
  border: 1px solid #fdb81e;
  margin-top: -7px;
}
.no-skin #search-zone .results h2 {
  font-size: 1rem;
  line-height: 1.4rem;
  color: #fdb81e;
}
.no-skin #search-zone .results h1 {
  font-size: 5rem;
  color: #fdb81e;
}
.no-skin #search-zone .results ul li a {
  background: #fdb81e;
  color: #212121;
  font-size: 1rem;
}
.no-skin #search-zone .results ul li:before {
  display: none;
}
.no-skin #search-zone .search-close {
  display: none;
}
.no-skin #search-zone .close-wcag {
  display: block;
  position: absolute;
  top: 50px;
  right: 50px;
  cursor: pointer;
  width: 45px;
  height: 45px;
}
.no-skin #news-popup {
  background: #212121;
}
.no-skin #news-popup > .container .content {
  background: #212121;
}
.no-skin #news-popup > .container .content h1 {
  color: #fdb81e;
}
.no-skin #news-popup > .container .content .controls .close {
  display: none;
}
.no-skin #news-popup > .container .content .controls .close img {
  padding: 0;
}
.no-skin #news-popup > .container .content .controls .close-wcag {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 45px;
  height: 45px;
  opacity: 1;
  cursor: pointer;
  background: #212121;
}
.no-skin #news-popup > .container .content .controls .nav-btn {
  border: 1px solid #fdb81e;
  color: #fdb81e;
}
.no-skin #news-popup .main-news-img {
  display: none;
}
.no-skin #prezentacje,
.no-skin #sticky-news-nav {
  background-color: #212121 !important;
  color: #fdb81e;
  padding-left: 0;
}
.no-skin #prezentacje .presentations-filtration-list,
.no-skin #sticky-news-nav .presentations-filtration-list {
  background-color: #212121 !important;
  color: #fdb81e;
}
.no-skin #prezentacje .filter,
.no-skin #sticky-news-nav .filter {
  font-size: 1rem;
}
.no-skin #prezentacje .list p,
.no-skin #sticky-news-nav .list p,
.no-skin #prezentacje a,
.no-skin #sticky-news-nav a {
  background-color: #212121 !important;
  color: #fdb81e;
}
.no-skin #prezentacje:hover p,
.no-skin #sticky-news-nav:hover p,
.no-skin #prezentacje a,
.no-skin #sticky-news-nav a {
  color: #fdb81e !important;
}
.no-skin #prezentacje .selected-filter-item,
.no-skin #sticky-news-nav .selected-filter-item {
  color: #fdb81e !important;
}
.no-skin #prezentacje #show-all,
.no-skin #sticky-news-nav #show-all {
  color: #fdb81e !important;
}
.no-skin #prezentacje .presentation-item,
.no-skin #sticky-news-nav .presentation-item {
  font-size: inherit;
  border: 1px solid #fdb81e;
}
.no-skin #prezentacje .presentation-item .category-box,
.no-skin #sticky-news-nav .presentation-item .category-box {
  line-height: 1.4rem;
  font-size: 1rem;
}
.no-skin #prezentacje .presentation-item .short-desc,
.no-skin #sticky-news-nav .presentation-item .short-desc,
.no-skin #prezentacje .presentation-item p,
.no-skin #sticky-news-nav .presentation-item p,
.no-skin #prezentacje .presentation-item a,
.no-skin #sticky-news-nav .presentation-item a {
  line-height: 1.4rem;
  font-size: 1rem;
  color: #fdb81e !important;
}
.no-skin #prezentacje .presentation-item .short-desc:hover p,
.no-skin #sticky-news-nav .presentation-item .short-desc:hover p,
.no-skin #prezentacje .presentation-item p:hover p,
.no-skin #sticky-news-nav .presentation-item p:hover p,
.no-skin #prezentacje .presentation-item a:hover p,
.no-skin #sticky-news-nav .presentation-item a:hover p,
.no-skin #prezentacje .presentation-item .short-desc a,
.no-skin #sticky-news-nav .presentation-item .short-desc a,
.no-skin #prezentacje .presentation-item p a,
.no-skin #sticky-news-nav .presentation-item p a,
.no-skin #prezentacje .presentation-item a a,
.no-skin #sticky-news-nav .presentation-item a a {
  color: #fdb81e !important;
}
.no-skin #prezentacje .presentation-item .artist-list-photo,
.no-skin #sticky-news-nav .presentation-item .artist-list-photo {
  line-height: 1.4rem;
  font-size: 1rem;
  display: none !important;
}
.no-skin #prezentacje .presentation-item .name,
.no-skin #sticky-news-nav .presentation-item .name {
  line-height: 1.6rem;
  font-size: 1.4rem;
  color: #fdb81e !important;
}
.no-skin #prezentacje .presentation-item .name:hover p,
.no-skin #sticky-news-nav .presentation-item .name:hover p,
.no-skin #prezentacje .presentation-item .name a,
.no-skin #sticky-news-nav .presentation-item .name a {
  color: #fdb81e !important;
}
.no-skin #prezentacje .presentation-item .presentation-category,
.no-skin #sticky-news-nav .presentation-item .presentation-category {
  line-height: 1.4rem;
  font-size: 1rem;
  background-color: #fdb81e !important;
  color: #212121 !important;
}
.no-skin #prezentacje #loading,
.no-skin #sticky-news-nav #loading {
  visibility: hidden;
}
.no-skin #footer {
  color: #fdb81e !important;
  background-color: #212121 !important;
}
.no-skin #footer #dark-ending {
  color: #fdb81e !important;
  background-color: #212121 !important;
}
.no-skin #text-space {
  background: #212121 !important;
  color: #fdb81e !important;
}
.no-skin #text-space .main-presentations-header,
.no-skin #text-space .name-presentations,
.no-skin #text-space .surname-presentations,
.no-skin #text-space .category,
.no-skin #text-space .gallery-media-container,
.no-skin #text-space .long-description,
.no-skin #text-space .film-container {
  color: #fdb81e !important;
}
.no-skin #text-space .main-presentations-header {
  background-color: #212121 !important;
}
.no-skin #text-space .main-presentations-header .name-float {
  border-color: #fdb81e !important;
  color: #fdb81e !important;
  background-color: #212121 !important;
}
.no-skin #text-space .main-presentations-header .name-float .name-nav {
  color: #fdb81e !important;
  background-color: #212121 !important;
}
.no-skin #topControls {
  color: #fdb81e !important;
  background-color: #212121 !important;
}
.no-skin #topControls .btnss {
  color: #fdb81e !important;
  background-color: #212121 !important;
}
.no-skin #topControls .nav-btns {
  border-color: #fdb81e !important;
  color: #fdb81e !important;
  background-color: #212121 !important;
}
.no-skin #topControls .nav-btns .previous {
  color: #fdb81e !important;
  background-color: #212121 !important;
}
.no-skin #topControls .nav-btns .nextious {
  color: #fdb81e !important;
  background-color: #212121 !important;
}
.no-skin .close {
  color: #fdb81e !important;
  background-color: #212121 !important;
}
.no-skin .close img {
  padding: 10px !important;
  background-color: #fdb81e !important;
  fill: #212121 !important;
}
.no-skin .close .close-wcag {
  color: #fdb81e !important;
  background-color: #212121 !important;
}
.no-skin #homepage #poznaj-centrum .text {
  background-color: #212121 !important;
}
.no-skin #emanacje .load-more {
  color: #fdb81e !important;
}
.no-skin #emanacje .emanation-item {
  border: 1px solid #fdb81e !important;
}
.no-skin #emanacje .category-box {
  color: #212121 !important;
}
.no-skin #emanacje .category-box .category-gray {
  background-color: #fdb81e !important;
}
.no-skin #emanacje .category-box .category-yellow {
  background-color: #fdb81e !important;
}
.no-skin #emanacje .category-box .category-black {
  background-color: #fdb81e !important;
  color: #212121 !important;
}
.no-skin #emanacje .category-box .category-blue {
  background-color: #fdb81e !important;
  color: #212121 !important;
}
.no-skin #emanacje .category-box .category-light-blue {
  background-color: #fdb81e !important;
  color: #212121 !important;
}
.no-skin #emanacje .short-content .date {
  color: #fdb81e !important;
}
.no-skin #emanacje .short-content a.tag {
  color: #fdb81e !important;
}
.no-skin #emanacje .short-content .bottom-box {
  padding-left: 0 !important;
}
.no-skin #emanacje .short-content .bottom-box .read-more {
  padding-left: 0 !important;
  color: #fdb81e !important;
}
.no-skin #emanacje .short-content .title a {
  color: #fdb81e !important;
}
.no-skin #emanacje .short-content .short-desc p {
  color: #fdb81e !important;
}
.no-skin #emanacje .short-content .short-desc .read-more {
  padding-left: 0 !important;
  color: #fdb81e !important;
}
.no-skin #emanacje #sticky-news-nav.emanations-nav {
  z-index: 100;
}
.no-skin #emanacje #sticky-news-nav.emanations-nav #show-all {
  color: #fdb81e !important;
}
.no-skin #emanacje #emanation-filter-list .selected-filter-item {
  color: #fdb81e !important;
}
.no-skin #emanacje .list {
  background-color: #fff;
}
.no-skin #emanacje .list span a {
  color: #fdb81e !important;
}
.no-skin #emanacje .emanation-popup {
  background-color: #fdb81e !important;
}
.no-skin #emanacje .emanation-popup .container .content .controls .close {
  background-color: #fdb81e !important;
}
.no-skin #emanacje #szablon .tekst {
  color: #fdb81e !important;
}
.no-skin #emanacje #szablon .tekst-container blockquote {
  border-left-color: #fdb81e !important;
}
.no-skin ul {
  color: #fdb81e !important;
}
.no-skin .animation-control {
  display: none !important;
}
.no-skin #first-paragraph .buy-ticket {
  border: 1px #fdb81e solid !important;
  background-color: #212121 !important;
  color: #fdb81e !important;
}
.no-skin #site-preview {
  filter: none !important;
  background-image: none !important;
  -webkit-filter: none !important;
}
.no-skin .item-container .item {
  filter: none !important;
}
.no-skin .gallery-btns #next-modal,
.no-skin .gallery-btns #prev-modal {
  background-color: #212121 !important;
}
.no-skin .visible-Btn {
  background: #212121 !important;
}
.no-skin #condolences .condolences__header,
.no-skin #condolences .single-condolence,
.no-skin #condolences .date,
.no-skin #condolences .freeform-label {
  color: #fdb81e !important;
}
.no-skin #condolences button {
  background: #fdb81e !important;
  color: #212121 !important;
}
.no-skin #condolences .pagination a,
.no-skin #condolences .pagination span {
  color: #fdb81e !important;
}
header {
  position: absolute;
  width: 100%;
  color: #fff;
  z-index: 1000;
}
header div {
  width: 100%;
}
header #logo {
  float: left;
  width: 500px;
  padding: 50px 80px;
  max-width: 100%;
}
@media (max-width: 1023px) {
  header #logo {
    padding: 35px;
    width: 400px;
  }
}
@media (max-width: 767px) {
  header #logo {
    float: none;
    margin: 0 auto;
    display: block;
  }
}
@media (max-width: 500px) {
  header #logo {
    width: 100%;
  }
}
header #top-info-wrapper {
  width: calc(100% - 500px);
  float: right;
  padding-right: 80px;
}
@media (max-width: 1023px) {
  header #top-info-wrapper {
    width: calc(100% - 400px);
    padding-right: 30px;
  }
}
header #top-info-wrapper .info-item {
  width: 290px;
  display: inline-block;
  float: right;
  margin-top: 40px;
  padding-left: 20px;
  border-left: 1px solid #fff;
  color: #fff;
  text-decoration: none;
  height: 57px;
}
header #top-info-wrapper .info-item .date {
  font-weight: bold;
}
header #top-info-wrapper .info-item .txt {
  font-weight: 900;
}
@media (max-width: 1023px) {
  header #top-info-wrapper .info-item {
    margin-top: 25px;
  }
}
@media (max-width: 767px) {
  header #top-info-wrapper .info-item {
    display: none;
  }
}
header #top-info-wrapper .info-item:first-child {
  margin-left: 95px;
}
@media (max-width: 1399px) {
  header #top-info-wrapper .info-item {
    width: 190px;
  }
}
header #top-info-wrapper .info-item .date {
  display: block;
  font-size: 12px;
}
header #top-info-wrapper .info-item .txt {
  font-size: 12px;
  text-transform: uppercase;
}
header #top-info-wrapper .info-item #sponsors {
  width: 100%;
}
header #top-info-wrapper .info-item #sponsors .item {
  display: none;
  width: 100%;
}
header #top-info-wrapper .info-item #sponsors .item img {
  display: block;
  margin: 0 auto;
  height: 57px;
}
header #top-info-wrapper .info-item #sponsors .item:first-child {
  display: inline-block;
}
header .wcag-icon {
  display: none;
}
#playBtn {
  opacity: 0;
}
#pauseBtn {
  opacity: 1;
}
.animation-control {
  display: none;
  z-index: 6000;
  transition: 0.5s;
  opacity: 1;
  position: absolute;
  top: 45px;
  left: 50%;
  height: 50px;
  width: 50px;
  background-color: transparent;
}
.animation-control .playpause-button {
  background-color: transparent;
  width: 100%;
  height: 100%;
}
.no-animation {
  opacity: 0 !important;
}
.btn-fadeIn {
  opacity: 1 !important;
  z-index: 6100 !important;
}
.btn-fadeOut {
  opacity: 0 !important;
}
#preview-menu-bar {
  position: absolute;
  bottom: 55px;
  width: 100%;
}
#preview-menu-bar.affix-top nav {
  background-color: rgba(0,0,0,0.3);
}
#preview-menu-bar nav {
  width: 90%;
  margin: 0 auto;
  border: 1px solid #fff;
  height: 50px;
  position: relative;
}
@media (max-width: 500px) {
  #preview-menu-bar nav {
    height: 30px;
  }
}
#preview-menu-bar nav ul {
  display: inline-block;
  list-style-type: none;
  padding: 0;
  margin-left: 15px;
}
@media (max-width: 500px) {
  #preview-menu-bar nav ul {
    display: none;
  }
}
@media (max-width: 1400px) {
  #preview-menu-bar nav ul {
    display: none;
  }
}
#preview-menu-bar nav ul li {
  text-align: center;
  display: inline-block;
  margin: 0 4.5px;
  position: relative;
}
@media (max-width: 1400px) {
  #preview-menu-bar nav ul li {
    margin-right: 7px;
  }
}
#preview-menu-bar nav ul li:last-child {
  margin-right: 0;
}
#preview-menu-bar nav ul li .ui-effects-placeholder {
  margin-top: 30px !important;
  height: 0px !important;
  visibility: visible !important;
}
#preview-menu-bar nav ul li.wcag {
  height: 48px;
  cursor: pointer;
  margin-right: 15px !important;
  padding: 0px 8px;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}
#preview-menu-bar nav ul li.bilety {
  height: 100%;
}
#preview-menu-bar nav ul li.menu-right-svg-ico a {
  display: inline-block;
  width: 20px !important;
  text-align: center;
}
#preview-menu-bar nav ul li.menu-right-svg-ico a svg {
  height: 15px;
  fill: #fff;
}
#preview-menu-bar nav ul li.menu-right-svg-ico a svg.icon--accessibility {
  height: 17px;
  transform: translateY(-1px);
  fill: transparent;
}
#preview-menu-bar nav ul li a {
  line-height: 14px;
  padding: 17px 0;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  transition: all 0.2s ease;
  white-space: nowrap;
}
@media (max-width: 500px) {
  #preview-menu-bar nav ul li a {
    padding: 8px 0;
  }
}
#preview-menu-bar nav ul li a.active,
#preview-menu-bar nav ul li a.show-submenu {
  font-weight: bold;
}
#preview-menu-bar nav ul li a span.active {
  content: "";
  display: block;
  position: absolute;
  width: 17px;
  height: 1px;
  background-color: #2aace8;
  margin: 6px calc(50% - 13px) 0;
}
#preview-menu-bar nav ul.submenu {
  margin-left: 5px;
  display: block;
  float: right;
}
#preview-menu-bar nav ul.submenu li:last-child {
  margin-right: 0;
}
#preview-menu-bar nav ul.submenu li:hover > a {
  color: #fff;
}
#preview-menu-bar nav ul.menu > li > a {
  font-size: 14px;
}
#preview-menu-bar nav ul.menu > li > a.show-submenu::after {
  content: ":";
  display: inline;
}
#preview-menu-bar nav ul.menu > li ul.submenu {
  padding-left: 5px;
}
#preview-menu-bar nav ul.menu > li ul.submenu li a {
  font-size: 11px;
  color: #fff;
  font-weight: 700;
}
#preview-menu-bar nav ul.menu > li ul.submenu li.submenu-close {
  color: #fff;
  cursor: pointer;
}
#preview-menu-bar nav ul.links {
  padding-right: 0;
  display: flex;
  float: left;
  flex-direction: row;
  align-items: center;
}
#preview-menu-bar nav ul.links li {
  margin-right: 4px;
  height: 48px;
}
@media (max-width: 504px) {
  #preview-menu-bar nav ul.links li {
    display: none;
  }
  #preview-menu-bar nav ul.links li.wcag {
    display: block;
    margin-top: 10px;
    margin-right: -10px !important;
    border: none;
  }
  #preview-menu-bar nav ul.links li.bilety {
    display: block;
  }
}
@media (max-width: 500px) {
  #preview-menu-bar nav ul.links li.wcag {
    margin-top: 0;
  }
}
#preview-menu-bar nav ul.links li a {
  font-size: 11px;
}
#preview-menu-bar nav ul.links li.menu-right-svg-ico {
  margin-right: 7px !important;
}
#preview-menu-bar nav ul.links li.elements {
  padding: 0 4px;
}
#preview-menu-bar nav ul.links li.bilety {
  margin: 0 !important;
  margin-left: 15px !important;
}
#preview-menu-bar nav .menu-stick-right {
  position: absolute;
  right: 0;
  top: 0;
}
#preview-menu-bar nav .menu-stick-right .btn#rezerwuj {
  font-size: 14px;
  margin: 0 !important;
  padding: 0 30px;
  width: auto !important;
  line-height: 47px;
  background-color: #fff;
  border-radius: 0;
  text-decoration: none;
  color: #131f3c;
  font-weight: 800;
  letter-spacing: -1px;
  text-transform: uppercase;
}
#preview-menu-bar nav .menu-stick-right .btn#rezerwuj:active {
  box-shadow: none;
}
@media (max-width: 500px) {
  #preview-menu-bar nav .menu-stick-right .btn#rezerwuj {
    line-height: 27px;
    padding: 0 20px;
    font-size: 12px;
  }
}
#preview-menu-bar nav #mobile-menu-toggler {
  cursor: pointer;
  display: none;
  padding-left: 15px;
}
@media (max-width: 1400px) {
  #preview-menu-bar nav #mobile-menu-toggler {
    display: inline-block;
  }
}
#preview-menu-bar nav #mobile-menu-toggler .line {
  display: block;
  width: 11px;
  height: 1px;
  background-color: #fff;
  margin-bottom: 10px;
}
@media (max-width: 500px) {
  #preview-menu-bar nav #mobile-menu-toggler .line {
    margin-bottom: 7px;
  }
}
#preview-menu-bar nav #mobile-menu-toggler .line:first-child {
  margin-top: 10px;
  width: 26px;
}
@media (max-width: 500px) {
  #preview-menu-bar nav #mobile-menu-toggler .line:first-child {
    margin-top: 3px;
  }
}
#preview-menu-bar nav #mobile-menu-toggler .line:last-child {
  width: 21px;
}
#preview-menu-bar nav #sticky-menu-logo {
  display: none;
}
#preview-menu-bar.affix {
  position: fixed;
  top: 0;
  bottom: initial;
  z-index: 99999;
  height: 52px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}
@media (max-width: 500px) {
  #preview-menu-bar.affix {
    height: 33px;
  }
}
#preview-menu-bar.affix nav #sticky-menu-logo {
  display: block;
}
@media (max-width: 1500px) {
  #preview-menu-bar.affix nav #sticky-menu-logo {
    display: none;
  }
}
#preview-menu-bar.affix nav #sticky-menu-logo img {
  position: absolute;
  width: 60px;
  margin: 13px 0;
}
#preview-menu-bar.affix nav #mobile-menu-toggler .line {
  display: block;
  background-color: #131f3c;
}
#preview-menu-bar.affix nav ul.menu {
  margin-left: 5vw;
}
#preview-menu-bar.affix nav ul.menu li a {
  color: #131f3c;
}
#preview-menu-bar.affix nav ul.menu li ul.submenu li a {
  color: #131f3c;
}
#preview-menu-bar.affix nav ul.menu li ul.submenu li.submenu-close {
  color: #131f3c;
}
#preview-menu-bar.affix .menu-stick-right ul li.wcag {
  border-color: #131f3c;
  color: #131f3c;
}
#preview-menu-bar.affix .menu-stick-right ul li a {
  color: #131f3c;
}
#preview-menu-bar.affix .menu-stick-right ul li.menu-right-svg-ico a svg {
  fill: #131f3c;
}
#preview-menu-bar.affix .menu-stick-right ul li.menu-right-svg-ico a svg.icon--accessibility {
  fill: transparent;
}
#preview-menu-bar.affix .menu-stick-right ul li.menu-right-svg-ico a svg.icon--accessibility path#human {
  fill: #131f3c;
}
#preview-menu-bar.affix .menu-stick-right ul li.menu-right-svg-ico a svg.icon--accessibility path#outline {
  stroke: #131f3c;
}
#preview-menu-bar.affix .menu-stick-right .btn#rezerwuj {
  background-color: #131f3c;
  color: #fff;
  line-height: 48px;
}
@media (max-width: 500px) {
  #preview-menu-bar.affix .menu-stick-right .btn#rezerwuj {
    line-height: 30px;
  }
}
#mobile-menu-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0,92,156,0.9);
  z-index: 999999;
  padding: 15% 10%;
  display: none;
}
@media screen and (max-width: 1400px) and (orientation: landscape) {
  #mobile-menu-container {
    padding: 50px;
  }
}
@media (max-width: 500px) {
  #mobile-menu-container {
    padding: 15% 5%;
  }
}
#mobile-menu-container .mobile-menu-wrapper {
  position: absolute;
  height: 80%;
  overflow-y: auto;
}
#mobile-menu-container .mobile-menu-wrapper ul {
  list-style-type: none;
  padding: 0;
}
#mobile-menu-container .mobile-menu-wrapper ul li a {
  font-size: 30px;
  color: #fff;
  text-decoration: none;
}
@media (max-width: 500px) {
  #mobile-menu-container .mobile-menu-wrapper ul li a {
    font-size: 22px;
  }
}
#mobile-menu-container .mobile-menu-wrapper ul li a.active {
  font-weight: bold;
  color: #2aace8;
}
#mobile-menu-container .mobile-menu-wrapper ul li a.active::before {
  opacity: 1;
}
#mobile-menu-container .mobile-menu-wrapper ul li a:hover {
  font-weight: bold;
  text-decoration: none;
}
#mobile-menu-container .mobile-menu-wrapper ul li.wcag {
  display: none;
}
#mobile-menu-container .mobile-menu-wrapper ul li.menu-right-svg-ico {
  padding-left: 10px;
}
#mobile-menu-container .mobile-menu-wrapper ul li.menu-right-svg-ico a::before {
  display: none;
}
#mobile-menu-container .mobile-menu-wrapper ul li.menu-right-svg-ico a svg {
  height: 20px;
  fill: #fff;
}
#mobile-menu-container .mobile-menu-wrapper ul li.menu-right-svg-ico a svg.icon--accessibility {
  fill: none;
}
#mobile-menu-container .mobile-menu {
  display: block;
}
#mobile-menu-container .mobile-menu li {
  margin-bottom: 30px;
}
#mobile-menu-container .mobile-menu li .submenu {
  margin: 65px 0;
}
#mobile-menu-container .mobile-menu li .submenu li {
  margin-bottom: 20px;
}
#mobile-menu-container .mobile-menu li .submenu li a {
  color: #2aace8;
  font-size: 16px;
  padding-left: 15px;
}
@media (max-width: 500px) {
  #mobile-menu-container .mobile-menu li .submenu li a {
    font-size: 10px;
  }
}
#mobile-menu-container .mobile-menu li .submenu .submenu-close {
  display: none;
}
#mobile-menu-container .links li {
  display: inline-block;
  float: left;
  padding-bottom: 10px;
  padding-right: 15px;
  padding-left: 15px;
}
@media (max-width: 800px) {
  #mobile-menu-container .links li a {
    font-size: 22px !important;
  }
}
@media (max-width: 550px) {
  #mobile-menu-container .links li a {
    font-size: 16px !important;
  }
}
@media (max-width: 450px) {
  #mobile-menu-container .links li a {
    font-size: 13px !important;
  }
}
@media (max-width: 400px) {
  #mobile-menu-container .links li {
    padding-right: 10px;
  }
  #mobile-menu-container .links li a {
    font-size: 11px !important;
  }
}
@media (max-width: 500px) {
  #mobile-menu-container .links li {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}
#mobile-menu-container #rezerwuj {
  display: none;
}
#mobile-menu-container .mobile-menu-close {
  position: absolute;
  top: 3%;
  right: 3%;
  cursor: pointer;
}
#mobile-menu-container .mobile-menu-close.close-wcag {
  display: none;
}
.preventer {
  overflow-y: scroll !important;
}
.preventer-second {
  display: none !important;
}
footer #logos {
  margin: 85px 0;
}
footer #logos #ue-col {
  border-right: 1px solid #131f3c;
}
@media screen and (max-width: 990px) {
  footer #logos #ue-col {
    border-right: none;
  }
}
footer #logos #ue-col .txt {
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  padding: 15px 0;
}
footer #logos #partnerzy-col .item {
  display: block;
}
@media (min-width: 992px) {
  footer #logos #partnerzy-col .item {
    width: 33%;
  }
}
@media (min-width: 1199px) {
  footer #logos #partnerzy-col .item {
    width: 20%;
  }
}
@media (max-width: 991px) {
  footer #logos #partnerzy-col .item {
    padding: 15px 0;
  }
}
footer #dark-ending {
  padding: 40px 0 30px;
  background-color: #000;
}
footer #dark-ending #logo {
  padding: 10px 30px 0;
}
@media screen and (max-width: 1400px) and (orientation: landscape) {
  footer #dark-ending #logo {
    padding: 0;
  }
  footer #dark-ending #logo img {
    max-width: 80%;
  }
}
footer #dark-ending #txts {
  font-size: 14px;
  color: #979797;
}
@media screen and (max-width: 990px) {
  footer #dark-ending #txts {
    text-align: center;
  }
}
footer #dark-ending #txts h1,
footer #dark-ending #txts h2,
footer #dark-ending #txts h3,
footer #dark-ending #txts h4 {
  font-weight: 900;
  font-size: 14px;
}
footer #dark-ending .col-md-9 .row .extra-padding {
  padding-top: 46px;
}
footer #dark-ending .col-md-9 .row a {
  color: #979797;
}
footer #dark-ending .col-md-9 .row .col-md-2 .contain-footer {
  position: relative;
  top: 80px;
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 1439px) {
  footer #dark-ending .col-md-9 .row .col-md-2 .contain-footer {
    top: 98px;
  }
}
@media screen and (max-width: 1198px) {
  footer #dark-ending .col-md-9 .row .col-md-2 .contain-footer {
    top: 118px;
  }
}
footer #dark-ending .col-md-9 .row .col-md-2 .contain-footer .availability {
  margin: 0;
}
footer #dark-ending .col-md-9 .row .col-md-2 .contain-footer .availability .wheelchair {
  fill: #808080;
  height: 22px;
  color: #808080;
}
footer #dark-ending .col-md-9 .row .col-md-2 .contain-footer .availability .dost {
  text-decoration: none !important;
  color: #979797;
  text-decoration-color: transparent !important;
}
footer #dark-ending .col-md-9 .row .col-md-2 .contain-footer .text-availability {
  padding-top: 6px;
  padding-left: 6px;
}
footer #dark-ending #pleo-footer {
  display: block;
  margin-right: 30px;
  float: right;
  width: 45px;
}
footer #scroll-up {
  display: none;
}
.amp {
  transition: 0.5s;
  position: absolute;
  opacity: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.amp.top .ampBar {
  top: 0;
}
.amp.bottom .ampBar {
  bottom: 0;
}
.amp .ampBar {
  position: absolute;
  width: 1px;
}
#site-preview {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
#site-preview #mute {
  position: absolute;
  z-index: 999;
  top: -70px;
  right: 0;
  background: 0 0;
  border: 0px solid #fff;
  color: #fff;
  outline: none;
  padding: 12px;
}
@media screen and (max-width: 500px) {
  #site-preview #mute {
    right: 0;
  }
}
#site-preview #mute img {
  width: 24px;
  height: 24px;
  display: none;
}
#site-preview #mute .active {
  display: block;
}
#site-preview .header-txt {
  position: absolute;
  left: calc(50% - 585px);
  width: 1170px;
  bottom: 210px;
}
@media screen and (max-width: 1400px) {
  #site-preview .header-txt {
    width: 90%;
    left: 5%;
  }
}
@media screen and (max-width: 650px) {
  #site-preview .header-txt {
    bottom: 120px;
  }
}
#site-preview .header-txt p {
  font-size: 18px;
  color: #fff;
  line-height: 30px;
  width: 83.33333%;
}
@media screen and (max-width: 1023px) {
  #site-preview .header-txt p {
    width: 100%;
  }
}
@media screen and (max-width: 650px) {
  #site-preview .header-txt p {
    display: none;
  }
}
#site-preview .header-txt h1 {
  font-family: 'Jaapokki';
  font-size: 91px;
  color: #fff;
  margin-top: 0;
  margin-left: -4px;
}
@media (max-width: 1400px) {
  #site-preview .header-txt h1 {
    font-size: 65px;
  }
}
@media (max-width: 1000px) {
  #site-preview .header-txt h1 {
    font-size: 55px;
  }
}
@media (max-width: 550px) {
  #site-preview .header-txt h1 {
    font-size: 40px;
  }
}
#site-preview .header-txt h2 {
  font-size: 69px;
  font-weight: 300;
  color: #fff;
}
@media (max-width: 1400px) {
  #site-preview .header-txt h2 {
    font-size: 50px;
  }
}
@media (max-width: 1000px) {
  #site-preview .header-txt h2 {
    font-size: 40px;
  }
}
@media (max-width: 550px) {
  #site-preview .header-txt h2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 1000px) and (orientation: landscape) {
  #site-preview .header-txt {
    top: 30%;
  }
  #site-preview .header-txt h1 {
    font-size: 50px;
  }
  #site-preview .header-txt p {
    font-size: 14px;
    line-height: 20px;
  }
}
#site-preview .header-txt.home {
  left: calc(50% - 650px);
  width: 1300px;
  top: 40%;
  text-align: center;
}
@media (max-width: 1400px) {
  #site-preview .header-txt.home {
    left: 50px;
    width: calc(100% - 100px);
  }
}
#site-preview .header-txt.home h2 {
  font-size: 48px;
}
@media (max-width: 1400px) {
  #site-preview .header-txt.home h2 {
    font-size: 40px;
  }
}
@media (max-width: 1000px) {
  #site-preview .header-txt.home h2 {
    font-size: 30px;
  }
}
@media (max-width: 550px) {
  #site-preview .header-txt.home h2 {
    font-size: 25px;
  }
}
@media (max-width: 400px) {
  #site-preview .header-txt.home h2 {
    font-size: 20px;
  }
}
#site-preview .header-txt.home h1,
#site-preview .header-txt.home h2 {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
}
#site-preview .header-txt.home h1 {
  font-size: 79px;
}
@media (max-width: 1400px) {
  #site-preview .header-txt.home h1 {
    font-size: 65px;
  }
}
@media (max-width: 1000px) {
  #site-preview .header-txt.home h1 {
    font-size: 55px;
  }
}
@media (max-width: 550px) {
  #site-preview .header-txt.home h1 {
    font-size: 40px;
  }
}
@media (max-width: 400px) {
  #site-preview .header-txt.home h1 {
    font-size: 28px;
  }
}
#site-preview #heder-amp-contianer {
  position: absolute;
  height: calc(100% - 140px);
  width: 100%;
  bottom: 140px;
  left: 0;
}
@media (max-width: 1023px) {
  #site-preview #heder-amp-contianer {
    display: none;
  }
}
#site-preview #heder-amp-contianer > div {
  height: 100%;
}
@media (max-width: 1400px) {
  #site-preview #heder-amp-contianer > div {
    width: 90%;
  }
}
#site-preview #banner-blend {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.66;
  background: url("../img/home-banner-blend.png") center bottom repeat-x;
}
#site-preview #video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
#site-preview #video-container:after {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.05);
  content: '';
  position: absolute;
  z-index: 1;
}
#site-preview #video-container video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.condolences-free-form-headline {
  font-size: 18px !important;
  color: #131f3c;
  font-weight: 800;
  margin-bottom: 30px;
  margin-top: 60px;
}
.condolences-free-form {
  margin-bottom: 60px;
}
.condolences-free-form .freeform-row {
  margin: 0;
  width: 100%;
}
.condolences-free-form .freeform-row button {
  padding: 0 10px !important;
  line-height: 48px !important;
  color: #fff;
  font-weight: bold !important;
  background-color: #131f3c !important;
  text-transform: uppercase !important;
  border: none !important;
  width: 100px !important;
  margin-top: 20px;
}
.condolences-free-form .freeform-row:last-of-type {
  width: 70%;
}
@media screen and (max-width: 768px) {
  .condolences-free-form .freeform-row:last-of-type {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .condolences-free-form .freeform-row .freeform-row:last-of-type {
    width: 100%;
  }
}
.condolences-free-form .freeform-row .freeform-column {
  margin: 0;
}
.condolences-free-form .freeform-row .freeform-column .freeform-input {
  border: 1px solid #131f3c;
  margin: 0;
}
#homepage .heading {
  width: 100%;
  height: auto;
  margin: 0 0 10px 0;
  clear: both;
  position: relative;
  z-index: 2;
  display: none;
}
@media screen and (max-width: 770px) {
  #homepage .heading {
    padding: 0 15px;
  }
}
#homepage .heading.right-view-margin {
  margin-bottom: 40px;
}
@media screen and (max-width: 770px) {
  #homepage .heading.right-view-margin {
    margin-bottom: 10px;
  }
}
#homepage .heading .title {
  font-weight: 500;
  font-size: 18px;
  color: #282828;
  display: inline-block;
}
#homepage .heading .lines {
  margin: 4px 0 0 15px;
  display: inline-block;
}
#homepage .heading .lines span {
  height: 1px;
  display: inline-block;
  width: 17px;
  background-color: #282828;
}
#homepage .heading .lines span:first-child {
  width: 27px;
}
#homepage .heading .lines span:last-child {
  width: 5px;
}
#homepage .heading .show-all {
  color: #2aace8;
  font-size: 10px;
  font-weight: bold;
  text-decoration: none;
  margin: 3px 0 0 15px;
  display: inline-block;
}
#homepage #first-section {
  background-color: #eee;
}
#homepage #first-section .container {
  padding: 60px 0;
}
#homepage #first-section .news {
  width: 100%;
  height: auto;
  margin: 0;
  clear: both;
  position: relative;
  z-index: 2;
}
#homepage #first-section .news .items {
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 50px;
}
#homepage #first-section .news .items .arrows {
  width: auto;
  height: auto;
  box-sizing: border-box;
  display: inline-block;
  position: absolute;
  top: 3px;
  right: -35px;
}
@media screen and (max-width: 770px) {
  #homepage #first-section .news .items .arrows {
    position: initial;
    margin-bottom: 10px;
  }
}
#homepage #first-section .news .items .arrows .arrow {
  display: inline-block;
  cursor: pointer;
  margin-right: 15px;
}
#homepage #first-section .news .items .arrows .arrow:last-child {
  margin-right: 0;
}
#homepage #first-section .news .items .item {
  width: 100%;
  height: auto;
  position: relative;
  clear: both;
  display: none;
}
#homepage #first-section .news .items .item:first-child {
  display: inline-block;
}
#homepage #first-section .news .items .item:hover .image .layer {
  opacity: 0.66;
}
#homepage #first-section .news .items .item.right-view .image {
  right: 0;
}
#homepage #first-section .news .items .item.right-view .arrows {
  width: 46.5%;
  text-align: left;
  right: 0;
}
@media screen and (max-width: 770px) {
  #homepage #first-section .news .items .item.right-view .arrows {
    background-color: #fff;
    width: 100%;
    position: static;
    padding: 15px 10px 0 10px;
  }
}
#homepage #first-section .news .items .item.right-view .text_side {
  padding: 50px 80px 50px 50px;
  float: left;
}
@media screen and (max-width: 770px) {
  #homepage #first-section .news .items .item.right-view .text_side {
    width: 100%;
    min-height: auto;
    padding: 30px;
  }
}
#homepage #first-section .news .items .item .image {
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  width: 40%;
  height: 265px;
  margin: 30px 0 0 0;
  right: 0;
  overflow: hidden;
  position: absolute;
  z-index: 6;
  background-color: #fff;
}
@media screen and (max-width: 770px) {
  #homepage #first-section .news .items .item .image {
    width: 100%;
    position: relative;
  }
}
#homepage #first-section .news .items .item .image img {
  width: 100%;
  height: auto;
  display: block;
}
#homepage #first-section .news .items .item .image .layer {
  background-color: #2aace8;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  opacity: 0;
  transition: opacity 0.4s ease;
}
#homepage #first-section .news .items .item .text_side {
  background-color: #fff;
  width: 63%;
  height: 265px;
  padding: 50px 80px 50px 50px;
  float: left;
  margin-bottom: 45px;
  position: relative;
}
@media screen and (max-width: 770px) {
  #homepage #first-section .news .items .item .text_side {
    width: 100%;
    min-height: auto;
    padding: 30px;
  }
}
@media screen and (max-width: 500px) {
  #homepage #first-section .news .items .item .text_side {
    width: 100%;
    height: 360px;
    min-height: auto;
    padding: 30px;
  }
}
#homepage #first-section .news .items .item .text_side h2,
#homepage #first-section .news .items .item .text_side h2 a {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #131f3c;
  margin: 0;
  margin-bottom: 20px;
  box-sizing: border-box;
  text-decoration: none;
  white-space: normal;
}
#homepage #first-section .news .items .item .text_side p {
  font-size: 14px;
  color: #131f3c;
  line-height: 27px;
  margin: 0;
  padding: 0;
  text-align: justify;
  white-space: normal;
}
#homepage #first-section .news .items .item .text_side a.btn {
  float: right;
  margin-top: 20px;
  border-radius: 0;
  background-color: #221d14;
  line-height: 43px;
  padding: 0 21px;
  font-weight: 600 !important;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  position: absolute;
  right: 80px;
  bottom: 40px;
}
@media screen and (max-width: 1200px) {
  #homepage #first-section .news .items .item .text_side a.btn {
    position: initial;
  }
}
#homepage #first-section .news .items.first-row .item .image {
  right: initial;
  left: 0;
}
#homepage #first-section .news .items.first-row .item .text_side {
  padding: 50px 50px 50px 80px;
  float: right;
}
@media screen and (max-width: 770px) {
  #homepage #first-section .news .items.first-row .item .text_side {
    width: 100%;
    min-height: auto;
    padding: 30px;
  }
}
#homepage #first-section .news .items.first-row .item .text_side a.btn {
  right: 50px;
}
#homepage #first-section .news .items.first-row .item .arrows {
  right: 102%;
  text-align: right;
}
#homepage #first-section .news:nth-child(2n ) .items .image {
  right: initial;
  left: 0;
}
#homepage #first-section .news:nth-child(2n ) .items .text_side {
  padding: 50px 50px 50px 80px;
  float: right;
}
@media screen and (max-width: 770px) {
  #homepage #first-section .news:nth-child(2n ) .items .text_side {
    width: 100%;
    min-height: auto;
    padding: 30px;
  }
}
#homepage #first-section .news:nth-child(2n ) .items .text_side a.btn {
  right: 50px;
}
#homepage #first-section .news:nth-child(2n ) .items .arrows {
  right: 102%;
  text-align: right;
}
#homepage #poznaj-centrum {
  background-image: url("../img/tmp/home/poznaj_bg_new.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100vh;
  margin: -20px 0 0 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
}
@media screen and (max-width: 1200px) {
  #homepage #poznaj-centrum {
    height: auto;
    padding: 100px 0;
  }
}
#homepage #poznaj-centrum #poznaj-centrum-content {
  width: 79%;
  height: auto;
  margin: 0 0 0 -70px;
}
@media screen and (max-width: 1200px) {
  #homepage #poznaj-centrum #poznaj-centrum-content {
    width: 100%;
    margin: 0;
  }
}
#homepage #poznaj-centrum .text {
  background-color: rgba(0,0,0,0.77);
  width: 100%;
  height: auto;
  padding: 55px;
  box-sizing: border-box;
  clear: both;
}
#homepage #poznaj-centrum .text h2 {
  font-size: 91px;
  font-family: 'Jaapokki';
  color: #fff;
  margin: 0 0 50px 0;
}
@media screen and (max-width: 990px) {
  #homepage #poznaj-centrum .text h2 {
    font-size: 70px;
  }
}
@media screen and (max-width: 770px) {
  #homepage #poznaj-centrum .text h2 {
    font-size: 50px;
  }
}
#homepage #poznaj-centrum .text p {
  width: 100%;
  font-size: 14px;
  line-height: 28px;
  color: #fff;
  text-align: justify;
  clear: both;
}
#homepage #poznaj-centrum .read-more {
  background-color: rgba(0,0,0,0.77);
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  padding: 12px 40px;
  margin: 0 -20px 0 0;
  float: right;
}
@media screen and (max-width: 1200px) {
  #homepage #poznaj-centrum .read-more {
    margin: 10px 0 0 0;
    float: right;
  }
}
#homepage #zostan-patronem {
  background-color: #000;
  background-image: url("../img/tmp/home/zostan-patronem-bg.jpg");
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 145px 0 155px;
}
@media screen and (max-width: 1450px) {
  #homepage #zostan-patronem {
    background-position: 120% 0;
  }
}
@media screen and (max-width: 1200px) {
  #homepage #zostan-patronem {
    background-image: none;
  }
}
@media screen and (max-width: 990px) {
  #homepage #zostan-patronem {
    background-position: top center;
    background-size: cover;
  }
}
#homepage #zostan-patronem h1 {
  margin: 0 0 30px;
  color: #fff;
  font-size: 80px;
  font-family: 'Jaapokki';
}
@media screen and (max-width: 990px) {
  #homepage #zostan-patronem h1 {
    font-size: 70px;
  }
}
@media screen and (max-width: 770px) {
  #homepage #zostan-patronem h1 {
    font-size: 55px;
  }
}
#homepage #zostan-patronem p {
  width: 65%;
  font-size: 14px;
  line-height: 28px;
  color: #fff;
}
@media screen and (max-width: 990px) {
  #homepage #zostan-patronem p {
    width: 100%;
  }
}
#homepage #zostan-patronem a {
  background-color: #2aace8;
  color: #000;
  border-radius: 0;
  padding: 0 28px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 50px;
  line-height: 44px;
}
#homepage #programy-preview {
  width: 100%;
}
#homepage #programy-preview .heading {
  margin-top: 70px;
}
#homepage #items {
  width: 100%;
  height: auto;
  margin: 40px auto;
  margin-bottom: 0;
}
#homepage #items .item {
  width: 100%;
  height: auto;
  clear: both;
}
#homepage #items .item:nth-child(even) .left_side {
  float: right;
  padding: 40px 0 40px 40px;
}
@media screen and (max-width: 770px) {
  #homepage #items .item:nth-child(even) .left_side {
    padding: 25px 20px;
  }
}
#homepage #items .item:nth-child(even) .right_side .img_container {
  left: 0;
}
#homepage .left_side {
  width: 50%;
  height: 300px;
  padding: 40px 40px 40px 0;
  box-sizing: border-box;
  float: left;
}
@media screen and (max-width: 770px) {
  #homepage .left_side {
    width: 100%;
    height: auto;
    padding: 25px 20px;
    clear: both;
  }
}
#homepage .left_side h2 {
  font-size: 14px;
  color: #131f3c;
  font-weight: bold;
  margin: 0;
  margin-bottom: 40px;
  text-transform: uppercase;
}
#homepage .left_side p {
  font-size: 14px;
  color: #131f3c;
  line-height: 28px;
  text-align: justify;
}
#homepage .left_side a {
  padding: 0 30px;
  line-height: 44px;
  color: #fff;
  background-color: #000;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 0;
  margin-top: 0;
}
#homepage .right_side {
  width: 50%;
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 770px) {
  #homepage .right_side {
    width: 100%;
    clear: both;
  }
}
#homepage .right_side .img_container {
  background-size: cover;
  width: 50%;
  height: 300px;
  position: absolute;
  right: 0;
}
@media screen and (max-width: 770px) {
  #homepage .right_side .img_container {
    width: 100%;
    position: initial;
  }
}
.odd .lista .item {
  flex-direction: row-reverse !important;
}
.odd .lista .item .text {
  padding-right: 50px !important;
  padding-left: 0 !important;
}
@media screen and (max-width: 990px) {
  .odd .lista .item .text {
    padding: 0 25px !important;
  }
}
.odd .lista .item:nth-of-type(2n - 1 ) {
  flex-direction: row !important;
}
.odd .lista .item:nth-of-type(2n - 1 ) .text {
  padding-left: 50px !important;
  padding-right: 0 !important;
}
@media screen and (max-width: 990px) {
  .odd .lista .item:nth-of-type(2n - 1 ) .text {
    padding: 0 25px !important;
  }
}
.news-type-list > #loading {
  display: none !important;
}
.news-type-list #loading {
  padding: 80px 0;
}
.news-type-list > #loading-emanations {
  display: none !important;
}
.news-type-list #loading-emanations {
  padding: 80px 0;
}
.news-type-list #loading-emanations img {
  display: block;
  margin: 0 auto;
}
.news-type-list .load-more {
  display: block;
  font-weight: bold;
  color: #131f3c;
  font-size: 16px;
  text-transform: lowercase;
  text-align: center;
  text-decoration: none;
}
.news-type-list .lista-emanacji {
  padding-top: 135px;
}
@media screen and (max-width: 990px) {
  .news-type-list .lista-emanacji {
    padding-top: 50px;
  }
}
.news-type-list .lista {
  padding-top: 135px;
}
@media screen and (max-width: 990px) {
  .news-type-list .lista {
    padding-top: 50px;
  }
}
@media (min-width: 1700px) {
  .news-type-list .lista {
    min-height: 1000px;
  }
}
.news-type-list .lista .item,
.news-type-list .lista-emanacji .item {
  margin-bottom: 135px;
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 990px) {
  .news-type-list .lista .item,
  .news-type-list .lista-emanacji .item {
    display: block;
    margin-bottom: 80px;
  }
}
.news-type-list .lista .item:nth-of-type(2n - 1 ),
.news-type-list .lista-emanacji .item:nth-of-type(2n - 1 ) {
  flex-direction: row-reverse;
}
.news-type-list .lista .item:nth-of-type(2n - 1 ) .text,
.news-type-list .lista-emanacji .item:nth-of-type(2n - 1 ) .text {
  padding-left: 0;
  padding-right: 50px;
}
@media screen and (max-width: 990px) {
  .news-type-list .lista .item:nth-of-type(2n - 1 ) .text,
  .news-type-list .lista-emanacji .item:nth-of-type(2n - 1 ) .text {
    padding: 0 25px;
  }
}
.news-type-list .lista .item .img,
.news-type-list .lista-emanacji .item .img {
  background-color: #fff;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 50%;
  padding: 0;
  float: right;
  object-fit: contain;
  max-height: 340px;
}
@media screen and (max-width: 990px) {
  .news-type-list .lista .item .img,
  .news-type-list .lista-emanacji .item .img {
    width: 100%;
    margin-bottom: 20px;
    float: none;
    clear: both;
    display: block;
    max-height: 300px;
  }
}
.news-type-list .lista .item .img img,
.news-type-list .lista-emanacji .item .img img {
  width: 100%;
}
.news-type-list .lista .item .text,
.news-type-list .lista-emanacji .item .text {
  width: 50%;
  padding: 0;
  padding-left: 50px;
  float: left;
}
@media screen and (max-width: 990px) {
  .news-type-list .lista .item .text,
  .news-type-list .lista-emanacji .item .text {
    width: 100%;
    padding: 0 25px;
    float: none;
    clear: both;
  }
}
.news-type-list .lista .item .text h2,
.news-type-list .lista-emanacji .item .text h2 {
  font-size: 18px;
  font-weight: bold;
  color: #131f3c;
  margin: 0 0 20px;
}
.news-type-list .lista .item .text h2 a,
.news-type-list .lista-emanacji .item .text h2 a {
  color: #131f3c;
}
.news-type-list .lista .item .text span.tag,
.news-type-list .lista-emanacji .item .text span.tag {
  color: #2aace8;
  font-size: 14px;
  font-style: italic;
  text-decoration: none;
  text-transform: lowercase;
}
.news-type-list .lista .item .text p,
.news-type-list .lista-emanacji .item .text p {
  margin-top: 40px;
  color: #131f3c;
  font-size: 14px;
  line-height: 28px;
}
.news-type-list .lista .item .text p a,
.news-type-list .lista-emanacji .item .text p a {
  color: #2aace8;
  text-decoration: none;
  font-size: 14px;
  line-height: 28px;
}
.news-type-list .lista .item .text p a:hover,
.news-type-list .lista-emanacji .item .text p a:hover {
  color: #2aace8;
}
.news-type-list .lista .item .text .read-more,
.news-type-list .lista-emanacji .item .text .read-more {
  color: #137eb0;
  text-decoration: none;
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
  cursor: pointer;
  margin-bottom: 40px;
  display: inline-block;
}
.news-type-list .lista .item .text .read-more:hover,
.news-type-list .lista-emanacji .item .text .read-more:hover {
  color: #137eb0;
}
.news-type-list .lista .item .text table,
.news-type-list .lista-emanacji .item .text table {
  margin-top: 55px;
  border: 1px solid #131f3c;
}
.news-type-list .lista .item .text table td.day,
.news-type-list .lista-emanacji .item .text table td.day {
  padding: 6px 15px;
}
.news-type-list .lista .item .text table td.hour,
.news-type-list .lista-emanacji .item .text table td.hour {
  padding: 6px 23px;
}
.news-type-list .lista .item .text table td.hour,
.news-type-list .lista-emanacji .item .text table td.hour,
.news-type-list .lista .item .text table td.day,
.news-type-list .lista-emanacji .item .text table td.day {
  border-right: 1px solid #131f3c;
  color: #131f3c;
  font-size: 14px;
  font-weight: bold;
}
.news-type-list .lista .item .text table td.rezerwuj,
.news-type-list .lista-emanacji .item .text table td.rezerwuj {
  background-color: #131f3c;
  color: #fff;
  font-weight: bold;
  padding: 6px 50px;
}
.news-type-list .lista .item .text table td.rezerwuj a,
.news-type-list .lista-emanacji .item .text table td.rezerwuj a {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
}
.news-type-list .lista .item .text table td.rezerwuj a:hover,
.news-type-list .lista-emanacji .item .text table td.rezerwuj a:hover {
  color: #fff;
}
.condolence {
  background-color: rgba(0,0,0,0.88) !important;
}
.condolence .close {
  background-color: rgba(0,0,0,0.88) !important;
}
#news-popup {
  position: absolute;
  display: none;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,9,0.88);
  z-index: 999999;
}
#news-popup .main-news-img {
  margin-top: 80px;
  background-color: #fff;
  display: flex;
  width: 100%;
  aspect-ratio: 16/9;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
#news-popup .main-news-img img {
  object-fit: contain;
  width: 100%;
}
@media screen and (max-width: 1199px) {
  #news-popup .main-news-img {
    margin-top: 0;
  }
}
#news-popup > .container {
  position: relative;
}
#news-popup > .container .content {
  margin-top: 5%;
  margin-bottom: 10%;
  padding: 0 100px 100px;
  background-color: #fff;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 992px) {
  #news-popup > .container .content {
    padding: 80px 50px 50px;
  }
}
@media screen and (max-width: 700px) {
  #news-popup > .container .content {
    padding: 80px 30px 30px;
  }
}
@media screen and (max-width: 470px) {
  #news-popup > .container .content {
    padding: 80px 15px 15px;
  }
}
#news-popup > .container .content.newsletter-popup {
  padding: 0 100px;
}
@media screen and (max-width: 992px) {
  #news-popup > .container .content.newsletter-popup {
    padding: 0 50px;
  }
}
@media screen and (max-width: 700px) {
  #news-popup > .container .content.newsletter-popup {
    padding: 0 30px;
  }
}
@media screen and (max-width: 470px) {
  #news-popup > .container .content.newsletter-popup {
    padding: 0 15px;
  }
}
#news-popup > .container .content .close {
  position: absolute;
  right: 0;
  top: 0;
  width: 45px;
  height: 45px;
  background-color: rgba(8,54,91,0.93);
  opacity: 1;
}
#news-popup > .container .content .close img {
  padding: 10px;
}
#news-popup > .container .content h1 {
  font-size: 24px;
  color: #131f3c;
  margin-top: 55px;
  margin-bottom: 25px;
  font-weight: bold;
}
#news-popup > .container .content .informations {
  margin-bottom: 80px;
}
#news-popup > .container .content .container {
  width: 100%;
}
#news-popup > .container .content .controls {
  margin-top: 50px;
}
#news-popup > .container .content .controls .close-wcag {
  display: none;
}
#news-popup > .container .content .controls .nav-btn {
  display: inline-block;
  border: 1px solid #131f3c;
  padding: 15px 35px;
  font-weight: bold;
  cursor: pointer;
}
#news-popup > .container .content .controls .nav-btn.prev {
  float: left;
}
#news-popup > .container .content .controls .nav-btn.next {
  float: right;
}
.informations {
  width: auto;
  height: auto;
  margin: 20px 0 0 0;
  clear: both;
}
.informations .information_item {
  width: auto;
  height: auto;
  padding: 11px 20px;
  font-size: 14px;
  color: #131f3c;
  font-weight: bold;
  border: 1px solid #131f3c;
  margin-left: -1px;
  float: left;
}
@media screen and (max-width: 470px) {
  .informations .information_item {
    width: 100%;
    text-align: center;
    float: none;
    clear: both;
  }
}
.informations .information_item.book-btn {
  background-color: #131f3c;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0;
}
.informations .information_item.book-btn a {
  color: #fff;
  padding: 11px 45px;
  display: block;
  text-decoration: none;
}
@media screen and (max-width: 470px) {
  .informations .information_item.book-btn a {
    padding: 11px 20px;
  }
}
#sticky-news-nav {
  background-color: #fff;
  width: 250px;
  height: auto;
  padding: 30px;
  top: 70px;
  left: 0;
  position: absolute;
  z-index: 999;
}
@media (max-width: 1700px) {
  #sticky-news-nav {
    display: none;
  }
}
#sticky-news-nav #show-all {
  font-size: 11px;
  color: #131f3c;
  font-weight: bold;
  padding-left: 20px;
  cursor: pointer;
}
#sticky-news-nav.affix {
  position: fixed;
  top: 70px;
  left: 0;
}
#sticky-news-nav .select-box {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  clear: both;
}
#sticky-news-nav .select-box:last-child {
  margin-bottom: 0;
}
#sticky-news-nav .select-box .heading {
  width: 100%;
  height: auto;
  font-size: 13px;
  font-family: Open Sans;
  color: #131f3c;
  padding: 14px 17px 5px 17px;
  box-sizing: border-box;
  clear: both;
  text-transform: uppercase;
  font-weight: bold;
}
#sticky-news-nav .select-box .heading::after {
  content: "";
  display: block;
  width: 45px;
  height: 1px;
  background-color: #131f3c;
  margin-top: 30px;
}
#sticky-news-nav .select-box .heading .arrow {
  font-size: 20px;
  margin-top: -4px;
  float: right;
  cursor: pointer;
  transition: all 0.4s ease;
}
#sticky-news-nav .select-box.collapsed .arrow {
  transform: rotate(-180deg);
}
#sticky-news-nav .select-box .list {
  width: 100%;
  height: auto;
  padding: 25px 0;
  box-sizing: border-box;
  clear: both;
}
#sticky-news-nav .select-box .list ul {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}
#sticky-news-nav .select-box .list ul li {
  width: 100%;
  height: auto;
  font-size: 11px;
  font-family: Open Sans;
  color: #131f3c;
  padding: 0 22px;
  margin-bottom: 12px;
  position: relative;
  clear: both;
  text-transform: uppercase;
}
#sticky-news-nav .select-box .list ul li:last-child {
  margin-bottom: 0;
}
#sticky-news-nav .select-box .list ul li.active {
  font-weight: bold;
}
#sticky-news-nav .select-box .list ul li.active::before {
  background-color: #131f3c;
  width: 18px;
  height: 1px;
  content: '';
  top: 7px;
  left: 0;
  position: absolute;
  z-index: 9;
}
#sticky-news-nav .select-box .list ul li a {
  color: #131f3c;
  text-decoration: none;
}
#sticky-news-nav .select-box .list ul li a:hover {
  font-weight: bold;
}
#sticky-news-nav .select-box .list-checkbox {
  width: 100%;
  height: 120px;
  margin: 25px 0 0 0;
  overflow-y: auto;
  box-sizing: border-box;
  clear: both;
}
#sticky-news-nav .select-box .list-checkbox ul {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}
#sticky-news-nav .select-box .list-checkbox ul li {
  width: 100%;
  height: auto;
  font-size: 11px;
  font-family: Open Sans;
  font-style: italic;
  color: #131f3c;
  margin-bottom: 15px;
  position: relative;
  clear: both;
  text-transform: lowercase;
}
#sticky-news-nav .select-box .list-checkbox ul li:last-child {
  margin-bottom: 0;
}
#sticky-news-nav .select-box .list-checkbox ul li:hover {
  cursor: pointer;
}
#sticky-news-nav .select-box .list-checkbox ul li.active {
  font-weight: bold;
}
#sticky-news-nav .select-box .list-checkbox ul li label {
  width: 100%;
  display: block;
}
#sticky-news-nav .select-box .list-checkbox ul li label:hover {
  cursor: pointer;
}
#sticky-news-nav .select-box .list-checkbox ul li input[type="checkbox"] {
  display: none;
}
#sticky-news-nav .select-box .list-checkbox ul li input[type="checkbox"] + label {
  color: #131f3c;
}
#sticky-news-nav .select-box .list-checkbox ul li input[type="checkbox"] + label span {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin: -2px 14px 0 0;
  vertical-align: middle;
  border: 1px solid #131f3c;
  cursor: pointer;
}
#sticky-news-nav .select-box .list-checkbox ul li input[type="checkbox"]:checked + label span {
  background: url("../img/checkbox-checked.png") center center no-repeat;
}
#first-paragraph {
  font-size: 14px;
  line-height: 28px;
  color: #131f3c;
  z-index: 1;
}
#first-paragraph .buy-ticket {
  border: none;
  padding-top: 9px;
  background-color: #1c92d1;
  text-align: center;
  color: #fff;
  font-weight: bolder;
  font-size: 17px;
}
#first-paragraph .buy-ticket a {
  text-decoration: none;
  color: inherit !important;
}
#first-paragraph .buy-ticket a:hover {
  color: inherit !important;
}
#first-paragraph .youtube-container {
  margin-bottom: 60px;
}
#first-paragraph .youtube-container .news-film {
  padding: 10px 0;
  width: 600px;
  height: 300px;
}
#first-paragraph .youtube-container .news-film .film {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
#emanacje {
  margin-top: 70px;
}
#emanacje > #loading {
  display: none !important;
}
#emanacje #loading {
  padding: 80px 0;
}
#emanacje #loading img {
  display: block;
  margin: 0 auto;
}
#emanacje .load-more {
  position: absolute;
  bottom: -100px;
  left: 50%;
  transform: translateX(-50%);
  color: #1d2944;
  font-weight: 700;
  font-size: 14px;
  text-transform: lowercase;
  text-align: center;
  text-decoration: none;
}
#emanacje .current-emanations-link {
  margin: 0 auto;
  width: 90%;
  margin-bottom: 30px;
}
#emanacje .current-emanations-link a {
  font-weight: 700;
  color: #2aace8 !important;
}
.emanations-list {
  margin-top: 70px;
  height: 100vw;
}
.emanation-item {
  padding: 14px;
  font-family: Open Sans;
  border: 1px solid #d0d0d0;
  display: flex;
  flex-direction: column;
  height: fit-content;
  white-space: normal;
}
.emanation-item .category-box {
  text-align: center;
  font-size: 12px;
  color: #1d2944;
  font-weight: 600;
  text-transform: lowercase;
}
.emanation-item .category-box .category-gray {
  background-color: #e9e9e9;
  padding: 5px;
}
.emanation-item .category-box .category-yellow {
  background-color: #fef263;
  padding: 5px;
}
.emanation-item .category-box .category-red {
  background-color: #ff957e;
  padding: 5px;
}
.emanation-item .category-box .category-black {
  background-color: #000;
  padding: 5px;
  color: #fff;
}
.emanation-item .category-box .category-dark-with-red {
  background-color: #1d2944;
  padding: 5px;
  color: #f05a39;
}
.emanation-item .category-box .category-dark-with-yellow {
  background-color: #1d2944;
  padding: 5px;
  color: #fef263;
}
.emanation-item .category-box .category-blue {
  background-color: #2aace8;
  padding: 5px;
  color: #1d2944;
}
.emanation-item .category-box .category-light-blue {
  background-color: #c4e0ed;
  padding: 5px;
  color: #1d2944;
}
.emanation-item .short-content .date {
  color: #1d2944;
  font-size: 12px;
  margin-top: 30px;
  font-weight: 600;
}
.emanation-item .short-content a.tag {
  color: #2aace8;
  font-size: 14px;
  font-style: italic;
  text-decoration: none;
  text-transform: lowercase;
  pointer-events: none;
}
.emanation-item .short-content .title {
  margin-top: 13px;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}
@media (max-width: 1110px) {
  .emanation-item .short-content .title {
    font-size: 18px;
  }
}
.emanation-item .short-content .title a {
  color: #1d2944;
}
.emanation-item .short-content .short-desc p {
  color: #1d2944;
  font-size: 12px;
  font-weight: 600;
  line-height: 28px;
}
.emanation-item .short-content .bottom-box {
  display: flex;
  justify-content: space-between;
  height: 20px;
  margin-top: 20px;
}
.emanation-item .short-content .bottom-box #share-box {
  cursor: pointer;
  position: relative;
  display: flex;
}
.emanation-item .short-content .bottom-box #share-box #webSocialList {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 2px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}
.emanation-item .short-content .bottom-box #share-box #webSocialList .social-icon {
  margin-left: 10px;
  transition: all 1s ease;
  display: none;
}
.emanation-item .short-content .bottom-box #share-box #webSocialList .social-icon .fa {
  font-size: 15px;
  color: #2aace8;
}
.emanation-item .short-content .bottom-box #share-box .visible-social-icons {
  left: 20px !important;
}
.emanation-item .short-content .bottom-box #share-box .visible-social-icons .social-icon {
  display: block !important;
}
.emanation-item .short-content .bottom-box #share-box .share-button {
  transition: all 1s ease;
}
.emanation-item .short-content .bottom-box #share-box .close {
  width: 12px;
  height: 14px;
  position: absolute;
  display: none;
  margin-bottom: 5px;
  transition: all 1s ease;
}
.emanation-item .short-content .bottom-box .read-more {
  color: #137eb0;
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
  cursor: pointer;
}
#sticky-news-nav.emanations-nav {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  padding: 0;
}
#sticky-news-nav.emanations-nav #show-all {
  display: none;
  white-space: nowrap;
  align-items: center;
  position: relative;
  font-weight: 800;
  color: #1d2944;
}
#sticky-news-nav.emanations-nav #show-all.show-all-icon::before,
#sticky-news-nav.emanations-nav #show-all.show-all-icon:after {
  position: absolute;
  top: calc(50% - 2px);
  right: -12px;
  content: '';
  height: 8px;
  width: 2px;
  background-color: #1d2944;
  border-radius: 2px;
}
#sticky-news-nav.emanations-nav #show-all.show-all-icon:before {
  transform: rotate(45deg);
}
#sticky-news-nav.emanations-nav #show-all.show-all-icon:after {
  transform: rotate(-45deg);
}
#sticky-news-nav.emanations-nav .select-box {
  width: 15%;
}
#sticky-news-nav.emanations-nav .select-box .emanations-filtration-list {
  width: 100vw;
  position: absolute;
  left: 0;
  margin-left: -1px;
}
#sticky-news-nav.emanations-nav .filter {
  padding-bottom: 15px;
  margin-top: 15px;
  background-color: transparent;
  border-color: transparent;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 6px;
  padding-left: 0;
  cursor: pointer;
}
#sticky-news-nav.emanations-nav .filter .arrow {
  margin-left: 5px;
}
#sticky-news-nav.emanations-nav #emanation-filter-list {
  width: 85%;
  display: flex;
  align-items: center;
}
#sticky-news-nav.emanations-nav #emanation-filter-list .selected-filter-item {
  margin-right: 35px;
  font-size: 14px;
  position: relative;
  color: #1d2944;
}
#sticky-news-nav.emanations-nav #emanation-filter-list .selected-filter-item span {
  font-size: 11px;
  position: absolute;
  right: -10px;
  top: 2px;
  font-weight: bold;
  cursor: pointer;
}
#sticky-news-nav.emanations-nav .list {
  background-color: #fff;
  padding-top: 0;
  display: flex;
}
@media (max-width: 450px) {
  #sticky-news-nav.emanations-nav .list {
    flex-direction: column;
  }
}
#sticky-news-nav.emanations-nav .list span {
  display: block;
  padding-left: 2px;
  padding-bottom: 10px;
  text-transform: uppercase;
  font-weight: 800;
}
#sticky-news-nav.emanations-nav .list span:not(:first-child) {
  padding-top: 10px;
}
#sticky-news-nav.emanations-nav .list ul li {
  text-transform: lowercase;
  padding-left: 2px;
  font-size: 12px;
}
#sticky-news-nav.emanations-nav .list ul li.active {
  font-weight: normal;
  text-decoration: underline;
}
#sticky-news-nav.emanations-nav .list ul li.active::before {
  display: none;
}
#sticky-news-nav.emanations-nav .list ul li a {
  color: #131f3c;
  text-decoration: none;
}
#sticky-news-nav.emanations-nav .list ul li a:hover {
  font-weight: normal;
  text-decoration: underline;
}
#sticky-news-nav.emanations-nav .list .list-box {
  margin-right: 10px;
}
@media (max-width: 450px) {
  #sticky-news-nav.emanations-nav .list .list-box {
    margin-bottom: 30px;
  }
}
#sticky-news-nav.emanations-nav .list .archives {
  margin-left: 30px;
}
@media (max-width: 450px) {
  #sticky-news-nav.emanations-nav .list .archives {
    margin-left: 0;
  }
}
.emanations-grid {
  width: 90%;
  margin: 0 auto 150px;
}
.grid-item {
  width: 25%;
}
@media (min-width: 1600px) {
  .grid-item {
    width: 20%;
  }
}
@media (max-width: 1000px) {
  .grid-item {
    width: 33%;
  }
}
@media (max-width: 700px) {
  .grid-item {
    width: 50%;
  }
}
@media (max-width: 500px) {
  .grid-item {
    width: 80%;
    margin-left: 10%;
  }
}
@media (max-width: 400px) {
  .grid-item {
    width: 100%;
    margin-left: 0;
  }
}
.visible {
  display: flex !important;
}
.emanation-popup {
  background-color: rgba(0,0,0,0.88) !important;
}
.emanation-popup .container .content .controls .close {
  background-color: rgba(0,0,0,0.78) !important;
}
#szablon.emanation .date {
  margin-top: 40px;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 700;
}
#szablon.emanation h1 {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}
#first-paragraph {
  padding: 0;
}
.button-div {
  background-color: transparent;
  border-color: transparent;
}
#prezentacje {
  transition: 0.5s;
  background-color: #fff;
  margin-top: 70px;
}
#prezentacje > #loading {
  display: none !important;
}
#prezentacje #loading {
  padding: 80px 0;
}
#prezentacje .noDisplay {
  display: none;
}
#prezentacje .load-more {
  position: absolute;
  bottom: -100px;
  left: 50%;
  transform: translateX(-50%);
  color: #1d2944;
  font-weight: 700;
  font-size: 14px;
  text-transform: lowercase;
  text-align: center;
  text-decoration: none;
}
.presentations-list {
  margin-top: 70px;
  height: 100vw;
}
.presentation-item {
  padding: 14px;
  transition: 0.5s;
  font-family: Open Sans;
  border: 1px solid #d0d0d0;
  display: flex;
  flex-direction: column;
  height: fit-content;
  white-space: normal;
}
.presentation-item:hover .artist-list-photo {
  overflow: hidden;
}
.presentation-item:hover .artist-list-photo .list-photo {
  transform: scale(1.1);
}
.presentation-item:hover .name-container .name {
  text-decoration: none;
  color: #0b7fc7;
}
.presentation-item .artist-list-photo {
  text-align: center !important;
  width: 100%;
  overflow: hidden;
}
.presentation-item .artist-list-photo .list-photo {
  height: 200px;
  width: fit-content;
  object-fit: cover;
  transition: 0.5s ease;
}
.presentation-item .category-box {
  text-align: center;
  font-size: 12px;
  color: #1d2944;
  font-weight: 600;
  text-transform: lowercase;
}
.presentation-item .category-creamy-yellow {
  background-color: #c1904d !important;
  padding: 5px;
  color: #fff !important;
}
.presentation-item .category-light-blue {
  background-color: #9ce5e8 !important;
  padding: 5px;
  color: #1d2944 !important;
}
.presentation-item .category-light-yellow {
  background-color: #ffda77 !important;
  padding: 5px;
  color: #1d2944 !important;
}
.presentation-item .category-sea-blue {
  background-color: #3c479b !important;
  padding: 5px;
  color: #fff !important;
}
.presentation-item .short-content a.tag {
  font-size: 14px;
  text-transform: lowercase;
  pointer-events: none;
  font-weight: 500;
  color: #000;
  font-style: normal;
}
.presentation-item .short-content .name-container {
  margin: 25px 0 15px 0;
}
.presentation-item .short-content .name-container .name {
  word-spacing: 100vw;
  word-break: break-word;
  font-weight: 700;
  font-size: 25px;
}
.presentation-item .short-content .short-desc p {
  color: #1d2944;
  font-size: 12px;
  font-weight: 600;
  line-height: 28px;
  text-underline: none;
}
.presentation-item .short-content .bottom-box {
  text-align: end;
}
.presentation-item .short-content .bottom-box .read-more {
  color: #137eb0;
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
  cursor: pointer;
}
#sticky-news-nav.presentations-nav {
  z-index: 100;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-left: -1px;
}
#sticky-news-nav.presentations-nav #show-all {
  display: none;
  white-space: nowrap;
  align-items: center;
  position: relative;
  font-weight: 800;
  color: #1d2944;
  margin-right: 20px;
}
#sticky-news-nav.presentations-nav #show-all.show-all-icon::before,
#sticky-news-nav.presentations-nav #show-all.show-all-icon:after {
  position: absolute;
  top: calc(50% -2px);
  right: -12px;
  content: '';
  height: 8px;
  width: 2px;
  background-color: #1d2944;
  border-radius: 2px;
}
#sticky-news-nav.presentations-nav #show-all.show-all-icon:before {
  transform: rotate(45deg);
}
#sticky-news-nav.presentations-nav #show-all.show-all-icon:after {
  transform: rotate(-45deg);
}
#sticky-news-nav.presentations-nav .select-box {
  width: 15%;
}
#sticky-news-nav.presentations-nav .select-box .presentations-filtration-list {
  width: 100vw;
  position: absolute;
  left: 0;
}
#sticky-news-nav.presentations-nav .filter {
  padding-bottom: 15px;
  margin-top: 15px;
  background-color: transparent;
  border-color: transparent;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 6px;
  cursor: pointer;
}
#sticky-news-nav.presentations-nav .filter .arrow {
  margin-left: 5px;
}
#sticky-news-nav.presentations-nav #presentation-filter-list {
  width: 85%;
  display: flex;
  align-items: center;
}
#sticky-news-nav.presentations-nav #presentation-filter-list .selected-filter-item {
  margin-right: 35px;
  font-size: 14px;
  position: relative;
  color: #1d2944;
}
#sticky-news-nav.presentations-nav #presentation-filter-list .selected-filter-item span {
  font-size: 11px;
  position: absolute;
  right: -10px;
  top: 2px;
  font-weight: bold;
  cursor: pointer;
}
#sticky-news-nav.presentations-nav .list {
  background-color: #fff;
  padding-top: 0;
  display: flex;
}
#sticky-news-nav.presentations-nav .list span {
  display: block;
  padding-left: 2px;
  padding-bottom: 10px;
  text-transform: uppercase;
  font-weight: 800;
}
#sticky-news-nav.presentations-nav .list ul li {
  text-transform: lowercase;
  padding-left: 2px;
  font-size: 12px;
}
#sticky-news-nav.presentations-nav .list ul li.active {
  font-weight: normal;
  text-decoration: underline;
}
#sticky-news-nav.presentations-nav .list ul li.active::before {
  display: none;
}
#sticky-news-nav.presentations-nav .list ul li a {
  color: #131f3c;
  text-decoration: none;
}
#sticky-news-nav.presentations-nav .list ul li a:hover {
  font-weight: normal;
  text-decoration: underline;
}
#sticky-news-nav.presentations-nav .list .list-box {
  margin-right: 10px;
}
.presentations-grid {
  width: 90%;
  margin: 0 auto 150px;
}
.presentations-grid .tags {
  display: flex;
  background-color: #ff0;
  justify-content: center;
  align-items: center;
  height: 30px;
}
.grid-item {
  width: 25%;
}
@media (min-width: 1600px) {
  .grid-item {
    width: 20%;
  }
}
@media (max-width: 1000px) {
  .grid-item {
    width: 33%;
  }
}
@media (max-width: 700px) {
  .grid-item {
    width: 50%;
  }
}
@media (max-width: 500px) {
  .grid-item {
    width: 80%;
    margin-left: 10%;
  }
}
@media (max-width: 400px) {
  .grid-item {
    width: 100%;
    margin-left: 0;
  }
}
.visible {
  display: flex !important;
}
.presentation-popup {
  position: fixed !important;
  background-color: rgba(0,0,0,0.88) !important;
  visibility: visible !important;
}
.presentation-popup .container {
  padding: 0 !important;
  margin: 0 !important;
  width: 100vw;
  height: 100vh;
}
.presentation-popup .content {
  margin: 0 !important;
}
.presentation-popup .container .content .controls .close {
  background-color: rgba(0,0,0,0.78) !important;
}
#szablon.presentation .date {
  margin-top: 40px;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 700;
}
#szablon.presentation h1 {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}
#first-paragraph {
  padding: 0;
}
.container-modal {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.container-modal .artist-photo {
  background-color: #000;
  z-index: 600;
  position: absolute;
  height: 100%;
  width: 50vw;
  text-align: center;
  transition: 0.5s;
}
@media screen and (max-width: 992px) {
  .container-modal .artist-photo {
    display: none;
  }
}
.container-modal .artist-photo .photos {
  display: none;
  transition: 0.5s;
  background-color: #000;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  margin: 0 auto;
}
.container-modal .artist-photo .photos.visible-photo {
  display: block;
  text-align: center;
  -webkit-transition: opacity 2s ease-in;
  -moz-transition: opacity 2s ease-in;
  -o-transition: opacity 2s ease-in;
  -ms-transition: opacity 2s ease-in;
  transition: opacity 2s ease-in;
}
.container-modal .gallery-btns {
  bottom: 15px;
  z-index: 700;
  display: flex;
  margin-left: 30px;
  position: absolute;
}
.container-modal .gallery-btns button {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #fff !important;
  box-shadow: none !important;
}
.container-modal .gallery-btns button.gallery-btn-next {
  border-color: #fff;
  background-color: transparent;
  padding: 12px 15px;
}
.container-modal .gallery-btns button.gallery-btn-prev {
  border-color: #fff;
  background-color: transparent;
  padding: 12px 15px;
}
.container-modal .gallery-btns button.gallery-btn-close {
  cursor: default;
  transition: 0.5s;
  border-color: #fff;
  background-color: transparent;
  padding: 12px 15px;
  opacity: 0;
}
.container-modal .content-presentations {
  margin-left: 50vw;
  overflow: auto;
  width: 50%;
  font-family: "Open Sans";
  padding: 65px 45px 0;
}
@media screen and (max-width: 992px) {
  .container-modal .content-presentations {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
.container-modal .content-presentations .main-presentations-header {
  display: none;
  z-index: 500;
  position: absolute;
  height: 70px;
  background-color: #fff;
  margin-left: -25px;
  top: 0;
  width: 45%;
}
@media screen and (max-width: 1370px) {
  .container-modal .content-presentations .main-presentations-header {
    width: 43%;
  }
}
@media screen and (max-width: 992px) {
  .container-modal .content-presentations .main-presentations-header {
    z-index: 550;
    position: absolute;
    height: 60px;
    left: 20px;
    background-color: #fff;
    top: 60px;
    width: 101vw;
  }
}
.container-modal .content-presentations .main-presentations-header .name-float {
  border: 1px solid #1d2944;
  position: absolute;
  left: 0;
  top: 11px;
  width: 100%;
}
@media screen and (max-width: 992px) {
  .container-modal .content-presentations .main-presentations-header .name-float {
    display: inline-block;
    position: relative;
    top: 0;
    width: calc(100% - 30px);
  }
}
.container-modal .content-presentations .main-presentations-header .name-float .name-nav {
  background-color: #fff;
  font-weight: 700;
  color: #1d2944;
  padding: 12px 0 12px 10px;
}
@media screen and (max-width: 992px) {
  .container-modal .content-presentations .main-presentations-header .name-float .name-nav {
    font-size: 12px;
    padding: 12px 15px;
  }
}
.container-modal .content-presentations .name-presentations {
  color: #1d2944;
  font-weight: 700;
  font-size: 50px;
  padding: 15px 0 0 4px;
}
@media screen and (max-width: 992px) {
  .container-modal .content-presentations .name-presentations {
    font-size: 50px;
    font-weight: 900;
    padding: 20px 0 0 25px;
  }
}
@media screen and (max-width: 605px) {
  .container-modal .content-presentations .name-presentations {
    font-size: 38px;
    padding: 20px 0 0 25px;
  }
}
.container-modal .content-presentations .surname-presentations {
  color: #1d2944;
  font-weight: 700;
  font-size: 50px;
  line-height: 1;
  padding: 0 0 8px 4px;
}
@media screen and (max-width: 992px) {
  .container-modal .content-presentations .surname-presentations {
    font-size: 50px;
    font-weight: 900;
    padding: 0 30px 0 25px;
  }
}
@media screen and (max-width: 605px) {
  .container-modal .content-presentations .surname-presentations {
    font-size: 38px;
    font-weight: 900;
    padding: 0 30px 0 25px;
  }
}
.container-modal .content-presentations .gallery-media-container {
  display: none;
}
@media screen and (max-width: 992px) {
  .container-modal .content-presentations .gallery-media-container {
    display: block;
  }
}
.container-modal .content-presentations .gallery-media-container #links {
  display: none;
}
.container-modal .content-presentations .gallery-media-container .blueimp-gallery {
  background-color: #000;
}
.container-modal .content-presentations .gallery-media-container .blueimp-gallery .slides .slide .slide-content {
  height: 100%;
}
.container-modal .content-presentations .gallery-media-container #blueimp-carousel {
  z-index: 100;
  height: 400px;
  width: 100%;
}
.container-modal .content-presentations .gallery-media-container .blueimp-gallery-carousel {
  box-shadow: none;
}
.container-modal .content-presentations .gallery-media {
  z-index: 200;
  display: none;
  width: 100vw;
  height: 430px;
  text-align: center;
  position: relative;
}
@media screen and (max-width: 992px) {
  .container-modal .content-presentations .gallery-media {
    display: block;
  }
}
.container-modal .content-presentations .category {
  margin-bottom: 20px;
  color: #2aace8;
  font-size: 24px;
  padding: 0 0 30px 6px;
}
@media screen and (max-width: 992px) {
  .container-modal .content-presentations .category {
    font-weight: 600;
    padding: 5px 0 20px 25px;
  }
}
.container-modal .content-presentations .category .text-category {
  font-size: 24px;
}
.container-modal .content-presentations .long-description {
  font-weight: 600;
  font-size: 13px;
  line-height: 30px;
  margin-bottom: 30px;
}
@media screen and (max-width: 992px) {
  .container-modal .content-presentations .long-description {
    z-index: 400;
    background-color: #fff;
    margin-bottom: 0;
    line-height: 2;
    font-size: 12px;
    padding: 50px 30px 40px 25px;
  }
}
.container-modal .content-presentations .film-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  margin-bottom: 100px;
}
@media screen and (max-width: 992px) {
  .container-modal .content-presentations .film-container {
    margin-bottom: 140px;
    margin-left: 25px;
    margin-right: 25px;
  }
}
.container-modal .content-presentations .film-container .film {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.widen-Gallery {
  text-align: center;
  transition: 1s;
  width: 100vw !important;
}
.visible-Btn {
  cursor: pointer !important;
  transition: 0.5s;
  z-index: 1000;
  opacity: 1 !important;
}
.btns-space {
  z-index: 1000;
}
.widened-Gallery {
  transition: 1s;
}
.nav-list {
  height: 100%;
}
.submenu ul {
  display: none;
  list-style: none;
}
.submenu .expnd {
  color: #0a73bb;
}
.submenu .expnd:after {
  content: "+";
}
.submenu.hidn:after {
  content: "-";
}
.btn-div {
  background-color: transparent;
  border-color: transparent;
}
.no-pointer {
  pointer-events: none;
}
.no-pointer #document svg path {
  fill: #0a73bb;
}
.btn-scrl-color {
  fill: #1d2944;
}
#presentations-popup {
  position: absolute;
  visibility: hidden;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,9,0.88);
  z-index: 999999;
}
#presentations-popup .main-news-img {
  background-color: #fff;
  height: 550px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
@media screen and (max-width: 1199px) {
  #presentations-popup .main-news-img {
    height: 350px;
  }
}
@media screen and (max-width: 750px) {
  #presentations-popup .main-news-img {
    height: 250px;
  }
}
#presentations-popup > .container {
  position: relative;
}
#presentations-popup > .container .content {
  margin-top: 5%;
  margin-bottom: 10%;
  background-color: #fff;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 992px) {
  #presentations-popup > .container .content {
    padding: 80px 0 50px;
  }
}
@media screen and (max-width: 700px) {
  #presentations-popup > .container .content {
    padding: 80px 0 30px;
  }
}
@media screen and (max-width: 470px) {
  #presentations-popup > .container .content {
    padding: 80px 0 15px;
  }
}
#presentations-popup > .container .content.newsletter-popup {
  padding: 0 100px;
}
@media screen and (max-width: 992px) {
  #presentations-popup > .container .content.newsletter-popup {
    padding: 0 50px;
  }
}
@media screen and (max-width: 700px) {
  #presentations-popup > .container .content.newsletter-popup {
    padding: 0 30px;
  }
}
@media screen and (max-width: 470px) {
  #presentations-popup > .container .content.newsletter-popup {
    padding: 0 15px;
  }
}
#presentations-popup > .container .content h1 {
  font-size: 24px;
  color: #131f3c;
  margin-top: 55px;
  margin-bottom: 25px;
  font-weight: bold;
}
#presentations-popup > .container .content .informations {
  margin-bottom: 80px;
}
#presentations-popup > .container .content .container {
  width: 100%;
}
#presentations-popup > .container .content .controls .btnss {
  z-index: 500;
  position: absolute;
  top: 11px;
  right: 35px;
  display: flex;
}
@media screen and (max-width: 992px) {
  #presentations-popup > .container .content .controls .btnss {
    position: absolute;
    right: 0;
    padding: 0 10px;
    height: 60px;
    background-color: #fff;
    text-align: center;
    width: 100%;
  }
}
#presentations-popup > .container .content .controls .btnss .btnss-cont {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 992px) {
  #presentations-popup > .container .content .controls .btnss .btnss-cont {
    height: 50px;
    display: flex;
    width: 100%;
  }
}
#presentations-popup > .container .content .controls .btnss .btnss-cont .hide-portfolio {
  display: block;
  margin-left: 5px;
}
#presentations-popup > .container .content .controls .btnss .btnss-cont .nav-btns {
  color: #1d2944;
  background-color: #fff;
  padding: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 992px) {
  #presentations-popup > .container .content .controls .btnss .btnss-cont .nav-btns {
    padding: 15px 0 0 15px;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
  }
}
#presentations-popup > .container .content .controls .btnss .btnss-cont .nextious {
  width: 50%;
  border: 1px solid #1d2944;
  border-left: none;
}
#presentations-popup > .container .content .controls .btnss .btnss-cont .previous {
  border: 1px solid #1d2944;
  width: 50%;
}
#presentations-popup > .container .content .controls .btnss .btnss-cont .close {
  opacity: 1;
  width: 50px;
  height: 46px;
  background-color: rgba(8,54,91,0.93);
}
@media screen and (max-width: 992px) {
  #presentations-popup > .container .content .controls .btnss .btnss-cont .close {
    height: 50px;
    width: 60px;
  }
}
#presentations-popup > .container .content .controls .btnss .btnss-cont .close img {
  height: 100%;
  padding: 15px;
  background-color: #1d2944;
}
@media screen and (max-width: 992px) {
  #presentations-popup > .container .content .controls .btnss .btnss-cont .close img {
    height: 100%;
    width: 100%;
    padding: 17px;
  }
}
#presentations-popup > .container .content .controls .btnss .btnss-cont .close-wcag {
  display: none;
}
.contact_wrapper,
#availability-wrapper {
  max-width: 1140px;
  height: auto;
  margin: auto;
  clear: both;
}
@media screen and (max-width: 1140px) {
  .contact_wrapper,
  #availability-wrapper {
    padding: 0 20px;
  }
}
#availability-wrapper {
  font-family: 'Open Sans', sans-serif;
  line-height: 28px;
  font-size: 14px;
}
#availability-wrapper h2 {
  font-weight: 700;
  font-size: 18px;
}
#availability-wrapper a {
  text-decoration: none;
  color: #131f3c;
}
#availability-wrapper a:hover {
  text-decoration: underline;
}
#contact,
#availability {
  width: 100%;
  height: auto;
  margin: 100px 0 0 0;
  clear: both;
}
#contact .company_place,
#availability .company_place {
  width: 100%;
  height: auto;
  margin-bottom: 170px;
  clear: both;
}
#contact .company_place .first,
#availability .company_place .first {
  width: 100%;
  height: auto;
  margin-bottom: 170px;
  clear: both;
}
#contact .company_place .first .full_map,
#availability .company_place .first .full_map {
  width: 100%;
  height: 500px;
  margin-bottom: 25px;
  clear: both;
}
#contact .company_place .first .heading,
#availability .company_place .first .heading {
  width: 100%;
  height: auto;
  font-size: 18px;
  font-family: Open Sans;
  font-weight: bold;
  color: #131f3c;
  margin-bottom: 50px;
  clear: both;
}
@media screen and (max-width: 1140px) {
  #contact .company_place .first .heading,
  #availability .company_place .first .heading {
    text-align: center;
  }
}
#contact .company_place .first .informations_box,
#availability .company_place .first .informations_box {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: flex-start;
}
#contact .company_place .first .informations_box .item,
#availability .company_place .first .informations_box .item {
  width: 25%;
  height: auto;
  margin-top: 100px;
  font-size: 14px;
  font-family: Open Sans;
  color: #131f3c;
  line-height: 30px;
}
#contact .company_place .first .informations_box .item:nth-child(-n+4),
#availability .company_place .first .informations_box .item:nth-child(-n+4) {
  margin-top: 0;
}
@media screen and (max-width: 1140px) {
  #contact .company_place .first .informations_box .item,
  #availability .company_place .first .informations_box .item {
    text-align: center;
  }
}
@media screen and (max-width: 940px) {
  #contact .company_place .first .informations_box .item,
  #availability .company_place .first .informations_box .item {
    width: 50%;
  }
  #contact .company_place .first .informations_box .item:nth-child(-n+2),
  #availability .company_place .first .informations_box .item:nth-child(-n+2) {
    margin-top: 0;
  }
  #contact .company_place .first .informations_box .item:not(:nth-child(-n+2)),
  #availability .company_place .first .informations_box .item:not(:nth-child(-n+2)) {
    margin-top: 100px;
  }
}
@media screen and (max-width: 500px) {
  #contact .company_place .first .informations_box .item,
  #availability .company_place .first .informations_box .item {
    width: 100%;
  }
  #contact .company_place .first .informations_box .item:first-child,
  #availability .company_place .first .informations_box .item:first-child {
    margin-top: 0;
  }
  #contact .company_place .first .informations_box .item:not(:first-child),
  #availability .company_place .first .informations_box .item:not(:first-child) {
    margin-top: 50px;
  }
}
#contact .company_place .first .informations_box .item .person_fullname,
#availability .company_place .first .informations_box .item .person_fullname {
  width: 100%;
  height: auto;
  font-family: Jaapokki;
  font-weight: bold;
  color: #131f3c;
  text-transform: uppercase;
  margin-bottom: 8px;
  clear: both;
}
#contact .company_place .first .informations_box .item .person_role,
#availability .company_place .first .informations_box .item .person_role {
  width: 100%;
  height: auto;
  font-size: 12px;
  line-height: 20px;
  clear: both;
}
#contact .company_place .second,
#availability .company_place .second {
  width: 100%;
  height: auto;
  clear: both;
}
#contact .company_place .second .left_side,
#availability .company_place .second .left_side {
  height: auto;
  float: left;
}
@media screen and (max-width: 1140px) {
  #contact .company_place .second .left_side,
  #availability .company_place .second .left_side {
    width: 100%;
    float: none;
    clear: both;
  }
}
#contact .company_place .second .left_side .heading,
#availability .company_place .second .left_side .heading {
  width: 100%;
  height: auto;
  font-size: 18px;
  font-family: Open Sans;
  font-weight: bold;
  color: #131f3c;
  margin-bottom: 50px;
  clear: both;
}
@media screen and (max-width: 1140px) {
  #contact .company_place .second .left_side .heading,
  #availability .company_place .second .left_side .heading {
    text-align: center;
  }
}
#contact .company_place .second .left_side .informations_box,
#availability .company_place .second .left_side .informations_box {
  width: 100%;
  height: auto;
  clear: both;
  display: grid;
  grid-template-columns: 1fr;
  gap: 50px;
}
@media screen and (min-width: 501px) {
  #contact .company_place .second .left_side .informations_box,
  #availability .company_place .second .left_side .informations_box {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1141px) {
  #contact .company_place .second .left_side .informations_box,
  #availability .company_place .second .left_side .informations_box {
    grid-template-columns: repeat(4, 1fr);
    gap: 75px;
  }
}
#contact .company_place .second .left_side .informations_box .item,
#availability .company_place .second .left_side .informations_box .item {
  width: 100%;
  height: auto;
  font-size: 14px;
  font-family: Open Sans;
  color: #131f3c;
  line-height: 30px;
}
@media screen and (min-width: 500px) {
  #contact .company_place .second .left_side .informations_box .item:nth-child(-n+2),
  #availability .company_place .second .left_side .informations_box .item:nth-child(-n+2) {
    margin-top: 0;
  }
}
@media screen and (max-width: 1140px) {
  #contact .company_place .second .left_side .informations_box .item,
  #availability .company_place .second .left_side .informations_box .item {
    text-align: center;
  }
}
@media screen and (max-width: 500px) {
  #contact .company_place .second .left_side .informations_box .item,
  #availability .company_place .second .left_side .informations_box .item {
    width: 100%;
  }
}
#contact .company_place .second .left_side .informations_box .item .person_fullname,
#availability .company_place .second .left_side .informations_box .item .person_fullname {
  width: 100%;
  height: auto;
  font-family: Jaapokki;
  font-weight: bold;
  color: #131f3c;
  text-transform: uppercase;
  margin-bottom: 8px;
  clear: both;
}
#contact .company_place .second .left_side .informations_box .item .person_role,
#availability .company_place .second .left_side .informations_box .item .person_role {
  width: 100%;
  height: auto;
  font-size: 12px;
  line-height: 20px;
  clear: both;
}
#contact .company_place .second .small_map,
#availability .company_place .second .small_map {
  width: 570px;
  height: 500px;
  float: right;
}
@media screen and (max-width: 1140px) {
  #contact .company_place .second .small_map,
  #availability .company_place .second .small_map {
    width: 100%;
    float: none;
    margin-bottom: 40px;
    clear: both;
  }
}
#mecenat .img {
  margin-bottom: 60px;
}
#mecenat .img img {
  display: block;
  margin: 0 auto;
}
#mecenat h1 {
  font-size: 18px;
  font-weight: 700;
  margin: 140px 0 0 0;
}
#mecenat p {
  margin: 70px 0 50px 0;
  font-size: 1.02em;
  line-height: 200%;
}
#mecenat .btn {
  background-color: #131f3c;
  color: #fff;
  text-transform: uppercase;
  text-style: "Jaapokki Regular";
  border-radius: 0;
  text-align: center;
  padding: 10px 40px 10px 40px;
}
#mecenat .btn:hover,
#mecenat .btn:focus,
#mecenat .btn:visited {
  color: #fff;
}
#mecenat .galeria {
  margin: 100px 0 120px 0;
}
#mecenat .galeria .galeria-opis {
  display: none;
}
.programy .program {
  padding-bottom: 1px;
  padding-top: 80px;
}
.programy .program .module {
  margin-top: 0 !important;
  background-color: transparent !important;
}
.programy .program:nth-child(2n ) {
  background-color: #eee;
}
#szablon .module {
  margin-bottom: 80px;
  position: relative;
  background-color: #fff;
}
@media (max-width: 768px) {
  #szablon .module {
    margin-bottom: 60px;
  }
}
#szablon .module:first-child {
  margin-top: 135px;
}
#szablon .module p {
  font-size: 14px;
  line-height: 28px;
  color: #131f3c;
}
#szablon .module p:last-child {
  margin-bottom: 0;
}
#szablon .module h1,
#szablon .module h2,
#szablon .module h3,
#szablon .module h4 {
  font-size: 18px;
  font-weight: 700;
  padding: 0;
  margin-bottom: 60px;
  color: #131f3c;
}
#szablon .module .bg-tekst {
  font-size: 176px;
  opacity: 0.08;
  color: #131f3c;
  position: absolute;
  font-family: 'Jaapokki';
}
@media (max-width: 1023px) {
  #szablon .module .bg-tekst {
    display: none;
  }
}
#szablon .module .bg-tekst.left {
  top: 0;
  left: 0;
}
#szablon .module .bg-tekst.right {
  top: 0;
  right: 0;
}
#szablon .module .bg-tekst.drop-left {
  bottom: 0;
  left: 0;
}
#szablon .module .bg-tekst.drop-right {
  bottom: 0;
  right: 0;
}
#szablon .module.obudowa::after {
  content: "";
  display: block;
  width: calc(100% + 130px);
  height: calc(100% + 65px);
  left: -65px;
  top: 0;
  background-color: #fff;
  position: absolute;
  z-index: -1;
}
#szablon .plik-do-pobrania {
  margin: 60px 0;
  z-index: 1;
}
#szablon .plik-do-pobrania a {
  font-size: 14px;
  color: #131f3c;
  font-weight: bold;
  text-decoration: none;
}
#szablon .plik-do-pobrania a:hover {
  color: #131f3c;
}
#search-zone {
  position: absolute;
  z-index: 9999999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: rgba(0,92,156,0.88);
  padding-bottom: 150px;
  display: none;
}
#search-zone #search-form {
  margin-top: 10vh;
  padding: 0 15px !important;
}
#search-zone #search-form h1 {
  font-size: 87px;
  color: #fff;
  margin-bottom: 55px;
  font-weight: 700;
  font-family: 'Jaapokki';
}
@media (max-width: 650px) {
  #search-zone #search-form h1 {
    font-size: 50px;
  }
}
#search-zone #search-form input {
  width: calc(100% - 175px);
  display: inline;
  border-radius: 0;
  border: 1px solid #fff;
  outline: none;
  padding: 15px 25px;
  line-height: 45px;
  height: 45px;
  color: #fff;
  background-color: transparent;
}
@media (max-width: 650px) {
  #search-zone #search-form input {
    width: 100%;
  }
}
#search-zone #search-form button {
  width: 170px;
  line-height: 43px;
  border: 1px solid #fff;
  padding: 0;
  margin: 0;
  border-radius: 0;
  margin-top: -4px;
  margin-left: -4px;
  text-transform: uppercase;
  color: #005c9c;
  background-color: #fff;
  font-size: 12px;
  font-weight: bold;
}
@media (max-width: 650px) {
  #search-zone #search-form button {
    width: 100%;
    margin: 0;
  }
}
#search-zone #results {
  height: 52vh;
  margin-top: 5vh;
}
#search-zone .results {
  padding: 0 15px !important;
}
#search-zone .results h2 {
  margin-top: 10vh;
  font-size: 14px;
  color: #fff;
}
#search-zone .results h1 {
  font-size: 87px;
  color: #fff;
  font-weight: 700;
  font-family: 'Jaapokki';
}
#search-zone .results ul {
  padding: 0;
  margin: 0;
  padding-bottom: 150px;
  color: #fff;
  list-style-type: none;
}
#search-zone .results ul li::before {
  content: "—  ";
  display: inline-block;
  padding-right: 7px;
}
#search-zone .results ul li p {
  display: inline;
  font-size: 18px;
  line-height: 30px;
}
#search-zone .results ul li a {
  display: block;
  margin: 35px 0 45px;
  background-color: #41a1d7;
  color: #fff;
  border-radius: 0;
  width: 210px;
  line-height: 45px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  font-family: 'Jaapokki';
}
#search-zone .search-close {
  position: absolute;
  top: 50px;
  right: 50px;
  cursor: pointer;
}
#search-zone .close-wcag {
  display: none;
}
#newsletter {
  padding: 95px 0;
  background-color: #eee;
}
#newsletter .container {
  display: flex;
}
#newsletter .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 1024px) {
  #newsletter .text {
    height: 100%;
  }
}
#newsletter .text h2 {
  font-size: 99px;
  font-family: 'Jaapokki';
  color: #000;
  margin-top: 0;
  margin-bottom: 0;
}
@media screen and (max-width: 550px) {
  #newsletter .text h2 {
    font-size: 60px;
  }
}
#newsletter .text p {
  color: #000;
  font-size: 14px;
  line-height: 28px;
}
#newsletter .form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
@media screen and (max-width: 1023px) {
  #newsletter .form {
    padding-top: 50px;
  }
}
#newsletter .form label {
  font-weight: bold;
  font-size: 14px;
  color: #000;
  display: block;
  margin-bottom: unset;
}
#newsletter .form input {
  width: 100%;
  border: none;
  padding: 13px 30px;
  outline: none;
}
#newsletter .form button,
#newsletter .form #newsletter-notification-open {
  color: #fff;
  text-transform: uppercase;
  background-color: #000;
  text-decoration: none;
  padding: 13px 30px;
  font-family: 'Jaapokki';
  border-color: none;
  border: none;
  outline-color: #fdb81e;
}
#newsletter-notification {
  position: fixed;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 999999999;
}
#newsletter-notification .notification-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#newsletter-notification .notification-container .notification-content {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-end;
}
#newsletter-notification .notification-container .notification-content .newsletter-popup-background {
  background: #222230 0 0 no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 0.92;
  width: 100%;
  height: 100%;
}
#newsletter-notification .notification-container .notification-content .newsletter-popup-content {
  position: absolute;
  background-color: #fff;
  max-width: 100vw;
  width: 690px;
  height: 335px;
  top: 50vh;
  transform: translateY(-50%);
}
#newsletter-notification .notification-container .notification-content .newsletter-popup-content .button-container {
  width: 100%;
  height: 100%;
  padding: 45px 64px;
}
#newsletter-notification .notification-container .notification-content .newsletter-popup-content .button-container .notification-message h1 {
  margin-left: -2px;
  margin-bottom: 20px;
  text-align: left;
  font-size: 64px;
  letter-spacing: 0;
  font-family: Jaapokki;
  color: #000;
  opacity: 1;
}
@media (max-width: 768px) {
  #newsletter-notification .notification-container .notification-content .newsletter-popup-content .button-container .notification-message h1 {
    font-size: 40px;
  }
}
#newsletter-notification .notification-container .notification-content .newsletter-popup-content .button-container .notification-message p {
  margin-bottom: 40px;
  text-align: left;
  font: normal normal normal 18px/22px Open Sans;
  letter-spacing: 0;
  color: #000;
  opacity: 1;
}
#newsletter-notification .notification-container .notification-content .newsletter-popup-content .button-container .notification-button {
  outline-color: #fdb81e;
  padding: 12px 27px 10px 25px;
  float: none;
  background: #000;
  font: normal 14px Jaapokki;
  letter-spacing: 0;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  border-color: none;
  border: none;
}
#condolences .condolences__header {
  margin-bottom: 50px;
  font-size: 18px;
  color: #131f3c;
  font-weight: 800;
}
#condolences .condolences-form {
  margin-top: 75px;
  position: relative;
  margin-bottom: 75px;
}
#condolences .condolences-form textarea {
  width: 100%;
  margin-bottom: 25px;
  border-color: #131f3c;
  resize: none;
  outline: none !important;
  padding: 5px 15px;
}
#condolences .condolences-form label {
  display: none;
}
#condolences .condolences-form .input > input {
  width: 35%;
  height: 45px;
  border: 1px solid #131f3c;
  outline: none;
  padding: 0 15px;
  display: flex;
  align-items: center;
}
#condolences .condolences-form .input > input::placeholder {
  font-size: 14px;
  line-height: 2;
  color: #c9c9c9;
}
@media (max-width: 768px) {
  #condolences .condolences-form .input > input {
    width: 75%;
  }
}
#condolences .condolences-form .submit {
  position: absolute;
  bottom: 0;
  left: 35%;
}
#condolences .condolences-form .submit button {
  background-color: #131f3c;
  height: 45px;
  padding: 0 25px;
  display: flex;
  align-items: center;
  color: #fff;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 2;
}
@media (max-width: 768px) {
  #condolences .condolences-form .submit {
    position: relative;
    left: 0;
    margin-top: 30px;
  }
}
#condolences .single-condolence {
  font-size: 14px;
  line-height: 2;
  color: #131f3c;
  padding-bottom: 60px;
  margin-bottom: 60px;
  border-bottom: 1px solid #e7e7e7;
}
#condolences .single-condolence .text {
  margin-bottom: 30px;
}
#condolences .single-condolence .signature {
  font-weight: 700;
}
#condolences .single-condolence .date {
  color: #137eb0;
  font-style: italic;
}
#condolences .pagination {
  margin: 0 auto;
}
#condolences .pagination a,
#condolences .pagination span {
  font-size: 18px;
  line-height: 2;
  color: #131f3c !important;
  margin-left: 90px;
  font-weight: 700;
}
#condolences .pagination a:first-child,
#condolences .pagination span:first-child {
  margin-left: 0;
}
#condolences .pagination .current {
  font-weight: 400;
}
.flex {
  display: flex;
}
.condolence-modal-title {
  font-family: Jaapokki;
  font-size: 61px;
  color: #131f3c;
}
.condolence-modal-desc {
  color: #131f3c;
  font-size: 18px;
  line-height: 30px;
}
.zdjecie-i-tekst .zdjecie {
  padding: 0;
}
.zdjecie-i-tekst .zdjecie .zdjecie-container {
  position: relative;
  z-index: 2;
}
.zdjecie-i-tekst .zdjecie .zdjecie-container::before {
  content: "";
  display: block;
  width: 130px;
  height: 130px;
  top: -20px;
  right: -20px;
  background-color: #6d717e;
  opacity: 0.08;
  position: absolute;
  z-index: -1;
}
.zdjecie-i-tekst .zdjecie .zdjecie-container::after {
  content: "";
  display: block;
  width: 130px;
  height: 130px;
  bottom: -20px;
  left: -20px;
  background-color: #6d717e;
  opacity: 0.08;
  position: absolute;
  z-index: -1;
}
.zdjecie-i-tekst .zdjecie .zdjecie-container .autor {
  position: absolute;
  right: 0;
  bottom: 0;
  padding-left: 15px;
  padding-right: 50px;
  color: #fff;
  background-color: #000;
  font-size: 8px;
  line-height: 20px;
}
.zdjecie-i-tekst .zdjecie .zdjecie-container .autor::before {
  content: "";
  position: absolute;
  right: 100%;
  bottom: 0;
  border-bottom: 20px solid #000;
  border-left: 20px solid transparent;
}
.zdjecie-i-tekst .zdjecie .zdjecie-container .autor::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  border-bottom: 35px solid #fff;
  border-left: 35px solid transparent;
}
.zdjecie-i-tekst .tekst {
  padding-left: 0;
  padding-right: 35px;
}
@media screen and (max-width: 991px) {
  .zdjecie-i-tekst .tekst {
    margin-top: 60px;
  }
}
.zdjecie-i-tekst .tekst h2 {
  margin-top: 0;
}
.tekst .tekst-container {
  position: relative;
  z-index: 1;
}
.tekst .tekst-container iframe {
  width: 100%;
}
.tekst-2-kolumny .columns {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 60px;
  -moz-column-gap: 60px;
  column-gap: 60px;
}
@media screen and (max-width: 700px) {
  .tekst-2-kolumny .columns {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}
.zdjecie-full .bg-tekst {
  z-index: 1;
  color: #95989c !important;
  opacity: 0.18 !important;
}
.zdjecie-full .bg-tekst.left,
.zdjecie-full .bg-tekst.right {
  top: -40px !important;
}
.zdjecie-full .zdjecie-container {
  height: 560px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.zdjecie-full .zdjecie-container .txt {
  position: absolute;
  width: 800px;
  top: 40%;
  left: calc(50% - 400px);
}
@media screen and (max-width: 860px) {
  .zdjecie-full .zdjecie-container .txt {
    width: auto;
    left: auto;
  }
}
@media screen and (max-width: 500px) {
  .zdjecie-full .zdjecie-container .txt {
    top: 30%;
  }
}
.zdjecie-full .zdjecie-container .txt,
.zdjecie-full .zdjecie-container .txt p {
  font-size: 24px !important;
  text-align: center;
  color: #fff !important;
  line-height: 35px !important;
}
@media screen and (max-width: 860px) {
  .zdjecie-full .zdjecie-container .txt,
  .zdjecie-full .zdjecie-container .txt p {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.zdjecie-full .zdjecie-container .txt blockquote {
  border: none;
  text-align: right;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-style: italic;
}
@media screen and (max-width: 860px) {
  .zdjecie-full .zdjecie-container .txt blockquote {
    padding-right: 15px;
  }
}
.galeria-osob {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.galeria-osob .item {
  width: 25%;
  padding: 0;
  text-align: center;
  margin-bottom: 35px;
}
@media screen and (max-width: 740px) {
  .galeria-osob .item {
    width: 33.333%;
  }
}
@media screen and (max-width: 500px) {
  .galeria-osob .item {
    width: 50%;
  }
}
.galeria-osob .item img {
  width: 90%;
}
.galeria-osob .item:nth-child(4n ) {
  padding-right: 0;
}
.galeria-osob .item .opis {
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  color: #131f3c;
  text-align: center;
  margin-top: 15px;
}
.blueimp-gallery {
  background-color: rgba(0,0,0,0.88);
}
.blueimp-gallery .next,
.blueimp-gallery .prev {
  width: 23px;
  height: 111px;
  border: 0;
  background: url("../img/gallery-arrow-white.png") transparent no-repeat left center;
  font-size: 0;
}
.blueimp-gallery .next {
  transform: rotate(180deg);
}
.galeria-zdjec {
  display: flex;
  padding: 0;
  height: auto;
  position: relative;
  clear: both;
  width: calc(100% - 70px);
  margin: 0 auto;
}
@media (max-width: 1450px) {
  .galeria-zdjec {
    width: 100%;
  }
}
.galeria-zdjec::after {
  content: "";
  flex: auto;
}
.galeria-zdjec .gallery-move {
  position: absolute;
  width: 53px;
  height: 100%;
  background: #f00;
  top: 0;
  background: url("../img/gallery-arrow.png") no-repeat left center;
  cursor: pointer;
  transition: opacity 0.5s;
  z-index: 999;
}
@media (max-width: 1450px) {
  .galeria-zdjec .gallery-move {
    opacity: 0;
    visibility: hidden;
  }
}
.galeria-zdjec .gallery-move.left {
  left: -53px;
}
.galeria-zdjec .gallery-move.right {
  right: -53px;
  transform: rotate(180deg);
}
.galeria-zdjec .gallery-move.disabled {
  opacity: 0.1;
  cursor: not-allowed;
}
.galeria-zdjec .gallery-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.galeria-zdjec .gallery-container .linkCopy:focus .item-container .item {
  outline: solid 2px #000;
}
.galeria-zdjec .gallery-container .item-container {
  position: absolute;
}
.galeria-zdjec .gallery-container .item-container .item {
  width: 100%;
  height: 100%;
  background-position: center center !important;
  background-size: cover;
  object-fit: cover;
}
.galeria-zdjec-full {
  display: flex;
  padding: 0;
  height: auto;
  position: relative;
  clear: both;
}
.galeria-zdjec-full .gallery-move {
  position: absolute;
  width: 53px;
  height: 100px;
  background: #f00;
  top: calc(50% - 50px);
  background: url("../img/gallery-arrow-white.png") no-repeat left center;
  cursor: pointer;
  transition: opacity 0.5s;
  z-index: 999;
}
.galeria-zdjec-full .gallery-move.left {
  left: 53px;
}
.galeria-zdjec-full .gallery-move.right {
  right: 53px;
  transform: rotate(180deg);
}
@media (max-width: 750px) {
  .galeria-zdjec-full .gallery-move.left {
    left: 23px;
  }
  .galeria-zdjec-full .gallery-move.right {
    right: 23px;
  }
}
@media (max-width: 750px) {
  .galeria-zdjec-full .gallery-move.left {
    left: 10px;
  }
  .galeria-zdjec-full .gallery-move.right {
    right: 10px;
  }
}
.galeria-zdjec-full .gallery-move.disabled {
  opacity: 0.1;
  cursor: not-allowed;
}
.slick-gallery {
  width: 100%;
}
.slick-gallery .slide {
  width: auto;
  position: relative;
}
.slick-gallery .slide:not(.slick-active)::after {
  background-color: rgba(0,92,156,0.8);
  width: 100%;
  height: 100%;
  content: '';
  top: 0;
  left: 0;
  position: absolute;
}
.slick-gallery .slide img {
  width: 75vw;
}
.zdjecie-przesuniete {
  margin-top: -140px;
  padding: 0;
  z-index: -2;
}
@media screen and (max-width: 1300px) {
  .zdjecie-przesuniete {
    margin-top: 0;
  }
}
.zdjecie-przesuniete img {
  width: 100%;
}
.tabs {
  --accent-color: #d3d3d3;
  --indicator-color: #808080;
  --shift: 0deg;
}
.tabs .tab:last-of-type {
  border-bottom: solid 1px var(--accent-color);
}
.tabs .tab {
  --height: 0;
  border-top: solid 1px var(--accent-color);
  cursor: pointer;
}
.tabs .tab .main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tabs .tab .main .header {
  margin: 0 !important;
  padding: 20px 0 !important;
  color: #2aace8 !important;
}
.tabs .tab .main .indicator {
  position: relative;
  width: 18px;
  aspect-ratio: 1;
}
.tabs .tab .main .indicator:after,
.tabs .tab .main .indicator:before {
  content: '';
  position: absolute;
  background-color: var(--indicator-color);
  top: 50%;
  width: 100%;
  height: 2px;
  border-radius: 8px;
  translate: 0 50%;
  transform-origin: center;
  transition: background-color 0.5s ease-in-out, rotate 0.5s ease-in-out;
  rotate: var(--shift);
}
.tabs .tab .main .indicator:after {
  rotate: calc(90deg + var(--shift));
}
.tabs .tab .expandable {
  height: 100%;
  max-height: var(--height);
  overflow-y: hidden;
  pointer-events: none;
  transition: max-height 0.5s ease-in-out;
}
.tabs .tab .expandable p {
  padding: 20px 0 !important;
}
.tabs .tab.expanded {
  --indicator-color: #2aace8;
  --shift: 45deg;
}
.tabs .tab.expanded .expandable {
  pointer-events: auto;
}
#powiazane {
  background-color: #f8f8f8;
}
#powiazane #powiazane-wrapper {
  padding: 80px 0;
}
@media (max-width: 1023px) {
  #powiazane #powiazane-wrapper .amp.top {
    display: none;
  }
}
#powiazane #powiazane-wrapper .item {
  width: 250px;
  border: 1px solid #131f3c;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  margin: 0 auto 15px auto;
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  #powiazane #powiazane-wrapper .item {
    width: 215px;
  }
}
#powiazane #powiazane-wrapper .item .name {
  font-family: 'Jaapokki';
  font-size: 14;
  color: #131f3c;
  margin: 25px auto;
  display: block;
  text-align: center;
  text-transform: uppercase;
}
#powiazane #powiazane-wrapper .item .opis {
  font-size: 12px;
  color: #131f3c;
  padding: 0 45px 30px;
  text-align: center;
}
.notification-form {
  background-color: #eee;
  padding-bottom: 80px;
}
@media (max-width: 768px) {
  .notification-form {
    padding-bottom: 60px;
  }
}
.notification-form .tab {
  padding-top: 80px;
}
@media (max-width: 768px) {
  .notification-form .tab {
    padding-top: 60px;
  }
}
.notification-form .tab h3 {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 40px;
}
.notification-form header {
  position: static;
  color: #000;
  margin-bottom: 20px;
}
.notification-form header h3 {
  margin-bottom: 5px !important;
}
.notification-form label {
  font-weight: normal;
}
.notification-form .fields .tab .field {
  display: flex;
  margin-bottom: 24px;
  flex-direction: column;
  flex-wrap: wrap;
}
.notification-form .fields .tab .field a {
  color: #2aace8;
}
.notification-form .fields .tab .field .heading legend,
.notification-form .fields .tab .field .heading label {
  font-weight: 700;
  font-size: 14px;
  color: #000;
  display: block;
  margin-bottom: 0;
  border-bottom: none;
}
.notification-form .fields .tab .field .heading .instructions {
  font-size: 14px;
  line-height: 28px;
  color: #131f3c;
}
.notification-form .fields .tab .field .input input,
.notification-form .fields .tab .field .input textarea,
.notification-form .fields .tab .field .input select {
  margin: 10px 0 15px;
  border: none;
  padding: 13px 30px;
  outline: 0;
  width: 100%;
}
.notification-form .fields .tab .field .input input:focus,
.notification-form .fields .tab .field .input textarea:focus,
.notification-form .fields .tab .field .input select:focus {
  outline: none;
}
.notification-form .fields .tab .field .input textarea {
  height: 209px;
}
.notification-form .fields .tab .field .input label {
  display: block;
  margin-bottom: 15px;
}
.notification-form .fields .tab .field .input input[type=checkbox],
.notification-form .fields .tab .field .input input[type=radio] {
  width: auto;
}
.notification-form .fields .tab .field.fileupload input {
  margin-left: 0;
  padding-left: 0;
}
.notification-form .fields .tab .field.fileupload ::-webkit-file-upload-button {
  padding: 0 30px;
  line-height: 44px;
  color: #fff;
  font-weight: bold;
  background-color: #000;
  text-transform: uppercase;
  border: none;
}
.notification-form .fields .tab .field.fileupload ::-webkit-file-upload-button:focus {
  outline: none;
}
.notification-form .fields .tab .field.phone .input > div {
  display: flex;
  flex-direction: column;
}
.notification-form .fields .tab .field.phone .input > div select {
  margin-bottom: 0;
}
@media (min-width: 600px) {
  .notification-form .fields .tab .field.phone .input > div {
    display: flex;
    flex-direction: row;
  }
  .notification-form .fields .tab .field.phone .input > div select {
    width: 216px;
    margin-right: 30px;
    margin-bottom: 15px;
  }
}
.notification-form .fields .tab .field ul.errors {
  margin: 10px 0;
  padding: 0;
  padding-left: 216px;
  list-style-type: none;
  color: #ff4949;
}
.notification-form .fields .tab .field.multiplechoice .input {
  padding-left: 30px;
  position: relative;
}
.notification-form .fields .tab .field.multiplechoice .input input {
  position: absolute;
  left: 0;
  margin: 0;
  width: 1px;
  background-color: transparent;
}
.notification-form .fields .tab .field.multiplechoice .input input::before {
  content: "";
  display: inline-block;
  min-width: 21px;
  height: 21px;
  background-color: #fff;
  margin-right: 6px;
  left: 0;
  top: 0;
  border-radius: 50%;
  position: absolute;
}
.notification-form .fields .tab .field.multiplechoice .input input:checked {
  background-color: transparent;
}
.notification-form .fields .tab .field.multiplechoice .input input:checked::after {
  content: "";
  position: absolute;
  left: 6px;
  top: 6px;
  width: 9px;
  height: 9px;
  background-color: #000;
  border-radius: 50%;
}
.notification-form .fields .tab .field.optin .input {
  padding-left: 30px;
  position: relative;
}
.notification-form .fields .tab .field.optin .input input {
  margin: 0;
  position: absolute;
  left: 0;
  margin: 0;
  width: 1px;
  background-color: transparent;
}
.notification-form .fields .tab .field.optin .input input::before {
  content: "";
  display: inline-block;
  min-width: 20px;
  height: 20px;
  background: #fff;
  margin-right: 4px;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 0;
}
.notification-form .fields .tab .field.optin .input input:checked {
  background-color: transparent;
}
.notification-form .fields .tab .field.optin .input input:checked::after {
  content: "";
  position: absolute;
  left: 7px;
  top: 4px;
  width: 6px;
  height: 10px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.notification-form .fields .tab .field .sproutcheckboxes input {
  opacity: 0;
  position: absolute;
}
.notification-form .fields .tab .field .sproutcheckboxes span {
  position: relative;
  margin-right: 40px;
}
.notification-form .fields .tab .field .sproutcheckboxes span:before {
  content: "";
  display: inline-block;
  min-width: 20px;
  height: 20px;
  background: #fff;
  margin-right: 4px;
  border-radius: 4px;
  position: absolute;
}
.notification-form .fields .tab .field .sproutcheckboxes :checked + span::after {
  content: "";
  position: absolute;
  left: 7px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.notification-form .fields .tab .field .file-container input[type="file"] {
  position: absolute;
  opacity: 0;
  top: 0;
}
.notification-form .fields .tab .field .file-container label {
  background-color: #fff;
  display: inline-block;
  position: relative;
}
.notification-form .fields .tab .field .file-container .reset-file {
  margin-left: 55px;
  background-color: transparent;
  border: none;
  font-size: 12px;
  font-weight: bold;
}
.notification-form .fields .tab .field .file-container .reset-file:focus {
  outline: none;
}
.notification-form .consent {
  margin-bottom: 60px;
}
.notification-form .consent p {
  font-size: 12px;
}
@media (min-width: 1024px) {
  .notification-form .consent {
    margin-left: 216px;
  }
}
.notification-form .submit button {
  padding: 0 30px;
  line-height: 44px;
  color: #fff;
  font-weight: bold;
  background-color: #000;
  text-transform: uppercase;
  border: none;
}
.notification-form .submit button:focus {
  outline: none;
}
.my-select ul>li {
  padding: 0;
}
.my-select-button.ui-button {
  width: 100%;
  border: none;
  background: #fff;
  margin-right: 0;
  max-width: 412px;
  border-bottom: 1px solid #eee;
}
.my-select-button.ui-button:focus {
  outline: none;
  color: #000;
}
.my-select-button.ui-corner-top {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.my-select-button .ui-icon {
  background-image: url("../img/arrow.png");
}
.my-select-button .ui-icon-triangle-1-s {
  background-position: 5px 6px;
}
.my-select-button:active .ui-icon {
  background-image: url("../img/arrow.png");
}
.my-select-button:hover .ui-icon {
  background-image: url("../img/arrow.png");
}
.my-select-button:focus .ui-icon {
  background-image: url("../img/arrow.png");
}
.my-select-menu .ui-widget.ui-widget-content {
  border: 1px solid #fff;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.my-select-menu ul>li {
  padding-left: 12px;
  line-height: normal;
}
.my-select-menu ul>li:before {
  content: none;
}
.my-select-menu .ui-state-active,
.my-select-menu .ui-widget-content .ui-state-active {
  color: #63a4d9;
  background-color: transparent;
  border: none;
}
.my-select-menu .ui-menu .ui-state-active {
  margin: 0;
}
.my-select-menu .ui-menu .ui-menu-item-wrapper {
  padding-left: 0;
}
.freeform-row button {
  padding: 0 30px !important;
  line-height: 44px !important;
  color: #fff !important;
  font-weight: bold !important;
  background-color: #000 !important;
  text-transform: uppercase !important;
  border: none !important;
  width: 200px !important;
}
.freeform-row .freeform-column {
  display: flex;
  margin-bottom: 24px;
  flex-direction: column;
  flex-wrap: wrap;
}
.freeform-row a {
  color: #2aace8;
}
.freeform-row .freeform-instructions {
  color: #363636 !important;
}
.freeform-row .freeform-label {
  font-weight: 700;
  font-size: 14px !important;
  color: #000;
  display: block;
  margin-bottom: 0;
  border-bottom: none;
}
.freeform-row .instructions {
  font-size: 14px;
  line-height: 28px;
  color: #131f3c;
}
.freeform-row input[type=radio] {
  background-color: #f00 !important;
}
.freeform-row .freeform-input,
.freeform-row textarea,
.freeform-row select {
  margin: 10px 0 15px;
  border: none;
  padding: 13px 30px;
  outline: 0;
  width: 100%;
}
.freeform-row textarea {
  height: 209px;
}
.freeform-row label {
  display: block;
  margin-bottom: 15px;
}
.freeform-row input[type=checkbox],
.freeform-row input[type=radio] {
  width: auto;
  margin-right: 10px !important;
}
.custom-form-error {
  font-size: 12px;
  line-height: 14px;
  color: rgba(255,0,0,0.8);
  font-weight: 400;
  margin-right: 5px;
  right: 0px;
}
.custom-form-error-submit {
  font-size: 12px;
  line-height: 14px;
  color: rgba(255,0,0,0.8);
  font-weight: 400;
  margin-right: 5px;
  right: 0px;
  margin-top: 5px;
}
#freeform-popup {
  position: absolute;
  display: none;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,9,0.88);
  z-index: 999999;
}
#freeform-popup .freeform-content {
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
}
#freeform-popup > .container {
  position: relative;
}
#freeform-popup > .container .content {
  margin-top: 5%;
  margin-bottom: 10%;
  padding: 0 100px 100px;
  background-color: #fff;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 992px) {
  #freeform-popup > .container .content {
    padding: 80px 50px 50px;
  }
}
@media screen and (max-width: 700px) {
  #freeform-popup > .container .content {
    padding: 80px 30px 30px;
  }
}
@media screen and (max-width: 470px) {
  #freeform-popup > .container .content {
    padding: 80px 15px 15px;
  }
}
#freeform-popup > .container .content.newsletter-popup {
  padding: 0 100px;
}
@media screen and (max-width: 992px) {
  #freeform-popup > .container .content.newsletter-popup {
    padding: 0 50px;
  }
}
@media screen and (max-width: 700px) {
  #freeform-popup > .container .content.newsletter-popup {
    padding: 0 30px;
  }
}
@media screen and (max-width: 470px) {
  #freeform-popup > .container .content.newsletter-popup {
    padding: 0 15px;
  }
}
#freeform-popup > .container .content h1 {
  font-size: 24px;
  color: #131f3c;
  margin-top: 55px;
  margin-bottom: 25px;
  font-weight: bold;
}
#freeform-popup > .container .content .container {
  width: 100%;
}
#freeform-popup > .container .content .controls {
  margin-top: 50px;
}
#freeform-popup > .container .content .controls .close {
  position: absolute;
  right: 0;
  top: 0;
  width: 45px;
  height: 45px;
  background-color: rgba(8,54,91,0.93);
  opacity: 1;
}
#freeform-popup > .container .content .controls .close img {
  padding: 10px;
}
#freeform-popup > .container .content .controls .close-wcag {
  display: none;
}
.top-form {
  height: 100px;
  background-color: #fff;
  display: flex;
  align-items: center;
}
.top-form .svg {
  height: 32px;
  width: 321px;
}
.top-form .cls-1 {
  fill: #63a4d9 !important;
}
.photo-form {
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media screen and (min-width: 2000px) {
  .photo-form {
    height: 400px;
  }
}
.text-form {
  padding-bottom: 80px;
  padding-top: 80px;
}
@media (max-width: 768px) {
  .text-form {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.text-form .header {
  font-size: 69px;
  margin-bottom: 30px;
  font-family: 'Jaapokki';
}
@media (max-width: 1400px) {
  .text-form .header {
    font-size: 50px;
  }
}
@media (max-width: 1000px) {
  .text-form .header {
    font-size: 40px;
  }
}
@media (max-width: 550px) {
  .text-form .header {
    font-size: 25px;
  }
}
.text-form .paragraph {
  line-height: 28px;
}
.popup-form {
  background-color: rgba(0,92,156,0.88);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 15px;
  display: none;
}
.popup-form .popup {
  max-width: 626px;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  margin-top: 5%;
  display: block;
  padding: 30px;
}
@media (max-width: 767px) {
  .popup-form .popup {
    padding: 15px;
  }
}
.popup-form .popup p {
  margin: 0;
}
.popup-form .popup .content {
  padding: 7%;
}
.popup-form .popup .content .header {
  margin-bottom: 40px;
}
.popup-form .popup .content .header p {
  font-weight: bold;
  font-family: 'Jaapokki';
  font-size: 20px;
}
@media (max-width: 767px) {
  .popup-form .popup .content .header {
    font-size: 14px;
  }
}
.popup-form .popup .content .paragraph {
  margin-bottom: 50px;
}
.popup-form .popup .content p {
  line-height: 23px;
}
.popup-form .popup .content button {
  border: none;
  background-color: #eee;
  padding: 5px 20px;
}
.popup-form .popup .content button:focus {
  outline: none;
}
.nagrody-i-wyroznienia-site img,
.nagrody-i-wyróżnienia-site img,
.lista-kompozycji-site img,
.krzysztof-penderecki-site img,
.kondolencje-site img,
.condolence-site img,
.nagrody-i-wyroznienia-site .img_container,
.nagrody-i-wyróżnienia-site .img_container,
.lista-kompozycji-site .img_container,
.krzysztof-penderecki-site .img_container,
.kondolencje-site .img_container,
.condolence-site .img_container,
.nagrody-i-wyroznienia-site .img,
.nagrody-i-wyróżnienia-site .img,
.lista-kompozycji-site .img,
.krzysztof-penderecki-site .img,
.kondolencje-site .img,
.condolence-site .img,
.nagrody-i-wyroznienia-site#site-preview,
.nagrody-i-wyróżnienia-site#site-preview,
.lista-kompozycji-site#site-preview,
.krzysztof-penderecki-site#site-preview,
.kondolencje-site#site-preview,
.condolence-site#site-preview {
  filter: grayscale(100%);
}
.nagrody-i-wyroznienia-site .item-container .item,
.nagrody-i-wyróżnienia-site .item-container .item,
.lista-kompozycji-site .item-container .item,
.krzysztof-penderecki-site .item-container .item,
.kondolencje-site .item-container .item,
.condolence-site .item-container .item {
  filter: grayscale(100%);
}
