#search-zone
	position: absolute
	z-index: 9999999
	width: 100%
	height: 100%
	top: 0
	left: 0
	overflow: hidden
	background-color: rgba( 0 , 92 , 156, 0.88 )
	padding-bottom: 150px
	display: none

	#search-form
		margin-top: 10vh
		padding: 0 15px !important

		h1
			font-size: 87px
			color: #fff
			margin-bottom: 55px
			font-weight: 700
			font-family: 'Jaapokki'

			@media( max-width: 650px )
				font-size: 50px

		input
			width: calc( 100% - 175px )
			display: inline
			border-radius: 0
			border: 1px solid #fff
			outline: none
			padding: 15px 25px
			line-height: 45px
			height: 45px
			color: #fff
			background-color: transparent

			@media( max-width: 650px )
				width: 100%

		button
			width: 170px;
			line-height: 43px;
			border: 1px solid #fff
			padding: 0
			margin: 0
			border-radius: 0
			margin-top: -4px
			margin-left: -4px
			text-transform: uppercase
			color: rgb( 0 , 92 , 156 )
			background-color: #fff
			font-size: 12px
			font-weight: bold

			@media( max-width: 650px )
				width: 100%
				margin: 0

	#results
		height: 52vh
		margin-top: 5vh

	.results
		padding: 0 15px !important
		h2
			margin-top: 10vh
			font-size: 14px
			color: #fff

		h1
			font-size: 87px
			color: #fff
			font-weight: 700
			font-family: 'Jaapokki'

		ul
			padding: 0
			margin: 0
			padding-bottom: 150px
			color: #fff
			list-style-type: none

			li

				&::before
					content: "—  "
					display: inline-block
					padding-right: 7px

				p
					display: inline
					font-size: 18px
					line-height: 30px

				a
					display: block
					margin: 35px 0 45px
					background-color: #41a1d7
					color: #fff
					border-radius: 0
					width: 210px
					line-height: 45px
					text-align: center
					text-decoration: none
					font-size: 14px
					font-family: 'Jaapokki'

	.search-close
		position: absolute
		top: 50px
		right: 50px
		cursor: pointer
	.close-wcag
		display: none
