footer-gray-bg = #979797 

footer
	#logos
		margin: 85px 0 

		#ue-col
			border-right: 1px solid dark-blue-color

			@media screen and (max-width: 990px)
				border-right: none

			.txt
				font-size: 11px 
				font-weight: bold 
				text-align: center 
				padding: 15px 0
		
		#partnerzy-col
			.item
				display: block		 
				@media( min-width: 992px )
					width: 33%		 
				@media( min-width: 1199px )
					width: 20%	
				@media( max-width: 991px )
					padding: 15px 0

	#dark-ending
		padding: 40px 0 30px;
		background-color: #000
		#logo
			padding: 10px 30px 0
			@media screen and (max-width: 1400px) and (orientation: landscape)
				padding: 0
				img
					max-width 80%

		#txts
			font-size: 14px
			color: footer-gray-bg

			@media screen and (max-width: 990px)
				text-align: center
			h1, h2, h3, h4 
				font-weight: 900
				font-size: 14px
		.col-md-9
			.row
				.extra-padding
					padding-top 46px
				a
					color: footer-gray-bg
				.col-md-2
					.contain-footer
						position relative
						top 80px
						display flex
						flex-direction row
						@media screen and (max-width: 1439px)
							top 98px
						@media screen and (max-width: 1198px)
							top 118px
						.availability
							margin 0
							.wheelchair
								fill grey
								height 22px
								color grey
							.dost
								text-decoration none !important
								color #979797
								text-decoration-color transparent !important
						.text-availability
							padding-top 6px
							padding-left 6px
		#pleo-footer
			display: block
			margin-right: 30px;
			float: right;
			width: 45px;
	#scroll-up
		display: none