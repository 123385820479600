header
	position: absolute
	width: 100%
	color: #fff
	z-index: 1000
	
	div
		width: 100%
	
	#logo
		float: left
		width: 500px
		padding: 50px 80px
		max-width: 100%
		
		@media( max-width: 1023px )
			padding: 35px
			width: 400px
			
		@media( max-width: 767px )
			float: none
			margin: 0 auto
			display: block
			
		@media( max-width: 500px )
			width: 100%
		
	#top-info-wrapper
		width: calc( 100% - 500px )
		float: right
		padding-right: 80px
		
		@media( max-width: 1023px )
			width: calc( 100% - 400px )
			padding-right: 30px
		
		.info-item
			width: 290px
			display: inline-block
			float: right
			margin-top: 40px
			padding-left: 20px
			border-left: 1px solid #fff
			color: #fff
			text-decoration: none
			height: 57px
			
			.date
				font-weight: bold

			.txt
				font-weight: 900
			
			@media( max-width: 1023px )
				margin-top: 25px
				
			@media( max-width: 767px )
				display: none
			
			&:first-child
				margin-left: 95px

			@media( max-width: 1399px )
				width: 190px
			
			.date
				display: block
				font-size: 12px
				
			.txt
				font-size: 12px
				text-transform: uppercase
				
			#sponsors
				width: 100%
				.item
					display: none
					width: 100%

					img
						display: block
						margin: 0 auto
						height: 57px

					&:first-child
						display: inline-block
	.wcag-icon
		display: none
#playBtn
	opacity 0
#pauseBtn
	opacity 1
.animation-control
	// wyłącznik animacji
	display none
	z-index 6000
	transition 0.5s
	opacity 1
	position absolute
	top 45px
	left 50%
	height 50px
	width 50px
	background-color transparent
	.playpause-button
		background-color transparent
		width 100%
		height 100%
		.playsvg
			//adasd
		.pausesvg
			//jkljkl
.no-animation
	opacity 0 !important
.btn-fadeIn
	opacity 1 !important
	z-index 6100 !important
.btn-fadeOut
	opacity 0 !important

