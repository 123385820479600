dark-blue-color = #131f3c
hover-blue = #2aace8

@font-face {
  font-family: 'Jaapokki'
  src: url('../fonts/jaapokki-regular.eot')
  src: url('../fonts/jaapokki-regular.eot?#iefix') format('embedded-opentype'),
	   url('../fonts/jaapokki-regular.woff') format('woff'),
	   url('../fonts/jaapokki-regular.ttf')  format('truetype'),
	   url('../fonts/jaapokki-regular.svg#svgFontName') format('svg')
}

.clearfix
	clear: both

.noscroll
	overflow-y: hidden

body
	font-family: 'Open Sans', sans-serif;
	overflow-x: hidden
	position: relative
	padding: 0 !important
	&.removeOverflowY
		overflow-y: hidden

	//*
	//	outline: solid 1px greenyellow

	.nopadding
		padding: 0 !important

	img
		max-width: 100%

	main
		position: relative

		#winda
			position: absolute
			left: 0
			top: 100px
			background-color: #fff
			z-index: 1000
			width: 100%
			max-width: 10vw
			&.affix
				top: 70px
				position: fixed


			@media screen and (max-width:1500px)
				display: none

			@media screen and (min-width:1700px)
				max-width: 15vw

			h4
				padding: 38px 40px 18px 40px
				font-size: 36px
				font-weight: 700
				color: dark-blue-color
				position: relative
				&:after
					content:''
					width: 40px
					height: 1px
					background-color: dark-blue-color
					position: absolute
					bottom: -10px
					left: 40px

			ul
				list-style-type: none
				padding: 32px 0 43px 0
				padding-bottom: 52px
				margin: 0

				li
					a
						font-size: 11px
						text-transform: uppercase
						cursor: pointer
						padding: 9px 40px
						font-weight: 400
						color: dark-blue-color

						&:hover
							background-color: transparent

					&.active
						a
							font-weight: 700
							padding: 28px 28px
	.skiplink
		display block
		opacity 1
		position absolute
		left:-200px
		z-index 909090
		text-align center
		word-break break-word
		border 2px solid black
		background-color white
		width 200px
		height auto
		color black
		&:focus
			left 0
