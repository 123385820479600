#newsletter
	padding: 95px 0
	background-color: #eeeeee
	.container{
		display: flex
	}
	.text
		display flex
		flex-direction column
		justify-content center
		@media (min-width: 1024px)
			height: 100%
		h2
			font-size: 99px
			font-family: 'Jaapokki'
			color: #000
			margin-top: 0
			margin-bottom: 0
			@media screen and (max-width: 550px)
				font-size: 60px

		p
			color: #000
			font-size: 14px
			line-height: 28px

	.form
		display flex
		flex-direction column
		row-gap: 20px

		@media screen and (max-width: 1023px)
			padding-top 50px

		label
			font-weight: bold
			font-size: 14px
			color: #000
			display: block
			margin-bottom: unset

		input
			width: 100%
			border: none
			padding: 13px 30px
			outline: none

		button , #newsletter-notification-open
			color: #fff
			text-transform: uppercase
			background-color: #000
			text-decoration: none
			padding: 13px 30px
			font-family: 'Jaapokki'
			border-color none
			border none
			outline-color #fdb81e
#newsletter-notification
	position fixed
	top 0
	display block
	width 100%
	height 100%
	z-index 999999999
	.notification-container
		position relative
		width 100%
		height 100%
		display flex
		justify-content center
		align-items center
		.notification-content
			display flex
			position relative
			width 100%
			height 100%
			justify-content center
			align-items flex-end
			.newsletter-popup-background
				background: #222230 0 0 no-repeat padding-box;
				border: 1px solid #707070;
				opacity: 0.92;
				width 100%
				height 100%
			.newsletter-popup-content
				position absolute
				background-color #fff
				max-width 100vw
				width 690px
				height 335px
				top 50vh
				transform translateY(-50%)
				.button-container
					width 100%
					height 100%
					padding 45px 64px
					.notification-message
						h1
							margin-left -2px
							margin-bottom 20px
							text-align left
							font-size 64px
							letter-spacing 0
							font-family Jaapokki
							color #000000
							opacity 1
							@media( max-width: 768px )
								font-size 40px
						p
							margin-bottom 40px
							text-align left
							font normal normal normal 18px/22px Open Sans
							letter-spacing 0
							color #000000
							opacity 1
					.notification-button
						outline-color #fdb81e
						padding 12px 27px 10px 25px
						float none
						background #000000
						font: normal 14px Jaapokki;
						letter-spacing: 0;
						color: #fff
						text-transform: uppercase
						text-decoration: none
						border-color none
						border none
