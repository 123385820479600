.condolences-free-form-headline
  font-size: 18px!important
  color: #131f3c
  font-weight: 800
  margin-bottom:30px
  margin-top:60px

.condolences-free-form
  margin-bottom:60px

  .freeform-row
    margin:0
    width: 100%;
    button
      padding: 0 10px !important
      line-height: 48px !important
      color: #fff
      font-weight: bold !important
      background-color: #131f3c !important
      text-transform: uppercase !important
      border: none !important
      width 100px !important
      margin-top: 20px

    &:last-of-type
      width:70%
      @media screen and (max-width: 768px)
        width:100%

    @media screen and (max-width: 768px)
      .freeform-row
        &:last-of-type
          width:100%

    .freeform-column
      margin: 0

      .freeform-input
        border:1px solid #131f3c
        margin:0
      .freeform-label

        //display:none


