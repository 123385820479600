#emanacje
	margin-top: 70px
	> #loading
		display: none !important
	#loading
		padding: 80px 0

		img
			display: block
			margin: 0 auto

	.load-more
		position: absolute
		bottom: -100px
		left: 50%
		transform: translateX(-50%)
		color: #1D2944
		font-weight: 700
		font-size: 14px
		text-transform: lowercase
		text-align: center
		text-decoration: none

	.current-emanations-link
		margin: 0 auto
		width: 90%
		margin-bottom: 30px
	.current-emanations-link a
		font-weight: 700
		color: #2AACE8 !important

.emanations-list
	margin-top: 70px
	height: 100vw

.emanation-item
	padding: 14px
	font-family: Open Sans
	border: 1px solid #D0D0D0
	display: flex
	flex-direction: column
	height: fit-content
	white-space: normal

	.category-box
		text-align: center
		font-size: 12px
		color: #1D2944
		font-weight: 600
		text-transform: lowercase
		.category-gray
			background-color: #E9E9E9
			padding:5px
		.category-yellow
			background-color: #FEF263
			padding:5px
		.category-red
			background-color: #FF957E
			padding:5px
		.category-black
			background-color: #000
			padding:5px
			color: #FFF
		.category-dark-with-red
			background-color: #1D2944
			padding:5px
			color: #F05A39
		.category-dark-with-yellow
			background-color: #1D2944
			padding:5px
			color: #FEF263
		.category-blue
			background-color: #2AACE8
			padding:5px
			color: #1D2944
		.category-light-blue
			background-color: #C4E0ED
			padding:5px
			color: #1D2944
	.short-content
		.date
			color: #1D2944
			font-size: 12px
			margin-top: 30px
			font-weight: 600
		a.tag
			color: hover-blue
			font-size: 14px
			font-style: italic
			text-decoration: none
			text-transform: lowercase
			pointer-events: none
		.title
			margin-top:13px
			margin-bottom:20px
			font-size: 24px
			font-weight: bold
			@media(max-width: 1110px)
				font-size: 18px
			a
				color: #1D2944
		.short-desc
			p
				color: #1D2944
				font-size: 12px
				font-weight: 600
				line-height: 28px
		.bottom-box
			display: flex
			justify-content: space-between
			height: 20px
			margin-top: 20px
			#share-box
				cursor: pointer
				position: relative
				display: flex
				#webSocialList
					list-style-type: none
					padding-left: 0
					display: flex
					position: absolute
					overflow: hidden
					left: 0
					top: 2px
					-webkit-transition: all 1s ease
					-moz-transition: all 1s ease
					-o-transition: all 1s ease
					-ms-transition: all 1s ease
					transition: all 1s ease
					.social-icon
						margin-left: 10px
						transition: all 1s ease
						display: none
						.fa
							font-size: 15px
							color: hover-blue
				.visible-social-icons
					left: 20px !important
					.social-icon
						display: block !important
				.share-button
					transition: all 1s ease
				.close
					width: 12px
					height: 14px
					position: absolute
					display: none
					margin-bottom:5px
					transition: all 1s ease
			.read-more
				color: #137EB0
				font-size: 12px
				font-weight: bold
				font-style: italic
				cursor: pointer



#sticky-news-nav.emanations-nav
	top: 0
	left: 50%
	transform: translateX(-50%);
	width: 90%
	height: 70px
	display: flex
	justify-content space-between
	padding: 0

	#show-all
		display: none
		white-space: nowrap
		align-items: center
		position: relative
		font-weight: 800
		color: #1D2944

		&.show-all-icon
			&::before, &:after
				position: absolute
				top: calc(50% - 2px)
				right: -12px
				content: ''
				height: 8px
				width: 2px
				background-color: #1D2944
				border-radius: 2px
			&:before
				transform: rotate(45deg)
			&:after
				transform: rotate(-45deg)

	.select-box
		width: 15%

		.emanations-filtration-list
			width: 100vw
			position: absolute
			left: 0
			margin-left: -1px

	.filter
		padding-bottom 15px
		margin-top 15px
		background-color transparent
		border-color transparent
		display: flex
		align-items: center
		font-weight: bold
		text-transform: uppercase
		padding-top: 6px
		padding-left: 0
		cursor: pointer

		.arrow
			margin-left: 5px

	#emanation-filter-list
		width: 85%
		display: flex
		align-items: center

		.selected-filter-item
			margin-right: 35px
			font-size: 14px
			position: relative
			color: #1D2944
			span
				font-size: 11px
				position: absolute
				right: -10px
				top: 2px
				font-weight: bold
				cursor: pointer
	.list
		background-color: #fff
		padding-top: 0
		display: flex
		@media(max-width:450px)
			flex-direction: column

		span
			display: block
			padding-left: 2px;
			padding-bottom: 10px
			text-transform: uppercase
			font-weight: 800
		span:not(:first-child)
			padding-top: 10px
		ul li
			text-transform: lowercase
			padding-left: 2px
			font-size: 12px

			&.active
				font-weight: normal
				text-decoration: underline
				&::before
					display: none
			a
				color: dark-blue-color
				text-decoration: none
				&:hover
					font-weight: normal
					text-decoration: underline
		.list-box
			margin-right: 10px
			@media(max-width:450px)
				margin-bottom: 30px
		.archives
			margin-left: 30px
			@media(max-width:450px)
				margin-left: 0


.emanations-grid
	width: 90%
	margin: 0 auto 150px

.grid-item
	width: 25%
	@media(min-width:1600px)
		width: 20%
	@media(max-width:1000px)
		width: 33%
	@media(max-width:700px)
		width: 50%
	@media(max-width:500px)
		width: 80%
		margin-left:10%
	@media(max-width:400px)
		width: 100%
		margin-left:0

.visible
	display: flex !important

.emanation-popup
	background-color: rgba(0,0,0,.88) !important
	.container .content .controls .close
		background-color: rgba(0,0,0,.78) !important

#szablon.emanation
	.date
		margin-top: 40px
		font-family: Open Sans
		font-size: 12px
		font-weight: 700
	h1
		margin-top: 20px !important
		margin-bottom: 10px !important
#first-paragraph
	padding: 0
.button-div
	background-color transparent
	border-color transparent

