.text-form
    padding-bottom: 80px
    padding-top: 80px
    @media( max-width: 768px )
        padding-top: 60px
        padding-bottom: 60px
    .header
        font-size: 69px
        margin-bottom: 30px
        font-family: 'Jaapokki'
        @media( max-width: 1400px )
            font-size: 50px
        @media( max-width: 1000px )
            font-size: 40px
        @media( max-width: 550px )
            font-size: 25px

    .paragraph
        line-height: 28px
