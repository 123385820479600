.contact_wrapper, #availability-wrapper
	max-width: 1140px
	height: auto
	margin: auto
	clear: both
	@media screen and (max-width: 1140px)
		padding: 0 20px

#availability-wrapper
	font-family: 'Open Sans',sans-serif;
	line-height: 28px
	font-size: 14px
	h2
		font-weight: 700
		font-size: 18px
	a
		text-decoration: none
		color: dark-blue-color
		&:hover
			text-decoration: underline

#contact, #availability
	width: 100%
	height: auto
	margin: 100px 0 0 0
	clear: both

	.company_place
		width: 100%
		height: auto
		margin-bottom: 170px
		clear: both

		.first
			width: 100%
			height: auto
			margin-bottom: 170px
			clear: both

			.full_map
				width: 100%
				height: 500px
				margin-bottom: 25px
				clear: both

			.heading
				width: 100%
				height: auto
				font-size: 18px
				font-family: Open Sans
				font-weight: bold
				color: dark-blue-color
				margin-bottom: 50px
				clear: both
				@media screen and (max-width: 1140px)
					text-align: center

			.informations_box
				width: 100%
				height: auto
				clear: both
				display: flex
				flex-direction: row
				flex-wrap: wrap
				justify-content: space-between
				align-content: stretch
				align-items: flex-start

				.item
					&:nth-child(-n+4)
						margin-top: 0
					width: 25%
					height: auto
					margin-top: 100px
					font-size: 14px
					font-family: Open Sans
					color: dark-blue-color
					line-height: 30px
					@media screen and (max-width: 1140px)
						text-align: center
					@media screen and (max-width: 940px)
						&:nth-child(-n+2)
							margin-top: 0
						&:not(:nth-child(-n+2))
							margin-top: 100px
						width: 50%
					@media screen and (max-width: 500px)
						&:first-child
							margin-top: 0
						&:not(:first-child)
							margin-top: 50px
						width: 100%

					.person_fullname
						width: 100%
						height: auto
						font-family: Jaapokki
						font-weight: bold
						color: dark-blue-color
						text-transform: uppercase
						margin-bottom: 8px
						clear: both

					.person_role
						width: 100%
						height: auto
						font-size: 12px
						line-height: 20px
						clear: both

		.second
			width: 100%
			height: auto
			clear: both

			.left_side
				//width: 508px
				height: auto
				float: left
				@media screen and (max-width: 1140px)
					width: 100%
					float: none
					clear: both

				.heading
					width: 100%
					height: auto
					font-size: 18px
					font-family: Open Sans
					font-weight: bold
					color: dark-blue-color
					margin-bottom: 50px
					clear: both
					@media screen and (max-width: 1140px)
						text-align: center

				.informations_box
					width: 100%
					height: auto
					clear: both
					display: grid
					grid-template-columns: 1fr
					gap: 50px
					//display: flex
					//flex-direction: row
					//flex-wrap: wrap
					//justify-content: space-between
					//align-content: stretch
					//align-items: flex-start
					@media screen and (min-width: 501px)
						grid-template-columns: repeat(2,1fr)
					@media screen and (min-width: 1141px)
						grid-template-columns: repeat(4,1fr)
						gap 75px


					.item
						@media screen and (min-width: 500px)
							&:nth-child(-n+2)
								margin-top: 0
						//width: 50%
						width: 100%
						height: auto
						//margin-top: 85px
						font-size: 14px
						font-family: Open Sans
						color: dark-blue-color
						line-height: 30px
						@media screen and (max-width: 1140px)
							text-align: center
						@media screen and (max-width: 500px)

							width: 100%

						.person_fullname
							width: 100%
							height: auto
							font-family: Jaapokki
							font-weight: bold
							color: dark-blue-color
							text-transform: uppercase
							margin-bottom: 8px
							clear: both

						.person_role
							width: 100%
							height: auto
							font-size: 12px
							line-height: 20px
							clear: both

			.small_map
				width: 570px
				height: 500px
				float: right
				@media screen and (max-width: 1140px)
					width: 100%
					float: none
					margin-bottom: 40px
					clear: both
