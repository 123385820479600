#presentations-popup
  position: absolute
  visibility: hidden
  width: 100%
  min-height: 100%
  top: 0
  left: 0
  background-color: rgba( 0 , 0 , 9, 0.88 )
  z-index: 999999

  .main-news-img
    background-color: #fff
    height: 550px
    width: 100%
    background-repeat: no-repeat
    background-size: contain
    background-position: center center

    @media screen and ( max-width: 1199px )
      height: 350px

    @media screen and ( max-width: 750px )
      height: 250px

  & > .container
    position: relative

    .content
      margin-top: 5%
      margin-bottom: 10%
      background-color: #ffffff
      overflow: hidden
      position: relative
      @media screen and (max-width: 992px)
        padding: 80px 0 50px
      @media screen and (max-width: 700px)
        padding: 80px 0 30px
      @media screen and (max-width: 470px)
        padding: 80px 0 15px

      &.newsletter-popup
        padding: 0 100px

        @media screen and (max-width: 992px)
          padding: 0 50px
        @media screen and (max-width: 700px)
          padding: 0 30px
        @media screen and (max-width: 470px)
          padding: 0 15px

      h1
        font-size: 24px
        color: dark-blue-color
        margin-top: 55px
        margin-bottom: 25px
        font-weight: bold

      .informations
        margin-bottom: 80px

      .container
        width: 100%

      .controls
        //margin-top: 50px
        .btnss
          z-index 500
          position absolute
          top 11px
          right 35px
          display flex
          @media screen and (max-width: 992px)
            position absolute
            right 0
            padding 0 10px
            height 60px
            background-color white
            text-align center
            width 100%
          //@media screen and (max-width: 605px)
          //@media screen and (max-width: 470px)
          .btnss-cont
            display flex
            flex-direction row
            @media screen and (max-width: 992px)
              height 50px
              display flex
              width 100%
            //@media screen and (max-width: 605px)
            //@media screen and (max-width: 470px)
            .hide-portfolio
              display block
              margin-left 5px
              //@media screen and (max-width: 992px)
              //@media screen and (max-width: 605px)
              //@media screen and (max-width: 470px)
            .nav-btns
              color #1D2944
              background-color white
              padding 12px
              cursor pointer
              display flex
              flex-direction row
              //justify-content center
              @media screen and (max-width: 992px)
                padding 15px 0 0 15px
                font-weight 500
                font-size 12px
                text-align center
              //@media screen and (max-width 605px)
              //@media screen and (max-width: 470px)
            .nextious
              width 50%
              border 1px solid #1D2944
              border-left none
            .previous
              border 1px solid #1D2944
              width 50%
            .close
              opacity 1
              width: 50px
              height: 46px
              background-color: rgba( 8 , 54 , 91 , 0.93 )
              @media screen and (max-width: 992px)
                height 50px
                width 60px
              //@media screen and (max-width: 605px)
              //@media screen and (max-width: 470px)
              img
                height 100%
                padding: 15px
                background-color #1D2944
                @media screen and (max-width: 992px)
                  height 100%
                  width 100%
                  padding 17px
                //@media screen and (max-width: 605px)
                //@media screen and (max-width: 470px)
            .close-wcag
              display: none

