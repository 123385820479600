html.normal
    font-size: 20px !important
html.plus
    font-size: 24px !important
html.plus-plus
    font-size: 28px !important
    
.no-skin
    background-color: #212121
    h1, h2, h3, h4, h5, h6, p, a, text
        color: #fdb81e
        font-size: 1rem
    p
        line-height: 1.4rem

    .svg,
    .svg * 
        fill: #fdb81e

    .btn
        color: #212121
        background: #fdb81e
        font-size: 1rem
        line-height: 1.4rem

    #winda
        display: none 

    header
        #logo
            width: 730px
        #top-info-wrapper
            width: calc(100% - 730px)
            .info-item
                color: #fdb81e
                border-color: #fdb81e
                &:first-child   
                    display: none
        .wcag-icon
            z-index 1000
            position: fixed
            right: 0
            top: 20vh
            display: block
            width: auto
            padding: 25px 15px
            background: #212121
            a
                padding: 10px
                border: 1px solid #fdb81e
    
    #site-preview
        background: #212121 !important
        #banner-blend
            background: #212121
        .header-txt
            height 400px
            top 200px!important
            bottom none
            h1, h2, p
                color: #fdb81e
            p
                font-size: 1rem
                line-height: 1.4rem
            &.home
                h1, h2
                    font-weight: 600
    #preview-menu-bar
        position: absolute !important
        bottom: 40px
        width: 100%
        #main-menu
            .nopadding
                display flex
                height 100%
                width auto
                min-height 150px
                padding 0
        nav
            border: 1px solid #fdb81e
            height 200px
            .menu-stick-right
                .btn#rezerwuj
                    padding 0 10px
            #mobile-menu-toggler
                .line
                    display:block
                    background-color: #fdb81e !important
            ul
                color #fdb81e !important
                padding 10px
                width 65%
                height 100%
                li
                    display flex
                    padding 0 !important
                    margin-right 0 !important
                    width 33.3%

                    a
                        padding 22px 0
                        span.active
                            display: none
                &.menu
                    display flex
                    flex-wrap wrap
                    width 100%
                    > li
                        &:hover > ul.submenu
                            //display block !important
                        > a
                            font-size: 1rem
                            color: #fdb81e
                            font-weight: 700
                            width: auto !important
                            span.active
                                display: none
                        ul.submenu
                            position absolute
                            display: flex
                            flex-direction row
                            flex-wrap wrap
                            width 200%
                            height auto
                            margin-left: 340px
                            padding: 0
                            > li
                                //&:nth-last-child(2)
                                //    margin-right: 0
                                > a
                                    font-size: 1rem
                                    color: #fdb81e
                                    width: auto !important
                                &.submenu-close
                                    width 775px
                                    color inherit
                                    font-size 22px
                                    display flex
                                    justify-content center
                                    font-weight 700
                            &.hidden_element
                                width 40%
                .submenued
                    display: block
                &.links
                    > li
                        display flex
                        justify-content center
                        align-items center
                        &.wcag
                            align-items center
                            justify-content center
                            height 100%
                            border-color: #fdb81e !important
                            //margin-right: 15px !important
                            color: #fdb81e
                            font-size: 1rem
                            width 70px
                            margin-right 0px!important
                            .svg,
                            .svg *
                                fill: #fdb81e !important
                                height: 23px !important
                        &.bilety
                            height 100%
                            width 130px
                            right 0
                            position absolute
                        &.elements
                            width 17%
                            height 50%
                        > a
                            font-size: 1rem
                            color: #fdb81e
                            font-weight: 400
                            width: auto !important

            .nav-list
                width 90%!important
            .menu-stick-right
                position: relative
                width 35%
                //@media( max-width: 1100px )
                //    position: absolute
                .links-nav
                    display flex
                    height 100%
                .links
                    flex-direction column
                    flex-wrap wrap
                    align-content flex-start
                    height auto
                    display flex
                    padding 0
                    margin 0
                    width 100%
                .btn#rezerwuj
                    position: absolute
                    top: 0
                    right: 0
                    font-size: 1rem
                    color: #212121
                    background: #fdb81e
                    display flex
                    height 100%
                    align-items center
    .amp            
        display: none
    #homepage
        #first-section
            background: #212121
            border-top: 1px solid #fdb81e
            .news
                .heading
                    margin-bottom: 30px
                    .title
                        color: #fdb81e
                        text-transform: uppercase
                    .lines
                        display: none
                    .show-all
                        color: #fdb81e
                        font-size: 0.8rem
                .items, .items.first-row
                    .item
                        .image
                            display: none
                        .text_side
                            padding: 50px
                            width: 100%
                            background: #212121
                            border: 1px solid #fdb81e
                            height: 400px
                            @media screen and (max-width: 770px)
                                height: 660px
                            h2, p, a
                                color: #fdb81e
                                font-size: 1rem
                                line-height: 1.4rem
                            a.btn
                                float: left
                                left: 50px
                                right: auto
                                color: #212121
                                background: #fdb81e
                                font-size: 1rem
                                line-height: 1.4rem
        #poznaj-centrum
            background: #212121
            height: auto
            .container
                padding: 0
            #poznaj-centrum-content
                width: 100%
                border: 1px solid #fdb81e
                margin: 0
                .text
                    h2, p
                        color: #fdb81e
                    p
                        font-size: 1rem
                        line-height: 1.4rem
                .read-more
                    float: left
                    color: #212121
                    background: #fdb81e
                    font-size: 1rem
                    line-height: 1.4rem
        #programy-preview
            .heading
                margin-bottom: 30px
                .title
                    color: #fdb81e
                    text-transform: uppercase
                .lines
                    display: none
                .show-all
                    color: #fdb81e
                    font-size: 0.8rem
        .right_side
            display: none
        .left_side
            width: 100%
            padding: 40px 0
            height: 330px
            @media screen and (max-width: 770px)
                padding: 40px 15px
                height: 650px
            h2, p
                color: #fdb81e
                font-size: 1rem
                line-height: 1.4rem
            a
                color: #212121
                background: #fdb81e
                margin-top: 40px
        #items
            .item:nth-child(even)
                .left_side
                    padding: 40px 0
                    height: 330px
                    @media screen and (max-width: 770px)
                        padding: 40px 15px
                        height: 900px
    #mecenat
        .galeria
            .galeria-opis
                display: block
        h1
            font-size: 1.4rem
        p
            font-size: 1rem
            line-height: 1.4rem
        .btn
            background: #212121
            border: 1px solid #fdb81e
            color: #fdb81e
            &:hover,
            &:active,
            &:focus,
            &:visited
                color: #fdb81e

    #newsletter
        background: #212121
        border-top: 1px solid #fdb81e
        border-bottom: 1px solid #fdb81e
        .container
            .row
                form
                    button,#newsletter-notification-open
                        background: #fdb81e!important
                        color: #212121!important
                        font-size: 1rem!important
                        line-height: 1.4rem!important
        .text
            h2, p
                color: #fdb81e
            p
                font-size: 1rem
                line-height: 1.4rem
        .form
            label
                color: #fdb81e
                font-size: 1rem
                line-height: 1.4rem
            input
                border: 1px solid #fdb81e
                background: #212121
                color: #fdb81e
                font-size: 1rem
                line-height: 1.4rem
                &::-webkit-input-placeholder
                    color: #fdb81e
                    font-size: 1rem
                &::-moz-placeholder
                    color: #fdb81e
                    font-size: 1rem
                &::-ms-input-placeholder
                    color: #fdb81e
                    font-size: 1rem
                &:-moz-placeholder
                    color: #fdb81e
                    font-size: 1rem
    #newsletter-notification
        .notification-container
            .notification-content
                .newsletter-popup-background
                    border none!important
                .newsletter-popup-content
                    background: #212121!important
                    border  1px solid #fdb81e!important
                    .button-container
                        .notification-message
                            h1
                                color: #fdb81e!important
                                font-size: 2.4rem!important
                                line-height: 1.4rem!important
                            p
                                color: #fdb81e!important
                                font-size: 1rem!important
                                line-height: 1.4rem!important
                        .notification-button
                            background: #212121!important
                            color: #fdb81e!important
                            font-size: 1.2rem!important
                            line-height: 1.4rem!important
                            border  1px solid #fdb81e!important
    #contact
        .company_place
            .first
                #first-map
                    display: none
                .heading
                    color: #fdb81e
                    font-size: 1rem
                    line-height: 1.4rem
                .informations_box
                    .item
                        color: #fdb81e
                        font-size: 1rem
                        line-height: 1.4rem
                        padding-right: 15px
                        word-break: break-word
                        width: 50%
                        .person_fullname
                            color: #fdb81e
                            font-size: 1rem
                            line-height: 1.4rem
                            word-break: break-word
                        .person_role
                            font-size: 0.95rem
                            line-height: 1.4rem
                            word-break: break-all
            .second
                #small-map
                    display: none
                .left_side
                    width: 100%
                    .heading
                        color: #fdb81e
                        font-size: 1rem
                        line-height: 1.4rem
                    .informations_box
                        justify-content: flex-start
                        .item
                            width: 50%
                            color: #fdb81e
                            font-size: 1rem
                            line-height: 1.4rem
                            margin-top: 50px
                            padding-right: 15px
                            &:nth-child(-n+2)
                                margin-top: 50px
                                padding-right: 15px
                            .person_fullname
                                color: #fdb81e
                                font-size: 1rem
                                line-height: 1.4rem
                                word-break: break-word
                            .person_role
                                font-size: 0.95rem
                                line-height: 1.4rem
                                word-break: break-all
    #aktualnosci
        .lista
            .item
                &:nth-of-type(2n - 1)
                    .text
                        padding: 0
                    @media screen and (max-width: 990px) 
                        padding: 0 25px
                > a
                    display: none
                .text
                    width: 100%
                    padding: 0
                    h2, a, p, a.tag, .read-more, .read-more:hover
                        color: #fdb81e
                        font-size: 1rem
                        line-height: 1.4rem
                @media screen and (max-width: 990px) 
                    padding: 0 25px 
        .load-more
            color: #fdb81e
            font-size: 1rem
            line-height: 1.4rem
    .informations
        .information_item
            color: #fdb81e
            border: 1px solid #fdb81e
            font-size: 1rem
            line-height: 1.4rem
            &.book-btn
                background: #212121
                a
                    color: #fdb81e
    #szablon
        .module
            background: #212121
            h1, h2, p, a
                color: #fdb81e
                font-size: 1rem
                line-height: 1.4rem
            .bg-tekst
                display: none
            &.obudowa:after
                width: 0
            .galeria-zdjec
                display: none
            #module2
                display: none
            #module3
                display: none
            #module5
                display: none
        &.programy
            .program
                background: #212121
                &:nth-child(2n)
                    background: #212121
    .zdjecie-i-tekst
        .zdjecie
            display: none
        .tekst
            width: 100%
            right: 0
    .zdjecie-przesuniete
        display: none
    .zdjecie-full
        display: none
    .galeria-osob
        .item
            img
                display: none
            p
                padding-right: 15px
                word-break: break-word
    #powiazane
        background: #212121
        #powiazane-wrapper
            .item
                border: 1px solid #fdb81e
                .name
                    color: #fdb81e
                    font-size: 1rem
                    line-height: 1.4rem
                .opis
                    color: #fdb81e
                    font-size: 1rem
                    line-height: 1.4rem
    #sticky-news-nav
        display: none
    footer
        #logos
            display: none
        #dark-ending
            #txts
                h3
                    font-size: 1rem
                    line-height: 1.4rem
        #scroll-up
            display: block
            position: fixed
            bottom: 0
            right: 5vw
            background: transparent
            border: none
            padding: 0
            z-index: 2
            outline: none
            visibility: hidden
            opacity: 0
            &.visible
                visibility: visible
                opacity: 1
    #mobile-menu-container
        background: #212121
        .mobile-menu-wrapper
            ul
                li
                    a
                        color: #fdb81e
                        &:before
                            display: none
                        &.active
                            color: #fdb81e
                        @media screen and (max-width: 1100px)
                            font-size: 1.5rem
            .links
                li
                    &.wcag
                        font-weight: bold
                        color: #fdb81e
                        font-size: 1.5rem !important
                    a
                        font-size: 1.5rem !important
        .mobile-menu
            li
                .submenued
                    display: none
                .submenu
                    margin: 0
                    display: block !important
                    li
                        margin-bottom: 30px
                        a
                            font-size: 1.5rem
                            color: #fdb81e
                            padding-left: 0
        .mobile-menu-close
            display: none
            &.close-wcag
                display: block
                width: 35px
    #search-zone
        background: #212121
        #search-form
            h1
                color: #fdb81e
            input
                color: #fdb81e
                font-size: 1rem
                line-height: 1.4rem
                border: 1px solid #fdb81e
                &::-webkit-input-placeholder
                    color: #fdb81e
                    font-size: 1rem
                &::-moz-placeholder
                    color: #fdb81e
                    font-size: 1rem
                &::-ms-input-placeholder
                    color: #fdb81e
                    font-size: 1rem
                &:-moz-placeholder
                    color: #fdb81e
                    font-size: 1rem
             button
                background: #fdb81e
                color: #212121
                font-size: 1rem
                border: 1px solid #fdb81e
                margin-top: -7px
        .results
            h2
                font-size: 1rem
                line-height: 1.4rem
                color: #fdb81e
            h1
                font-size: 5rem
                color: #fdb81e
            ul
                li
                    a
                        background: #fdb81e
                        color: #212121
                        font-size: 1rem
                    &:before
                        display: none
        .search-close
            display: none
        .close-wcag
            display: block
            position: absolute
            top: 50px
            right: 50px
            cursor: pointer
            width: 45px
            height: 45px
    #news-popup
        background: #212121
        > .container
            .content
                background: #212121
                h1
                    color: #fdb81e
                .controls
                    .close
                        display: none
                        img
                            padding: 0
                    .close-wcag
                        display: block
                        position: absolute
                        right: 0
                        top: 0
                        width: 45px
                        height: 45px
                        opacity: 1
                        cursor: pointer
                        background: #212121
                    .nav-btn
                        border: 1px solid #fdb81e
                        color: #fdb81e
        .main-news-img
            display: none
            //PRESENTATIONS-NOSKIN
    #prezentacje, #sticky-news-nav
        background-color #212121 !important
        color: #fdb81e
        padding-left 0
        .presentations-filtration-list
            background-color #212121 !important
            color: #fdb81e
        .filter
            font-size 1rem
        .list p, a
            background-color #212121 !important
            color: #fdb81e
        &:hover p, a
            color: #fdb81e!important
        .selected-filter-item
            color: #fdb81e!important
        #show-all
            color: #fdb81e!important
        .presentation-item
            font-size inherit
            border: 1px solid #fdb81e
            .category-box
                line-height 1.4rem
                font-size 1rem
            .short-desc , p , a
                line-height 1.4rem
                font-size 1rem
                color: #fdb81e!important
                &:hover p, a
                    color: #fdb81e!important
            .artist-list-photo
                line-height 1.4rem
                font-size 1rem
                display none !important
            .name
                line-height 1.6rem
                font-size 1.4rem
                color: #fdb81e!important
                &:hover p, a
                    color: #fdb81e!important
            .presentation-category
                line-height 1.4rem
                font-size 1rem
                background-color #fdb81e!important
                color: #212121!important
        #loading
            visibility hidden
    #footer
        color #fdb81e!important
        background-color #212121!important
        #dark-ending
            color #fdb81e!important
            background-color #212121!important
    #photo-modal
        //display none
    #text-space
        background #212121!important
        color #fdb81e!important
        .main-presentations-header, .name-presentations, .surname-presentations, .category, .gallery-media-container, .long-description, .film-container
            color #fdb81e!important
        .main-presentations-header
            background-color #212121!important
            .name-float
                border-color #fdb81e!important
                color #fdb81e!important
                background-color #212121!important
                .name-nav
                    color #fdb81e!important
                    background-color #212121!important
    #topControls
        color #fdb81e!important
        background-color #212121!important
        .btnss
            color #fdb81e!important
            background-color #212121!important
        .nav-btns
            border-color #fdb81e!important
            color #fdb81e!important
            background-color #212121!important
            .previous
                color #fdb81e!important
                background-color #212121!important
            .nextious
                color #fdb81e!important
                background-color #212121!important
    .close
        color #fdb81e!important
        background-color #212121!important
        img
            padding 10px !important
            background-color #fdb81e!important
            fill #212121!important
        .close-wcag
            color #fdb81e!important
            background-color #212121!important
    #homepage
        #poznaj-centrum
            .text
                background-color #212121!important
    #emanacje
        .load-more
            color #fdb81e!important
        .emanation-item
            border: 1px solid #fdb81e!important
        .category-box
            color: #212121!important
            .category-gray
                background-color #fdb81e!important
            .category-yellow
                background-color #fdb81e!important
            .category-black
                background-color #fdb81e!important
                color: #212121!important
            .category-blue
                background-color #fdb81e!important
                color: #212121!important
            .category-light-blue
                background-color #fdb81e!important
                color: #212121!important
        .short-content
            .date
                color #fdb81e!important
            a.tag
                color #fdb81e!important
            .bottom-box
                padding-left 0!important
                .read-more
                    padding-left 0!important
                    color #fdb81e!important
            .title
                a
                    color #fdb81e!important
            .short-desc
                p
                    color #fdb81e!important
                .read-more
                    padding-left 0!important

                    color #fdb81e!important
        #sticky-news-nav.emanations-nav
            z-index 100
            #show-all
                color #fdb81e!important
        #emanation-filter-list
            .selected-filter-item
                color #fdb81e!important
        .list
            background-color: #fff
            span
                a
                    color #fdb81e!important
        .emanation-popup
            background-color #fdb81e!important
            .container .content .controls .close
                background-color #fdb81e!important
        #szablon
            .tekst
                color #fdb81e!important
            .tekst-container
                blockquote
                    border-left-color #fdb81e!important
    ul
        color #fdb81e !important
    .animation-control
        display none !important
    #first-paragraph
        .buy-ticket
            border 1px #fdb81e solid !important
            background-color: #212121!important
            color #fdb81e!important
    #site-preview
        filter none!important
        background-image none!important
        -webkit-filter none!important
    .item-container
        .item
            filter none!important
    .gallery-btns
        #next-modal,#prev-modal
            background-color: #212121!important
    .visible-Btn
        background #212121!important

    #condolences
        .condolences__header, .single-condolence, .date, .freeform-label
            color: #fdb81e !important
        button
            background: #fdb81e !important
            color: #212121 !important
        .pagination
            a, span
                color: #fdb81e !important