gray-background = #eeeeee

.notification-form
    background-color: gray-background
    padding-bottom: 80px

    @media( max-width: 768px )
        padding-bottom: 60px

    .tab
        padding-top: 80px

        @media(max-width: 768px)
            padding-top: 60px

        h3
            font-weight: bold
            font-size: 14px
            margin-bottom 40px

    header
        position: static
        color: #000
        margin-bottom: 20px

        h3
            margin-bottom: 5px !important

    label
        font-weight: normal

    .fields
        .tab
            .field
                display: flex
                margin-bottom: 24px
                flex-direction column
                flex-wrap: wrap

                a
                    color: #2aace8;

                .heading
                    legend,
                    label
                        font-weight 700;
                        font-size: 14px;
                        color: #000;
                        display: block;
                        margin-bottom 0
                        border-bottom: none

                    .instructions
                        font-size: 14px;
                        line-height: 28px;
                        color: #131f3c;


                .input
                    input, textarea, select
                        margin: 10px 0 15px
                        border: none
                        padding: 13px 30px
                        outline: 0
                        width: 100%
                        &:focus
                            outline: none
                    textarea
                        height: 209px
                    label
                        display: block
                        margin-bottom: 15px
                    input[type=checkbox],
                    input[type=radio]
                        width: auto

                &.fileupload
                    input
                        margin-left: 0
                        padding-left 0
                    ::-webkit-file-upload-button
                        padding: 0 30px
                        line-height: 44px
                        color: #fff
                        font-weight: bold
                        background-color: #000
                        text-transform: uppercase
                        border: none
                        &:focus
                            outline: none

                &.phone
                    .input
                        > div
                            display flex
                            flex-direction column

                            select
                                margin-bottom 0

                            @media( min-width: 600px )
                                display: flex
                                flex-direction: row

                                select
                                    width: 216px
                                    margin-right 30px
                                    margin-bottom 15px

                ul.errors
                    margin: 10px 0
                    padding: 0
                    padding-left: 216px
                    list-style-type: none
                    color: #ff4949

                &.multiplechoice
                    .input
                        padding-left 30px
                        position relative
                        input
                            position absolute
                            left: 0
                            margin: 0
                            width: 1px
                            background-color transparent

                            &::before
                                content: ""
                                display: inline-block
                                min-width: 21px
                                height: 21px
                                background-color: white
                                margin-right: 6px
                                left: 0
                                top: 0
                                border-radius: 50%
                                position: absolute

                            &:checked
                                background-color transparent
                                &::after
                                    content: ""
                                    position: absolute
                                    left: 6px
                                    top: 6px
                                    width: 9px
                                    height: 9px
                                    background-color: black
                                    border-radius: 50%
                &.optin
                    .input
                        padding-left 30px
                        position relative
                        input
                            margin: 0
                            position absolute
                            left: 0
                            margin: 0
                            width: 1px
                            background-color transparent
                            &::before
                                content: ""
                                display: inline-block
                                min-width: 20px
                                height: 20px
                                background: white
                                margin-right: 4px
                                border-radius: 4px
                                position: absolute
                                left: 0
                                top: 0
                            &:checked
                                background-color transparent
                                &::after
                                    content:""
                                    position: absolute
                                    left: 7px
                                    top: 4px
                                    width: 6px
                                    height: 10px
                                    border: solid black
                                    border-width: 0 2px 2px 0
                                    transform: rotate(45deg)


                .sproutcheckboxes
                    input
                        opacity: 0
                        position: absolute
                    span
                        position relative
                        margin-right: 40px
                        &:before
                            content: ""
                            display: inline-block
                            min-width: 20px
                            height: 20px
                            background: white
                            margin-right: 4px
                            border-radius: 4px
                            position: absolute
                    :checked + span::after
                        content:""
                        position: absolute
                        left: 7px
                        top: 3px
                        width: 6px
                        height: 10px
                        border: solid black
                        border-width: 0 2px 2px 0
                        transform: rotate(45deg)


                .file-container
                    input[type="file"]
                        position: absolute
                        opacity 0
                        top: 0
                    label
                        background-color: white
                        display: inline-block
                        position relative

                    .reset-file
                        margin-left: 55px
                        background-color: transparent
                        border: none
                        font-size: 12px
                        font-weight: bold
                        &:focus
                            outline: none

    .consent
        margin-bottom: 60px
        p
            font-size: 12px
        @media(min-width: 1024px)
            margin-left: 216px

    .submit
        button
            padding: 0 30px
            line-height: 44px
            color: #fff
            font-weight: bold
            background-color: #000
            text-transform: uppercase
            border: none
            &:focus
                outline: none


.my-select

    ul>li
        padding: 0


.my-select-button.ui-button
    width: 100%
    border: none
    background: white
    margin-right: 0
    max-width: 412px
    border-bottom: 1px solid #eee
    &:focus
        outline: none
        color: black

.my-select-button.ui-corner-top
    border-top-right-radius: 0px
    border-top-left-radius: 0px


.my-select-button
    .ui-icon
        background-image: url(../img/arrow.png)
    .ui-icon-triangle-1-s
        background-position: 5px 6px

.my-select-button
    &:active
        .ui-icon
            background-image: url(../img/arrow.png)
.my-select-button
    &:hover
        .ui-icon
            background-image: url(../img/arrow.png)
.my-select-button
    &:focus
        .ui-icon
            background-image: url(../img/arrow.png)

.my-select-menu
    .ui-widget.ui-widget-content
        border: 1px solid white
        border-bottom-right-radius: 0px
        border-bottom-left-radius: 0px

.my-select-menu ul>li
    padding-left: 12px
    line-height: normal
    &:before
        content: none

// .my-select-menu .ui-menu .ui-menu-item
//     color: yellow

.my-select-menu
    .ui-state-active, .ui-widget-content .ui-state-active
        color: #63a4d9
        background-color: transparent
        border: none
    .ui-menu .ui-state-active
        margin: 0
    .ui-menu .ui-menu-item-wrapper
        padding-left:0
// #footer
// 	display grid
.notification-form
    form
		.freeform-row
			button
				padding: 0 30px !important
				line-height: 44px !important
				color: #fff !important
				font-weight: bold !important
				background-color: #000 !important
				text-transform: uppercase !important
				border: none !important
				width 200px !important
			.freeform-column
                display: flex
                margin-bottom: 24px
                flex-direction column
                flex-wrap: wrap
				a
                    color: #2aace8;
                .freeform-instructions
                    color: #363636 !important;
				.freeform-label
					font-weight 700;
					font-size: 14px !important;
					color: #000;
					display: block;
					margin-bottom 0
					border-bottom: none


				.instructions
					font-size: 14px;
					line-height: 28px;
					color: #131f3c;
				input[type=radio]
					background-color: red !important
				.freeform-input , textarea, select
					margin: 10px 0 15px
					border: none
					padding: 13px 30px
					outline: 0
					width: 100%
				textarea
					height: 209px
				label
					display: block
					margin-bottom: 15px
				input[type=checkbox],
				input[type=radio]
					width auto
					margin-right: 10px !important

.custom-form-error
    font-size: 12px
    line-height: 14px
    color: rgba(255, 0, 0, 0.8)
    font-weight: 400
    margin-right: 5px
    right: 0px

.custom-form-error-submit
    font-size: 12px
    line-height: 14px
    color: rgba(255, 0, 0, 0.8)
    font-weight: 400
    margin-right: 5px
    right: 0px
    margin-top: 5px;

#freeform-popup
	position: absolute
	display: none
	width: 100%
	min-height: 100%
	top: 0
	left: 0
	background-color: rgba( 0 , 0 , 9, 0.88 )
	z-index: 999999

	.freeform-content
		max-width: 500px;
		text-align: center;
		margin: 0 auto;

	& > .container
		position: relative

		.content
			margin-top: 5%
			margin-bottom: 10%
			padding: 0 100px 100px
			background-color: #ffffff
			overflow: hidden
			position: relative
			@media screen and (max-width: 992px)
				padding: 80px 50px 50px
			@media screen and (max-width: 700px)
				padding: 80px 30px 30px
			@media screen and (max-width: 470px)
				padding: 80px 15px 15px

			&.newsletter-popup
				padding: 0 100px

				@media screen and (max-width: 992px)
					padding: 0 50px
				@media screen and (max-width: 700px)
					padding: 0 30px
				@media screen and (max-width: 470px)
					padding: 0 15px

			h1
				font-size: 24px
				color: dark-blue-color
				margin-top: 55px
				margin-bottom: 25px
				font-weight: bold

			.container
				width: 100%

			.controls
				margin-top: 50px

				.close
					position: absolute
					right: 0
					top: 0
					width: 45px
					height: 45px
					background-color: rgba( 8 , 54 , 91 , 0.93 )
					opacity: 1

					img
						padding: 10px
				.close-wcag
					display: none
