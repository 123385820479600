#powiazane
	background-color: #f8f8f8
	
	#powiazane-wrapper	
		padding: 80px 0
		.amp.top 
			@media( max-width: 1023px )
				display: none
			
		.item
			width: 250px
			border: 1px solid dark-blue-color
			display: flex
			flex-direction: column
			text-decoration: none
			margin: 0 auto 15px auto
			@media screen and ( max-width: 1200px ) and ( min-width: 992px )
				width: 215px
		
			.name
				font-family: 'Jaapokki'
				font-size: 14
				color: dark-blue-color
				margin: 25px auto
				display: block
				text-align: center
				text-transform: uppercase
				
			.opis
				font-size: 12px
				color: dark-blue-color
				padding: 0 45px 30px
				text-align: center