.popup-form
    background-color: rgba( 0 , 92 , 156, 0.88 )
    position: fixed
    left: 0
    top: 0
    width: 100%
    height 100%
    z-index: 1
    padding: 15px
    display: none

    .popup
        max-width: 626px
        width: 100%
        margin: 0 auto
        background-color: white
        margin-top: 5%
        display: block
        padding: 30px
        @media(max-width:767px)
            padding: 15px

        p
            margin: 0

        .content
            padding: 7%

            .header
                margin-bottom: 40px
                p
                    font-weight: bold
                    font-family: 'Jaapokki'
                    font-size: 20px
                @media(max-width:767px)
                    font-size: 14px

            .paragraph
                    margin-bottom: 50px
                p
                    line-height: 23px

            button
                border none
                background-color: #eeeeee
                padding: 5px 20px
                &:focus
                    outline: none
