.amp
	transition 0.5s
	position: absolute
	opacity 1
	top: 0
	left: 0
	width: 100%
	height: 100%

	&.top

		.ampBar
			top: 0

	&.bottom

		.ampBar
			bottom: 0

	.ampBar
		position: absolute
		width: 1px
