#preview-menu-bar
	position: absolute
	bottom: 55px
	width: 100%
	&.affix-top
		nav
			background-color: rgba(0,0,0,0.3)

	nav
		width: 90%
		margin: 0 auto
		border: 1px solid #fff
		height: 50px
		position: relative

		@media( max-width: 500px )
			height: 30px
		
		ul
			display: inline-block
			list-style-type: none
			padding: 0
			margin-left: 15px
			@media( max-width: 500px )
				display: none
				//margin-right 15px!important
			@media( max-width: 1400px )
				display: none

			li
				text-align center
				display: inline-block
				margin: 0 4.5px
				position: relative

				@media(max-width:1400px)
					margin-right: 7px
				&:last-child
					margin-right: 0

				.ui-effects-placeholder
					margin-top: 30px !important
					height: 0px !important
					visibility: visible !important

				&.wcag
					height 48px
					cursor: pointer
					margin-right: 15px!important
					padding: 0px 8px
					border-left: 1px solid #fff
					border-right: 1px solid #fff
					color: #fff
					font-size: 14px
					font-weight: bold
				&.bilety
					height 100%
					

				&.menu-right-svg-ico 
					a
						display: inline-block
						width: 20px !important
						text-align: center
						
						svg
							height: 15px
							fill: #fff
							&.icon--accessibility
								height: 17px
								transform: translateY(-1px)
								fill: transparent
				
				a
					line-height: 14px
					padding: 17px 0
					color: #fff
					display: inline-block
					text-decoration: none
					transition: all 0.2s ease
					white-space: nowrap;
					@media( max-width: 500px )
						padding 8px 0

					&.active,
					&.show-submenu
						font-weight: bold
						
					span.active
						content: ""
						display: block
						position: absolute
						width: 17px
						height: 1px
						background-color: hover-blue
						margin: 6px calc(50% - 13px) 0


			&.submenu
				margin-left: 5px
				display: block
				float: right

				li

					&:last-child
						margin-right: 0

					&:hover

						> a
							color: #fff
							// color: hover-blue

		ul

			&.menu
				> li

					> a
						font-size: 14px
						
						&.show-submenu
							&::after
								content: ":"
								display: inline


					ul.submenu
						padding-left: 5px

						li

							a

								font-size: 11px
								// color: hover-blue
								color: #FFF
								//width 60px !important
								font-weight: 700
								
							&.submenu-close

								color: #fff
								cursor: pointer

			&.links
				padding-right: 0
				display: flex
				float: left
				flex-direction: row
				align-items: center

				li
					margin-right: 4px
					height 48px

					@media( max-width: 504px )
						display: none
					
						&.wcag
							display: block
							margin-top: 10px
							margin-right: -10px !important
							border: none
						&.bilety
							display: block


					@media( max-width: 500px )
						&.wcag
							margin-top: 0

					a
						font-size: 11px
					&.menu-right-svg-ico
						margin-right 7px!important
					&.elements
						padding 0 4px
					&.bilety
						margin 0 !important
						margin-left 15px!important
						
		.menu-stick-right
			position: absolute
			right: 0
			top: 0
			.btn#rezerwuj
				font-size 14px
				margin 0 !important
				padding: 0 30px
				width auto !important
				line-height: 47px
				background-color: #fff
				border-radius: 0
				text-decoration: none
				color: dark-blue-color
				font-weight: 800
				letter-spacing: -1px
				text-transform: uppercase

				
				&:active
					box-shadow: none

				@media( max-width: 500px )
					line-height: 27px
					padding: 0 20px
					font-size: 12px
				
		#mobile-menu-toggler
			cursor: pointer
			display: none
			padding-left: 15px

			@media( max-width: 1400px )
				display: inline-block
			
			.line
				display:block
				width: 11px
				height: 1px
				background-color: #fff
				margin-bottom: 10px
				
				@media( max-width: 500px )
					margin-bottom: 7px
				
				&:first-child
					margin-top: 10px
					width: 26px
					
					@media( max-width: 500px )
						margin-top: 3px
					
				&:last-child
					width: 21px
		

		#sticky-menu-logo
			display: none
					
	&.affix
		position: fixed
		top: 0
		bottom: initial
		z-index: 99999
		height: 52px
		background-color: #fff
		border-bottom: 1px solid #dddddd

		@media( max-width: 500px )
			height: 33px
		
		nav
			#sticky-menu-logo
				display: block
				
				@media( max-width: 1500px )
					display: none
					
				img
					position: absolute
					width: 60px
					margin: 13px 0
					
			#mobile-menu-toggler
				.line
					display:block
					background-color: dark-blue-color
			ul.menu
				margin-left 5vw
				li
					a
						color: dark-blue-color


					ul.submenu
						li
							a
								color: dark-blue-color
						li.submenu-close
								color: dark-blue-color

		.menu-stick-right

			ul 
				li

					&.wcag
						border-color: dark-blue-color
						color: dark-blue-color

					a
						color: dark-blue-color 
							
					&.menu-right-svg-ico 
						a							
							svg
								fill: dark-blue-color
								&.icon--accessibility
									fill: transparent
									path#human
										fill: dark-blue-color
									path#outline
										stroke: dark-blue-color
						
			.btn#rezerwuj
				background-color: dark-blue-color
				color: #fff
				line-height: 48px
				
				@media( max-width: 500px )
					line-height: 30px
					
#mobile-menu-container
	position: fixed
	width: 100%
	height: 100%
	background-color: rgba( 0 , 92 , 156 , 0.9 )
	z-index: 999999
	padding: 15% 10%
	display: none
	@media screen and (max-width: 1400px) and (orientation: landscape)
		padding: 50px

	@media( max-width: 500px )
		padding: 15% 5%
	
	.mobile-menu-wrapper
		position: absolute
		height: 80%
		overflow-y: auto

		ul
			list-style-type: none
			padding: 0
			
			li
				a
					font-size: 30px
					color: #fff
					text-decoration: none
					
					@media( max-width: 500px )
						font-size: 22px
					
					//&::before
					//	content: "—"
					//	color: hover-blue
					//	padding-right: 15px
					//	opacity: 0
						
					&.active
						font-weight: bold
						color: hover-blue

						&::before
							opacity: 1
					
					&:hover
						font-weight: bold
						text-decoration: none

				&.wcag
					display: none

				&.menu-right-svg-ico
					padding-left: 10px

					&.search
						//padding-left: 37px

					a
						&::before
							display: none
						svg
							height: 20px
							fill: #fff
							&.icon--accessibility
								fill: none

	.mobile-menu
		display: block

		li
			margin-bottom: 30px
			
			.submenu
				margin: 65px 0
				
				li
					margin-bottom: 20px
					
					a
						color: hover-blue
						font-size: 16px
						padding-left: 15px

						@media( max-width: 500px )
							font-size: 10px
				
				.submenu-close
					display: none
			
	.links
		li
			display: inline-block
			float: left
			padding-bottom: 10px
			padding-right: 15px
			padding-left: 15px

			@media( max-width: 800px )
				a
					font-size: 22px !important
			@media( max-width: 550px )
				a
					font-size: 16px !important
			@media( max-width: 450px )
				a
					font-size: 13px !important
			@media( max-width: 400px )
				padding-right: 10px
				a
					font-size: 11px !important
			
			@media( max-width: 500px )
				padding-right: 10px !important
				padding-left: 10px !important
				
	#rezerwuj
		display: none
		
	
	.mobile-menu-close
		position: absolute
		top: 3%
		right: 3%
		cursor: pointer
		&.close-wcag
			display: none
.preventer
	overflow-y scroll!important
.preventer-second
	display none!important