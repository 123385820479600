#site-preview
	background-size: cover
	background-repeat: no-repeat
	background-position: center
	position: relative

	#mute
		position: absolute
		z-index: 999
		top: -70px
		right: 0
		background: 0 0
		border: 0px solid #fff
		color:#fff
		outline:none
		padding:12px

		@media screen and (max-width: 500px)
			right: 0

		img
			width:24px
			height:24px
			display:none
		.active
			display:block



	.header-txt
		position: absolute
		left: calc( 50% - 585px )
		width: 1170px
		bottom: 210px

		@media screen and (max-width: 1400px)
			width: 90%
			left: 5%

		@media screen and (max-width: 650px)
			bottom: 120px

		p
			font-size: 18px
			color: #fff
			line-height: 30px
			width: 83.33333%
			@media screen and (max-width: 1023px)
				width: 100%
			@media screen and (max-width: 650px)
				display: none

		h1
			font-family: 'Jaapokki'
			font-size: 91px
			color: #fff
			margin-top: 0
			margin-left: -4px

			@media( max-width: 1400px )
				font-size: 65px;

			@media( max-width: 1000px )
				font-size: 55px;

			@media( max-width: 550px )
				font-size: 40px;
		h2
			font-size: 69px
			font-weight: 300
			color: #fff

			@media( max-width: 1400px )
				font-size: 50px;

			@media( max-width: 1000px )
				font-size: 40px;

			@media( max-width: 550px )
				font-size: 25px;

		@media screen and (max-width: 1000px) and (orientation: landscape)
			top: 30%
			h1
				font-size: 50px
			p
				font-size: 14px
				line-height: 20px
		&.home
			left: calc( 50% - 650px )
			width: 1300px
			top: 40%
			text-align: center

			@media( max-width: 1400px )
				left: 50px
				width: calc( 100% - 100px )
			h2
				font-size: 48px

				@media( max-width: 1400px )
					font-size: 40px;

				@media( max-width: 1000px )
					font-size: 30px;

				@media( max-width: 550px )
					font-size: 25px;
					
				@media( max-width: 400px )
					font-size: 20px;
			h1,h2
				text-align: center
				text-transform: uppercase
				letter-spacing: 1.2px
				font-weight: 300
				font-family: 'Open Sans', sans-serif;
			h1
				font-size: 79px
				@media( max-width: 1400px )
					font-size: 65px;

				@media( max-width: 1000px )
					font-size: 55px;

				@media( max-width: 550px )
					font-size: 40px;
					
				@media( max-width: 400px )
					font-size: 28px;


	#heder-amp-contianer
		position: absolute
		height: calc( 100% - 140px )
		width: 100%
		bottom: 140px
		left: 0

		@media( max-width: 1023px )
			display: none

		> div
			height: 100%

			@media ( max-width: 1400px )
				width: 90%

	#banner-blend
		position:absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		opacity: 0.66
		background: url('../img/home-banner-blend.png') center bottom repeat-x


	#video-container
		position:absolute
		top:0
		left:0
		width:100%
		height:100%
		z-index: 0
		&:after
			width:100%
			height:100%
			top:0
			left:0
			background-color:rgba(0, 0, 0, 0.05)
			content:''
			position:absolute
			z-index:1

		video
			object-fit: cover
			width:100%
			height:100%
			position: absolute
			top: 0
			left: 0
			z-index:1









