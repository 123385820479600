.galeria-osob
	display: flex
	flex-direction: row
	flex-wrap: wrap

	.item
		width: 25%
		padding: 0
		text-align: center
		margin-bottom: 35px
		@media screen and (max-width: 740px)
			width: 33.333%
		@media screen and (max-width: 500px)
			width: 50%

		img
			width: 90%

		&:nth-child( 4n )
			padding-right: 0

		.opis
			width: 100%
			font-size: 14px
			font-weight: 700
			color: dark-blue-color
			text-align: center
			margin-top: 15px
