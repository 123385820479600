.zdjecie-full

	.bg-tekst
		z-index:1
		color: #95989c !important
		opacity: 0.18 !important

		&.left, &.right
			top: -40px !important

	.zdjecie-container
		height: 560px
		background-size: cover
		background-repeat: no-repeat
		position: relative

		.txt
			position: absolute
			width: 800px
			top: 40%
			left: calc( 50% - 400px )
			@media screen and (max-width: 860px)
				width: auto
				left: auto
			@media screen and (max-width: 500px)
				top: 30%


			&, p
				font-size: 24px !important
				text-align: center
				color: #fff !important
				line-height: 35px !important
				@media screen and (max-width: 860px)
					padding-left: 15px
					padding-right: 15px

			blockquote
				border: none
				text-align: right
				padding: 0
				margin: 0
				font-size: 14px
				font-style: italic
				@media screen and (max-width: 860px)
					padding-right: 15px
