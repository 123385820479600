.programy
	.program
		padding-bottom: 1px
		padding-top: 80px

		.module
			margin-top: 0 !important
			background-color: transparent !important

		&:nth-child( 2n )
			background-color: #eeeeee
