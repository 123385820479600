
#prezentacje
  transition 0.5s
  background-color: white
  margin-top: 70px
  > #loading
    display: none !important
  #loading
    padding: 80px 0
  .noDisplay
    display none
  .load-more
    position absolute
    bottom -100px
    left 50%
    transform translateX(-50%)
    color #1D2944
    font-weight 700
    font-size 14px
    text-transform lowercase
    text-align center
    text-decoration none
.presentations-list
  margin-top: 70px
  height: 100vw

.presentation-item
  padding: 14px
  transition 0.5s
  font-family: Open Sans
  border: 1px solid #D0D0D0
  display: flex
  flex-direction: column
  height: fit-content
  white-space: normal
  &:hover
    .artist-list-photo
      overflow: hidden
      .list-photo
        transform scale(1.1)
    .name-container
      .name
        text-decoration none
        color #0B7FC7
  .artist-list-photo
    text-align center !important
    width 100%
    overflow hidden
    .list-photo
      height 200px
      width fit-content
      object-fit cover
      transition .5s ease
  .category-box
    text-align: center
    font-size: 12px
    color: #1D2944
    font-weight: 600
    text-transform: lowercase
  .category-creamy-yellow
    background-color #C1904D!important
    padding 5px
    color white!important
  .category-light-blue
    background-color #9CE5E8!important
    padding 5px
    color #1D2944!important
  .category-light-yellow
    background-color #FFDA77!important
    padding 5px
    color #1D2944!important
  .category-sea-blue
    background-color #3C479B!important
    padding 5px
    color white!important
  .short-content
    a.tag
      font-size: 14px
      text-transform: lowercase
      pointer-events: none
      font-weight 500
      color black
      font-style normal
    .name-container
      margin 25px 0 15px 0
      .name
        word-spacing 100vw
        word-break break-word
        font-weight 700
        font-size 25px
    .short-desc
      p
        color: #1D2944
        font-size: 12px
        font-weight: 600
        line-height: 28px
        text-underline none
    .bottom-box
      text-align end
      .read-more
        color: #137EB0
        font-size: 12px
        font-weight: bold
        font-style: italic
        cursor: pointer

#sticky-news-nav.presentations-nav
  z-index 100
  top: 0
  left: 50%
  transform: translateX(-50%);
  width: 90%
  height: 70px
  display: flex
  justify-content space-between
  padding: 0
  #show-all
    display none
    white-space nowrap
    align-items center
    position relative
    font-weight 800
    color #1D2944
    margin-right 20px
    &.show-all-icon
      &::before, &:after
        position absolute
        top:calc(50% -2px)
        right -12px
        content ''
        height 8px
        width 2px
        background-color #1D2944
        border-radius 2px
      &:before
        transform rotate(45deg)
      &:after
        transform rotate(-45deg)
  .select-box
    width: 15%

    .presentations-filtration-list
      width: 100vw
      position: absolute
      left: 0
	  margin-left: -1px

  .filter
    padding-bottom 15px
    margin-top 15px
    background-color transparent
    border-color transparent
    display: flex
    align-items: center
    font-weight: bold
    text-transform: uppercase
    padding-top: 6px
    cursor: pointer

    .arrow
      margin-left: 5px
  #presentation-filter-list
    width 85%
    display flex
    align-items center
    .selected-filter-item
      margin-right 35px
      font-size 14px
      position relative
      color #1D2944
      span
        font-size 11px
        position absolute
        right -10px
        top 2px
        font-weight bold
        cursor pointer
  .list
    background-color #fff
    padding-top 0
    display flex
    span
      display block
      padding-left 2px
      padding-bottom 10px
      text-transform uppercase
      font-weight 800
    span-not(first-child)
      padding-top 10px
    ul li
      text-transform lowercase
      padding-left 2px
      font-size 12px
      &.active
        font-weight normal
        text-decoration underline
        &::before
          display none
      a
        color dark-blue-color
        text-decoration none
        &:hover
          font-weight normal
          text-decoration underline
    .list-box
      margin-right 10px

.presentations-grid
  width: 90%
  margin: 0 auto 150px
  .tags
    display flex
    background-color yellow
    justify-content center
    align-items center
    height 30px
.grid-item
  width: 25%
  @media(min-width:1600px)
    width: 20%
  @media(max-width:1000px)
    width: 33%
  @media(max-width:700px)
    width: 50%
  @media(max-width:500px)
    width: 80%
    margin-left:10%
  @media(max-width:400px)
    width: 100%
    margin-left:0

.visible
  display: flex !important

.presentation-popup
  position fixed !important
  background-color: rgba(0,0,0,.88) !important
  visibility: visible !important
  .container
    padding 0!important
    margin 0 !important
    width 100vw
    height 100vh
  .content
    margin 0 !important
  .container .content .controls .close
    background-color: rgba(0,0,0,.78) !important

#szablon.presentation
  .date
    margin-top: 40px
    font-family: Open Sans
    font-size: 12px
    font-weight: 700
  h1
    margin-top: 20px !important
    margin-bottom: 10px !important
#first-paragraph
  padding: 0
.container-modal
  height 100vh
  width 100%
  display flex
  flex-direction row
  .artist-photo
    background-color black
    z-index 600
    position absolute
    height 100%
    width 50vw
    text-align center
    transition 0.5s
    @media screen and (max-width: 992px)
      display none
    .photos
      display: none
      transition 0.5s
      background-color black
      height 100%
      object-fit cover
      max-width 100%
      margin 0 auto
      &.visible-photo
        display: block
        text-align: center;
        -webkit-transition: opacity 2s ease-in;
        -moz-transition: opacity 2s ease-in;
        -o-transition: opacity 2s ease-in;
        -ms-transition: opacity 2s ease-in;
        transition: opacity 2s ease-in;
  .gallery-btns
    bottom 15px
    z-index 700
    display flex
    margin-left 30px
    position absolute
    button
      border-width 1px !important
      border-style solid !important
      border-color white !important
      box-shadow none !important
      &.gallery-btn-next
        border-color white
        background-color transparent
        padding 12px 15px
      &.gallery-btn-prev
        border-color white
        background-color transparent
        padding 12px 15px
      &.gallery-btn-close
        cursor default
        transition 0.5s
        border-color white
        background-color transparent
        padding 12px 15px
        opacity 0
  .content-presentations
    margin-left 50vw
    overflow auto
    width 50%
    font-family "Open Sans"
    padding 65px 45px 0
    @media screen and (max-width: 992px)
      margin 0
      padding 0
      display flex
      flex-direction column
      width 100%
    //@media screen and (max-width: 605px)
    //@media screen and (max-width: 470px)
    .main-presentations-header
      display none
      z-index 500
      position absolute
      height 70px
      background-color white
      margin-left -25px
      top 0
      width 45%
      @media screen and (max-width: 1370px)
        width 43%
      @media screen and (max-width: 992px)
        z-index 550
        position absolute
        height 60px
        left 20px
        background-color white
        top 60px
        width 101vw

      .name-float
        border 1px solid #1D2944
        position absolute
        left 0
        top 11px
        width 100%
        @media screen and (max-width: 992px)
          display inline-block
          position relative
          top 0
          width calc(100% - 30px)
        .name-nav
          background-color white
          font-weight 700
          color #1D2944
          padding 12px 0 12px 10px
          @media screen and (max-width: 992px)
            font-size 12px
            padding 12px 15px
    .name-presentations
      color #1D2944
      font-weight 700
      font-size 50px
      padding 15px 0 0 4px
      @media screen and (max-width: 992px)
        font-size 50px
        font-weight 900
        padding 20px 0 0 25px
      @media screen and (max-width: 605px)
        font-size 38px
        padding 20px 0 0 25px
      //@media screen and (max-width: 470px)
    .surname-presentations
      color #1D2944
      font-weight 700
      font-size 50px
      line-height 1
      padding 0 0 8px 4px
      @media screen and (max-width: 992px)
        font-size 50px
        font-weight 900
        padding 0 30px 0 25px
      @media screen and (max-width: 605px)
        font-size 38px
        font-weight 900
        padding 0 30px 0 25px
      //@media screen and (max-width: 470px)
    .gallery-media-container
      display none
      @media screen and (max-width: 992px)
        display block
      #links
        display none
      .blueimp-gallery
        background-color black
        .slides
          .slide
            .slide-content
              height 100%
      #blueimp-carousel
        z-index 100
        height 400px
        width 100%
      .blueimp-gallery-carousel
        box-shadow none
    .gallery-media
      z-index 200
      display none
      width 100vw
      height 430px
      text-align center
      position relative
      @media screen and (max-width: 992px)
        display block
    .category
      margin-bottom 20px
      color #2AACE8
      font-size 24px
      padding 0 0 30px 6px
      @media screen and (max-width: 992px)
        font-weight 600
        padding 5px 0 20px 25px
      .text-category
        font-size 24px
    .long-description
      font-weight 600
      font-size 13px
      line-height 30px
      margin-bottom 30px
      @media screen and (max-width: 992px)
        z-index 400
        background-color white
        margin-bottom 0
        line-height: 2
        font-size 12px
        padding 50px 30px 40px 25px
    .film-container
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      margin-bottom 100px
      @media screen and (max-width: 992px)
        margin-bottom 140px
        margin-left 25px
        margin-right 25px
      .film
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
.widen-Gallery
  text-align center
  transition 1s
  width 100vw !important
.visible-Btn
  cursor pointer !important
  transition 0.5s
  z-index 1000
  opacity 1 !important
.btns-space
  z-index 1000
.widened-Gallery
  transition 1s
//TOP LIST EXPANDABLE
.nav-list
  height 100%
.submenu
  ul
    display none
    list-style none
  .expnd
    color #0a73bb

    &:after
      content "+"

  &.hidn:after
    content "-"
.btn-div
  background-color transparent
  border-color transparent
.no-pointer
  pointer-events none
  #document
    svg
      path
        fill #0a73bb
.btn-scrl-color
  fill #1D2944

