.odd
	.lista
		.item
			flex-direction: row-reverse !important
			.text
				padding-right: 50px !important
				padding-left: 0 !important
				@media screen and (max-width: 990px)
					padding: 0 25px !important


			&:nth-of-type( 2n - 1 )
				flex-direction: row !important
				.text
					padding-left: 50px !important
					padding-right: 0 !important
					@media screen and (max-width: 990px)
						padding: 0 25px !important

.news-type-list
	> #loading
		display: none !important
	#loading
		padding: 80px 0
	> #loading-emanations
		display: none !important
	#loading-emanations
		padding: 80px 0
		img
			display: block
			margin: 0 auto

	.load-more
		display: block
		font-weight: bold
		color: dark-blue-color
		font-size: 16px
		text-transform: lowercase
		text-align: center
		text-decoration: none

	.lista-emanacji
		padding-top: 135px
		@media screen and (max-width: 990px)
			padding-top: 50px
	.lista
		padding-top: 135px
		@media screen and (max-width: 990px)
			padding-top: 50px
		@media( min-width: 1700px )
			min-height: 1000px
	.lista, .lista-emanacji

		.item
			margin-bottom: 135px
			display: flex
			flex-direction: row
			@media screen and (max-width: 990px)
				display: block
				margin-bottom: 80px

			&:nth-of-type( 2n - 1 )
				flex-direction: row-reverse

				.text
					padding-left: 0
					padding-right: 50px
					@media screen and (max-width: 990px)
						padding: 0 25px

			.img
				background-color: #fff
				background-size: contain
				background-position: center center
				background-repeat: no-repeat
				width: 50%
				padding: 0
				float: right
				object-fit contain
				max-height: 340px
				@media screen and (max-width: 990px)
					width: 100%
					//height: 200px
					margin-bottom: 20px
					float: none
					clear: both
					display: block
					max-height: 300px

				img
					width: 100%

			.text
				width: 50%
				padding: 0
				padding-left: 50px
				float: left
				@media screen and (max-width: 990px)
					width: 100%
					padding: 0 25px
					float: none
					clear: both

				h2
					font-size: 18px
					font-weight: bold
					color: dark-blue-color
					margin: 0 0 20px

					a
						color: dark-blue-color

				span.tag
					color: hover-blue
					font-size: 14px
					font-style: italic
					text-decoration: none
					text-transform: lowercase

				p
					margin-top: 40px
					color: dark-blue-color
					font-size: 14px
					line-height: 28px

					a
						color: hover-blue
						text-decoration: none
						font-size: 14px
						line-height: 28px

						&:hover
							color: hover-blue

				.read-more
					color: #137EB0
					text-decoration: none
					font-style: italic
					font-weight: bold
					font-size: 14px
					line-height: 28px
					cursor: pointer
					margin-bottom: 40px
					display: inline-block

					&:hover
						color: #137EB0

				table
					margin-top: 55px
					border: 1px solid dark-blue-color

					td.day
						padding: 6px 15px
					td.hour
						padding: 6px 23px

					td.hour,td.day
						border-right: 1px solid dark-blue-color
						color: dark-blue-color
						font-size: 14px
						font-weight: bold

					td.rezerwuj
						background-color: dark-blue-color
						color: #fff
						font-weight: bold
						padding: 6px 50px

						a
							color: #fff
							font-weight: bold
							font-size: 14px
							text-decoration: none
							text-transform: uppercase

							&:hover
								color: #fff

.condolence
	background-color: rgba(0,0,0,0.88) !important
	.close
		background-color: rgba(0,0,0,0.88) !important

#news-popup
	position: absolute
	display: none
	width: 100%
	min-height: 100%
	top: 0
	left: 0
	background-color: rgba( 0 , 0 , 9, 0.88 )
	z-index: 999999

	.main-news-img
		margin-top: 80px
		background-color: #fff
		//height: 550px
		display: flex
		width: 100%
		aspect-ratio: 16/9
		background-repeat: no-repeat
		background-size: contain
		background-position: center center

		img
			object-fit: contain
			width: 100%
			//height: 100%

		@media screen and ( max-width: 1199px )
			//height: 350px
			//object-fit: unset
			margin-top: 0

		@media screen and ( max-width: 750px )
			//height: 250px


	& > .container
		position: relative

		.content
			margin-top: 5%
			margin-bottom: 10%
			padding: 0 100px 100px
			background-color: #ffffff
			overflow: hidden
			position: relative
			@media screen and (max-width: 992px)
				padding: 80px 50px 50px
			@media screen and (max-width: 700px)
				padding: 80px 30px 30px
			@media screen and (max-width: 470px)
				padding: 80px 15px 15px

			&.newsletter-popup
				padding: 0 100px

				@media screen and (max-width: 992px)
					padding: 0 50px
				@media screen and (max-width: 700px)
					padding: 0 30px
				@media screen and (max-width: 470px)
					padding: 0 15px
			.close
				position: absolute
				right: 0
				top: 0
				width: 45px
				height: 45px
				background-color: rgba( 8 , 54 , 91 , 0.93 )
				opacity: 1

				img
					padding: 10px
			h1
				font-size: 24px
				color: dark-blue-color
				margin-top: 55px
				margin-bottom: 25px
				font-weight: bold

			.informations
				margin-bottom: 80px

			.container
				width: 100%

			.controls
				margin-top: 50px

				.close-wcag
					display: none

				.nav-btn
					display: inline-block
					border: 1px solid dark-blue-color
					padding: 15px 35px
					font-weight: bold
					cursor: pointer

					&.prev
						float: left
					&.next
						float: right

.informations
	width: auto
	height: auto
	margin: 20px 0 0 0
	clear: both

	.information_item
		width: auto
		height: auto
		padding: 11px 20px
		font-size: 14px
		color: dark-blue-color
		font-weight: bold
		border: 1px solid dark-blue-color
		margin-left: -1px
		float: left
		@media screen and (max-width: 470px)
			width: 100%
			text-align: center
			float: none
			clear: both

		&.book-btn
			background-color: dark-blue-color
			color: #ffffff
			font-weight: bold
			font-size: 14px
			text-decoration: none
			text-transform: uppercase
			font-weight: bold
			padding: 0

			a
				color: #ffffff
				padding: 11px 45px
				display: block
				text-decoration: none
				@media screen and (max-width: 470px)
					padding: 11px 20px

#sticky-news-nav
	background-color: #ffffff
	width: 250px
	height: auto
	padding: 30px
	top: 70px
	left: 0
	position: absolute
	z-index: 999
	
	@media( max-width: 1700px )
		display: none

	#show-all
		font-size: 11px
		color: dark-blue-color
		font-weight: bold
		padding-left: 20px
		cursor: pointer

	&.affix
		position: fixed
		top: 70px
		left: 0

	.select-box
		width: 100%
		height: auto
		margin-bottom: 10px
		clear: both
		&:last-child
			margin-bottom: 0

		.heading
			width: 100%
			height: auto
			font-size: 13px
			font-family: Open Sans
			color: dark-blue-color
			padding: 14px 17px 5px 17px
			box-sizing: border-box
			clear: both
			text-transform: uppercase
			font-weight: bold
			
			&::after
				content: ""
				display: block
				width: 45px
				height: 1px
				background-color: dark-blue-color
				margin-top: 30px

			.arrow
				font-size: 20px
				margin-top: -4px
				float: right
				cursor: pointer
				transition: all 0.4s ease

		&.collapsed
			.arrow
				transform: rotate( -180deg )

		.list
			width: 100%
			height: auto
			padding: 25px 0
			box-sizing: border-box
			clear: both

			ul
				list-style: none
				display: block
				padding: 0
				margin: 0

				li
					width: 100%
					height: auto
					font-size: 11px
					font-family: Open Sans
					color: dark-blue-color
					padding: 0 22px
					margin-bottom: 12px
					position: relative
					clear: both
					text-transform: uppercase
					&:last-child
						margin-bottom: 0
					&.active
						font-weight: bold
						&::before
							background-color: dark-blue-color
							width: 18px
							height: 1px
							content: ''
							top: 7px
							left: 0
							position: absolute
							z-index: 9

					a
						color: dark-blue-color
						text-decoration: none
						&:hover
							font-weight: bold


		.list-checkbox
			width: 100%
			height: 120px
			margin: 25px 0 0 0
			overflow-y: auto
			box-sizing: border-box
			clear: both

			ul
				list-style: none
				display: block
				padding: 0
				margin: 0

				li
					width: 100%
					height: auto
					font-size: 11px
					font-family: Open Sans
					font-style: italic
					color: dark-blue-color
					margin-bottom: 15px
					position: relative
					clear: both
					text-transform: lowercase
					&:last-child
						margin-bottom: 0
					&:hover
						cursor: pointer
					&.active
						font-weight: bold

					label
						width: 100%
						display: block
						&:hover
							cursor: pointer

					input[type="checkbox"]
						display: none

					input[type="checkbox"] + label
						color: dark-blue-color

					input[type="checkbox"] + label span
						display: inline-block
						width: 14px
						height: 14px
						margin: -2px 14px 0 0
						vertical-align: middle
						border: 1px solid dark-blue-color
						cursor: pointer

					input[type="checkbox"]:checked + label span
						background: url('../img/checkbox-checked.png') center center no-repeat

#first-paragraph
	font-size: 14px
	line-height: 28px
	color: dark-blue-color
	z-index: 1
	.buy-ticket
		border none
		padding-top 9px
		background-color #1C92D1
		text-align center
		color white
		font-weight bolder
		font-size 17px
		a
			text-decoration none
			color inherit!important
			&:hover
				color inherit!important
	.youtube-container
		margin-bottom 60px
		.news-film
			padding 10px 0
			width 600px
			height 300px
			.film
				width 100%
				height 100%
				object-fit contain

