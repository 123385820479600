.tabs
  --accent-color: lightgrey
  --indicator-color: grey
  --shift: 0deg
  .tab:last-of-type
    border-bottom: solid 1px var(--accent-color)
  .tab
    //--height: 300px
    --height: 0
    border-top: solid 1px var(--accent-color)
    cursor: pointer
    .main
      display: flex
      justify-content: space-between
      align-items: center
      .header
        margin: 0 !important
        padding: 20px 0  !important
        color: hover-blue !important
      .indicator
        //border: solid 1px black
        position relative
        width: 18px
        aspect-ratio 1
        &:after, &:before
          content: ''
          position absolute
          background-color: var(--indicator-color)
          top: 50%
          width: 100%
          height: 2px
          border-radius: 8px
          translate: 0 50%
          transform-origin: center
          transition: background-color 0.5s ease-in-out, rotate 0.5s ease-in-out;
          rotate: var(--shift)
        &:after
          rotate: calc(90deg + var(--shift))
    .expandable
      height: 100%
      max-height: var(--height)
      overflow-y: hidden
      pointer-events none
      transition: max-height 0.5s ease-in-out;
      p
        padding: 20px 0 !important
    &.expanded
      --indicator-color: hover-blue
      --shift: 45deg
      .expandable
        pointer-events auto

