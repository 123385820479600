#mecenat

	.img	
		margin-bottom: 60px

		img
			display: block
			margin: 0 auto

	h1
		font-size: 18px
		font-weight: 700
		margin: 140px 0 0 0

	p
		margin: 70px 0 50px 0	
		font-size: 1.02em
		line-height: 200%;
		
	.btn
		background-color:rgb(19,31,60)
		color: #fff
		text-transform: uppercase
		text-style:"Jaapokki Regular"
		border-radius: 0
		text-align: center
		padding: 10px 40px 10px 40px

		
		&:hover, &:focus, &:visited
			color: #fff
		
	.galeria
		margin: 100px 0 120px 0
		.galeria-opis
			display: none