#szablon
	.module
		margin-bottom: 80px
		position: relative
		background-color: #fff

		@media( max-width: 768px )
			margin-bottom: 60px

		&:first-child
			margin-top: 135px

		p
			font-size: 14px
			line-height: 28px
			color: dark-blue-color

			&:last-child
				margin-bottom: 0

		h1,h2,h3,h4
			font-size: 18px
			font-weight: 700
			padding: 0
			margin-bottom: 60px
			color: dark-blue-color

		.bg-tekst
			font-size: 176px
			opacity: 0.08
			color: dark-blue-color
			position: absolute
			font-family: 'Jaapokki'

			@media( max-width: 1023px )
				display: none

			&.left
				top: 0
				left: 0

			&.right
				top: 0
				right: 0

			&.drop-left
				bottom: 0
				left: 0

			&.drop-right
				bottom: 0
				right: 0

		&.obudowa::after
			content: ""
			display: block
			width: calc( 100% + 130px )
			height: calc( 100% + 65px )
			left: -65px
			top: 0
			background-color: #fff
			position: absolute
			z-index: -1

	.plik-do-pobrania
		margin: 60px 0
		z-index: 1
		a
			font-size: 14px
			color: dark-blue-color
			font-weight: bold
			text-decoration: none

			&:hover
				color: dark-blue-color
