.zdjecie-i-tekst
	
	.zdjecie
		padding: 0
		
		.zdjecie-container
			position: relative
			z-index: 2

			
			&::before
				content: ""
				display: block
				width: 130px
				height: 130px
				top: -20px
				right: -20px
				background-color: dark-gray
				opacity: 0.08
				position: absolute
				z-index: -1
				
			&::after
				content: ""
				display: block
				width: 130px
				height: 130px
				bottom: -20px
				left: -20px
				background-color: dark-gray
				opacity: 0.08
				position: absolute
				z-index: -1
				
			.autor
				position: absolute
				right: 0
				bottom: 0
				padding-left: 15px
				padding-right: 50px
				color: #fff
				background-color: #000
				font-size: 8px
				line-height: 20px
				
				&::before
					content: ""
					position: absolute
					right: 100%
					bottom: 0
					border-bottom: 20px solid #000
					border-left: 20px solid transparent
				
				&::after
					content: ""
					position: absolute
					right: 0
					bottom: 0
					border-bottom: 35px solid #fff
					border-left: 35px solid transparent
	
	.tekst
		padding-left: 0
		padding-right: 35px
		@media screen and (max-width: 991px)
			margin-top: 60px

		h2 
			margin-top: 0